import { formatPercent } from '@3fourteen/core';
import { memo } from 'react';

interface StatsProps {
  stats: [any];
}

function StatsBox({ stats }: StatsProps) {
  const labels = Object.keys(stats[0]);

  return (
    <div className='px-4 md:px-6 bg-white border-y border-solid border-zinc-200'>
      <div className='pb-2 pt-4'>
        <div className='pb-1 flex border-b border-solid border-zinc-200'>
          {labels.map((label) => (
            <span
              className='flex-1 text-sm font-bold uppercase text-center first-of-type:text-left'
              key={label}>
              {label}
            </span>
          ))}
        </div>
        {stats.map((stat, i) => {
          return (
            <div className='flex my-2' key={i}>
              {Object.keys(stat).map((key, i) => {
                const statValue = stat[key];
                const value = formatPercent(statValue) || statValue;
                return (
                  <span className='flex-1 text-center first-of-type:text-left text-15' key={i}>
                    {value}
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(StatsBox);
