import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo } from 'react';

import { LoadingDots, SupportingChartLinks } from 'components';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { IS_DEVELOPMENT } from 'services/environment';

interface SupportingChartProps {
  title: string;
  url: string;
  width: number;
  height: number;
  showHeader?: boolean;
}

const SupportingChart = ({
  title,
  url,
  width,
  height,
  showHeader = true,
}: SupportingChartProps) => {
  const filename = url.split('/').pop();
  const file = filename.split('.').shift();
  const urlComponents = url.split('/');
  const chartUrl = [...urlComponents].slice(0, -1).join('/');
  const { url: signedUrl, error, isLoading } = useSignedCloudfrontUrl(`${file}.jpg`);

  return (
    <div
      className={`${
        showHeader
          ? 'px-4 md:px-6 md:pb-6 mt-4 md:mt-6 mx-auto flex flex-col items-center max-w-[1248px]'
          : ''
      }`}>
      {showHeader && (
        <div className='flex justify-between items-center mb-2 w-full max-w-[1200px]'>
          <h3 className='text-sm md:text-base'>{title}</h3>
          <SupportingChartLinks url={url} />
        </div>
      )}
      {error && <p className='text-center py-2'>{error}</p>}
      {isLoading && <LoadingDots size='regular' color='#999' />}
      {signedUrl && (
        <Link href={chartUrl} className='w-full'>
          <Image
            unoptimized={IS_DEVELOPMENT}
            src={signedUrl}
            alt={title}
            height={height || 800}
            width={width || 1200}
            layout='responsive'
          />
        </Link>
      )}
    </div>
  );
};

export default memo(SupportingChart);
