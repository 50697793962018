import { fetchStratAA, FetchStratAAResponse } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useStratAA() {
  const { data: stratAA, ...rest } = useQuery<FetchStratAAResponse[]>({
    queryKey: ['stratAA'],
    queryFn: fetchStratAA,
  });

  return {
    stratAA,
    ...rest,
  };
}
