import { memo, ReactNode } from 'react';

interface BannerProps {
  children: ReactNode;
}

function Banner({ children }: BannerProps) {
  return (
    <div className='bg-amber-400 w-full py-2 top-0'>
      <p className='font-semibold text-center'>{children}</p>
    </div>
  );
}

Banner.displayName = 'Banner';

export default memo(Banner);
