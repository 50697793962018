import { memo } from 'react';

import { SimpleLineIcon } from 'components';

interface EyeIconProps {
  isOpen?: boolean;
  color?: string;
  size?: number;
}

function EyeIcon({ isOpen = true, color, size = 16 }: EyeIconProps) {
  return (
    <div className='relative flex items-center justify-center'>
      {!isOpen && <span className='absolute w-0.5 h-4 bg-teal-800 rotate-45' />}
      <SimpleLineIcon name='eye' color={color} size={size} />
    </div>
  );
}

export default memo(EyeIcon);
