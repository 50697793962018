'use client';

import { useMember } from 'hooks/useMember';
import { memo } from 'react';
import { useMedia } from 'react-use';

import { CoreNav } from 'components/CoreNav';
import { PbNav } from 'components/PbNav';
import Image from 'next/image';
import Link from 'next/link';
import { routes } from 'services/routes';

import logo from '../../public/images/logos/mark-white.svg';

function Sidebar() {
  const { isPbUser } = useMember();
  const isWide = useMedia('(min-width: 1024px)');

  if (!isWide) return null;

  return (
    <header className='flex flex-col h-screen overflow-y-auto bg-teal-800 shrink-0 xl:basis-[230px] 3xl:basis-[300px] pt-6 pb-4 md:pb-6'>
      <div className='h-8 w-8 xl:h-10 xl:w-10 2xl:h-12 2xl:w-12 mx-auto xl:ml-6 mb-8 block'>
        <Link href={routes.home} passHref={true}>
          <Image alt='3Fourteen Mark' src={logo} />
        </Link>
      </div>
      {isPbUser ? <PbNav /> : <CoreNav />}
    </header>
  );
}

export default memo(Sidebar);
