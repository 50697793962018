import { memo } from 'react';

interface TableLegendItem {
  color: string;
  label: string;
}

interface TableLegendProps {
  items: TableLegendItem[];
  containerStyles?: string;
}

function TableLegend({ items, containerStyles = '' }: TableLegendProps) {
  return (
    <div className={`flex items-center flex-wrap ${containerStyles}`}>
      {items.map(({ color, label }) => (
        <div className='flex items-center mr-8 mb-2' key={label}>
          <span
            className='h-3 w-5 rounded-sm inline-block mr-2'
            style={{ backgroundColor: color }}
          />
          <span className='text-sm'>{label}</span>
        </div>
      ))}
    </div>
  );
}

export default memo(TableLegend);
