'use client';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { FormikValues } from 'formik';
import { classNames } from 'helpers/classNames';
import { usePbAlphaModels } from 'hooks/usePbAlphaModels';
import { Fragment, memo, useCallback, useEffect } from 'react';

interface AlphaModelSelectProps {
  form: FormikValues;
}
function AlphaModelSelect({ form }: AlphaModelSelectProps) {
  const { alphaModels } = usePbAlphaModels();
  const selected = alphaModels?.find(({ id }) => id === form.values.alpha_model?.id);

  useEffect(() => {
    if (!selected && alphaModels) {
      form.setFieldValue('alpha_model', alphaModels?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alphaModels]);

  const onChange = useCallback(
    (props) => {
      form.setFieldValue('alpha_model', props);
    },
    [form]
  );

  return (
    <Listbox value={form.values.alpha_model} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className='block text-sm font-medium text-gray-700 uppercase'>
            Alpha Model
          </Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button className='h-10 relative w-full cursor-default rounded-md border border-gray-300 border-solid bg-white py-2 pl-3 pr-10 text-left  focus:border-teal-800 focus:outline-none focus:ring-1 focus:ring-teal-800 sm:text-sm'>
              <span className='block truncate'>{form.values.alpha_model?.name}</span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {alphaModels?.map((model) => (
                  <Listbox.Option
                    key={model.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-teal-800' : 'text-gray-900',
                        'relative cursor-default select-none py-4 px-3 border-b border-solid border-gray-200 last-of-type:border-none'
                      )
                    }
                    value={model}>
                    {({ active, selected }) => {
                      return (
                        <>
                          <div className='flex'>
                            <span className='font-medium block truncate mb-1'>{model.name}</span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-teal-800',
                                  'flex pl-1.5'
                                )}>
                                <CheckIcon className='h-5 w-5' aria-hidden='true' />
                              </span>
                            ) : null}
                          </div>
                          <p
                            className={classNames(
                              active ? 'text-gray-300' : 'text-teal-800',
                              'text-13 text-gray-500'
                            )}>
                            {model.description}
                          </p>
                        </>
                      );
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

AlphaModelSelect.displayName = 'AlphaModelSelect';

export default memo(AlphaModelSelect);
