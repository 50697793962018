import { Chart } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { PostIcon, PostTag } from 'components';
import Link from 'next/link';

interface ChartItemProps {
  chart: Chart;
  showIcon?: boolean;
  useHref?: boolean;
}

function ChartItem({ chart, showIcon = false, useHref = true }: ChartItemProps) {
  const isSmallScreen = useMediaQuery(768);

  const { categorySlug, slug } = chart;
  const iconSize = isSmallScreen ? 14 : 16;

  const el = useMemo(() => {
    if (chart) {
      const { title, excerpt } = chart;

      return (
        <div className='min-w-0'>
          <div className='flex items-baseline mb-1'>
            {showIcon && (
              <div className='pr-1.5'>
                <PostIcon categorySlug='charts' size={iconSize} />
              </div>
            )}
            <p className='pr-2 mb-0 font-medium whitespace-nowrap text-ellipsis overflow-hidden font-serif text-15'>
              {title}
            </p>
            {showIcon && <PostTag category='charts' />}
          </div>
          <div
            className='*:text-sm *:whitespace-nowrap *:overflow-hidden *:text-ellipsis *:opacity-70'
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        </div>
      );
    } else null;
  }, [chart, iconSize, showIcon]);

  if (!chart) return <Skeleton count={3} />;

  if (useHref) {
    return (
      <Link href={`/${categorySlug}/${slug}`} className='min-w-0'>
        {el}
      </Link>
    );
  }

  return el;
}

export default memo(ChartItem);
