import { memo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
  id: string;
}

function Tooltip({ id }: TooltipProps) {
  return (
    <ReactTooltip
      className='!text-15 z-[999] shadow-2xl shadow-gray-50/20 absolute font-medium max-w-xs bg-teal-900 text-white px-4 pt-2 pb-3 rounded whitespace-pre-wrap overflow-hidden'
      id={id}
    />
  );
}

Tooltip.displayName = 'Tooltip';

export default memo(Tooltip);
