import dayjs from 'dayjs';
import { memo } from 'react';

function DateTimeDisplay({ date }: { date: string }) {
  const isToday = dayjs(date).isSame(dayjs(), 'day');
  const isSameYear = dayjs(date).isSame(dayjs(), 'year');

  if (isToday) {
    return (
      <span className='text-sm tracking-tight'>
        {dayjs(date).format('h:mm A')}
      </span>
    );
  }

  if (isSameYear) {
    return (
      <div className='text-sm tracking-tight flex items-center'>
        <span>{dayjs(date).format('MMM D')}</span>
        <span className='mx-1'>@</span>
        <span>{dayjs(date).format('h:mm A')}</span>
      </div>
    );
  }

  return (
    <div className='text-sm tracking-tight flex items-center'>
      <span>{dayjs(date).format('MMM D, YYYY')}</span>
      <span className='mx-1'>@</span>
      <span>{dayjs(date).format('h:mm A')}</span>
    </div>
  );
}

DateTimeDisplay.displayName = 'DateTimeDisplay';

export default memo(DateTimeDisplay);
