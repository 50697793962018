import { Chart, Commentary } from '@3fourteen/core';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { memo } from 'react';

import { DownloadChartButton, FavoriteButton } from 'components';
import Link from 'next/link';

interface PostActionsProps {
  post: Commentary | Chart;
}

const PostActions = ({ post }: PostActionsProps) => {
  return (
    <div className='flex items-center justify-end'>
      <FavoriteButton post={post as Chart} />
      <div className='ml-4 2xl:ml-6'>
        <DownloadChartButton filename={post?.filename} />
      </div>
      <Link
        href={`/${post.categorySlug}/${post.slug}`}
        className='ml-4 2xl:ml-6 font-semibold flex items-center'>
        <ArrowTopRightOnSquareIcon className='size-4 lg:size-5 text-teal-600' />
      </Link>
    </div>
  );
};

export default memo(PostActions);
