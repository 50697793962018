import { AllocationModelCode } from '@3fourteen/core';
import dayjs from 'dayjs';
import { useStats } from 'hooks/useStats';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  HtmlRenderer,
  LatestUpdateLink,
  PageHeader,
  StatsBox,
  SupportingChart,
  SupportingChartLinks,
  WhitepaperLink,
} from 'components';
import { routes } from 'services/routes';

interface AssetModelPageProps {
  statsKey: AllocationModelCode;
  pageHeader?: React.ReactElement | string;
  description?: string;
  latestUpdate?: {
    date: string;
    content: string;
    url?: string;
  };
  Table?: React.ReactElement;
  Legend?: React.ReactElement;
  /** slug without preceding forward slash */
  supportingChart: {
    url: string;
    height: number;
    width: number;
  };
  whitepaperUrl?: string;
  isFetching?: boolean;
  skeletonCount?: number;
}

const AssetModelPage = ({
  statsKey,
  pageHeader,
  description,
  latestUpdate,
  Table,
  Legend,
  supportingChart,
  whitepaperUrl,
  isFetching = false,
  skeletonCount = 10,
}: AssetModelPageProps) => {
  const url = `${routes.charts}/${supportingChart.url}`;
  const isLoading = isFetching || !Table;
  const { stats } = useStats(statsKey);

  return (
    <div className='min-h-screen bg-zinc-100'>
      <div className='md:flex justify-between md:px-6 pt-6'>
        <div className='flex-1 mx-4 md:mx-0'>
          {typeof pageHeader === 'string' ? (
            <h1 className='flex-1 text-xl md:text-2xl text-center md:text-left font-semibold mb-2'>
              {pageHeader}
            </h1>
          ) : (
            pageHeader
          )}
          <div className='flex justify-center md:justify-start'>
            {description && <p className='text-15 mr-2'>{description}</p>}
            {!!whitepaperUrl && (
              <div>
                <WhitepaperLink url={whitepaperUrl} />
              </div>
            )}
          </div>
        </div>

        {!!stats && (
          <div className='basis-1/2 mt-6 md:mt-0'>
            <StatsBox stats={stats} />
          </div>
        )}
      </div>

      <div>
        {isLoading ? (
          <div className='p-4 md:p-6'>
            <Skeleton count={skeletonCount} />
          </div>
        ) : (
          <div className='my-6 md:m-6 border border-solid border-gray-200 pb-4 md:rounded-lg bg-white'>
            {Table}
            {!!Legend && <div className='mb-4 md:mb-0 px-4 md:px-6'>{Legend}</div>}
          </div>
        )}
      </div>

      {/* Commentary & Charts */}
      <div className='flex flex-col lg:flex-row pb-16 md:pb-28 lg:pb-6 md:mx-6' id='charts'>
        <div className='flex-1 order-2 lg:order-1 lg:mr-3 border border-solid border-gray-200 md:rounded-lg bg-white'>
          <div className='flex items-center justify-between pb-2 px-4 md:px-6 pt-4 md:pt-6'>
            <h2 className='font-medium leading-tight'>Supporting Chart</h2>
            {!isLoading && (
              <div className='flex'>
                <SupportingChartLinks url={url} />
              </div>
            )}
          </div>
          <div className='p-4 md:p-6'>
            {isLoading ? (
              <Skeleton height={400} />
            ) : (
              <SupportingChart
                title='Backtest'
                url={url}
                height={supportingChart.height}
                width={supportingChart.width}
                showHeader={false}
              />
            )}
          </div>
        </div>
        {!!latestUpdate && (
          <div className='flex-1 pb-6 mb-6 lg:mb-0 lg:ml-3 order-1 lg:order-2 border border-solid border-gray-200 md:rounded-lg bg-white'>
            <div className='flex items-center justify-between pb-2 px-4 pt-6 md:px-6 md:pt-8'>
              <div className='flex items-center justify-between flex-1 md:items-start md:justify-start'>
                <h2 className='font-medium leading-tight'>Latest Commentary</h2>
                {!isLoading && (
                  <span className='uppercase text-xs text-right relative pl-4 leading-tight md:text-sm md:text-left lg:before:content-[""] before:absolute before:h-5 before:w-px before:bg-neutral-200 before:ml-[-8px]'>
                    {dayjs(latestUpdate.date).format('MMM D, YYYY')}
                  </span>
                )}
              </div>
              {!isLoading && (
                <div className='hidden md:flex'>
                  <LatestUpdateLink url={!!latestUpdate.url ? latestUpdate.url : '#'} />
                </div>
              )}
            </div>
            <div className='px-4 md:px-6'>
              {isLoading ? (
                <div className='py-4 md:py-6'>
                  <Skeleton count={10} />
                </div>
              ) : (
                <div className='mt-4 md:mt-6'>
                  <HtmlRenderer content={latestUpdate.content} />
                  <div className='py-4 md:py-6 flex md:hidden justify-center'>
                    <LatestUpdateLink url={!!latestUpdate.url ? latestUpdate.url : '#'} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(AssetModelPage);
