import dayjs from 'dayjs';
import { memo } from 'react';

interface RebalDateProps {
  date: string;
}

function RebalDate({ date }: RebalDateProps) {
  if (!date) return null;
  return (
    <span className='uppercase text-xs md:text-sm whitespace-nowrap'>
      {dayjs(date).format('MMM D')}
    </span>
  );
}

export default memo(RebalDate);
