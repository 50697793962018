'use client';

import { Dialog, Transition } from '@headlessui/react';
import { useDisableBodyScroll } from 'hooks/useDisableBodyScroll';
import React, { Fragment, memo } from 'react';

import { Button } from 'components';

interface ActionSheetProps {
  children: React.ReactNode;
  visible: boolean;
  close?: () => void;
}

function ActionSheet({ children, visible, close }: ActionSheetProps) {
  useDisableBodyScroll(visible);

  return (
    <div className='pb-safe relative'>
      <Transition.Root show={visible} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={close}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full  items-end justify-center text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                <Dialog.Panel className='w-full relative h-[calc(100dvh_-_32px)] bottom-4 mx-4 transform overflow-hidden rounded-lg bg-white text-left transition-all shadow-[0_0_40px_10px_rgba(0,0,0,0.3)]'>
                  <Button
                    onClick={close}
                    className='btn btn-sm w-full bg-gray-100 rounded-none mb-4'>
                    <div className='w-8 h-1 text-center rounded bg-gray-400'></div>
                  </Button>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default memo(ActionSheet);
