import { formatPercent, weightGreen, weightRed } from '@3fourteen/core';
import dayjs from 'dayjs';
import { classNames } from 'helpers/classNames';
import { memo } from 'react';

import { AntIcon } from 'components';

interface FullCycleTableRowProps {
  row: any;
  inactiveRow?: boolean;
}

function FullCycleTableRow({ row, inactiveRow = false }: FullCycleTableRowProps) {
  return (
    <tr
      className={classNames(
        inactiveRow ? 'opacity-80 italic' : undefined,
        'border-y border-solid border-zinc-100 even:bg-zinc-50'
      )}>
      {row.cells.map((cell, i) => {
        const isNew = row.values.is_new;
        const isOld = row.values.is_old;
        const tickerClass = isNew ? 'bg-sage-500' : isOld ? 'bg-terracotta-500' : '';
        const isDateCell = i === 0 || i === 7;
        const isWeightCell = i === 2 || i === 3;
        const isTickerCell = i === 1;
        const isPctChangeCell = i === 2;
        const isSectorCell = i === 4;
        const isCompanyCell = i === 5;
        const isRankCell = i === 6;
        const weightCellClass = isPctChangeCell ? 'font-semibold' : undefined;
        const width =
          isWeightCell || isTickerCell
            ? '10%'
            : isSectorCell
            ? '16%'
            : isCompanyCell
            ? '17%'
            : isRankCell
            ? '9%'
            : '14%';

        return (
          <td
            role='cell'
            key={cell.getCellProps().key}
            width={width}
            data-testid={`td-${i}`}
            className='first:pl-4 md:first:pl-6 border-r border-solid border-zinc-100 px-3 py-0 text-15 whitespace-nowrap'>
            {isDateCell && (
              <span className='text-13 uppercase py-1 inline-block'>
                {cell.value ? dayjs(cell.value).format('MMMM D, YYYY') : ''}
              </span>
            )}
            {isTickerCell && (
              <div
                className={
                  isNew || isOld
                    ? classNames(
                        tickerClass,
                        'text-white font-semibold inline-block  px-0 w-16 text-center rounded-sm'
                      )
                    : ''
                }>
                {cell.value}
              </div>
            )}
            {(isSectorCell || isCompanyCell || isRankCell) && cell.render('Cell')}
            {isWeightCell && (
              <span className={`inline-block w-12 ${weightCellClass}`}>
                {formatPercent(cell.value)}
              </span>
            )}
            {isPctChangeCell && cell.value !== 0 && (
              <span className='not-italic'>
                <AntIcon
                  name={cell.value > 0 ? 'caretup' : 'caretdown'}
                  color={cell.value > 0 ? weightGreen : weightRed}
                />
              </span>
            )}
          </td>
        );
      })}
    </tr>
  );
}
export default memo(FullCycleTableRow);
