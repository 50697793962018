'use client';

import { resumeSubscription, Subscription } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { Dialog, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, memo, useCallback, useState } from 'react';

import { Button } from 'components';

interface ResumeSubcriptionModalProps {
  id: Subscription['id'];
}

function ResumeSubcriptionModal({ id }: ResumeSubcriptionModalProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onResume = async () => {
    setLoading(true);

    try {
      await resumeSubscription(id);

      setOpen(false);
    } catch (error) {
      setErrorMessage(
        'Looks like there might have been an issue resuming your subscription. Please reach out to us directly at info@3fourteenresearch.com.'
      );
      Bugsnag.notify(error, (event) => {
        event.context = 'onResume() in ResumeSubcriptionModal';
        event.addMetadata('Sx Info', {
          id,
        });
      });
    } finally {
      setLoading(false);

      await queryClient.refetchQueries({
        queryKey: ['member'],
        type: 'active',
        exact: true,
      });
    }
  };

  const onOpen = useCallback(() => {
    setErrorMessage('');
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div>
      <Button onClick={onOpen} className='btn-xs btn-secondary flex justify-center ml-4'>
        Resume
      </Button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg font-medium leading-6 text-gray-900 mb-4'>
                        Resume your subscription
                      </Dialog.Title>
                      <div className='mt-2 text-gray-700 mb-8'>
                        {!errorMessage ? (
                          <p>
                            Please confirm you&apos;d like to resume your subscription to the
                            Portfolio Builder.
                          </p>
                        ) : (
                          <p>{errorMessage}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='mt-5 sm:mt-6 sm:flex justify-center'>
                    {!errorMessage && (
                      <Button
                        type='button'
                        isLoading={loading}
                        className='btn bg-teal-800 text-white w-full mx-2'
                        onClick={onResume}>
                        Yes, I&apos;d like to resume
                      </Button>
                    )}
                    <Button
                      type='button'
                      className='btn border border-solid border-gray-500 text-gray-800 w-full mx-2'
                      onClick={onClose}>
                      {!errorMessage ? "No, don't resume" : 'OK'}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

ResumeSubcriptionModal.displayName = 'ResumeSubcriptionModal';

export default memo(ResumeSubcriptionModal);
