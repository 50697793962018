import { memo } from 'react';

import Link from 'next/link';

function PbTips() {
  return (
    <>
      <p className='font-semibold text-teal-700 mb-6 text-center text-2xl mt-1/2'>Tips & Tricks</p>
      <ul className='mb-8 max-w-prose'>
        <li className='list-disc ml-8 pl-2 text-justify mb-4'>
          When selecting assets,{' '}
          <span className='font-semibold'>the total benchmark weight must equal 100%</span>.
          There&apos;s a progress bar at the top of the asset menu that displays your current
          progress.
        </li>
        <li className='list-disc ml-8 pl-2 text-justify mb-4'>
          Selecting an asset doesn&apos;t automatically set a benchmark weight—
          <span className='font-semibold'>
            the benchmark weight must be set manually under the Benchmark column
          </span>
          .
        </li>
        <li className='list-disc ml-8 pl-2 text-justify mb-4'>
          It&apos;s helpful if you select all of your desired assets first, then check &quot;Hide
          Unselected&quot; at the top left of the table. With the unselected assets out of view,
          it&apos;s easier to go through and customize (benchmark/min/max weights, start date) your
          selected assets.
        </li>
        <li className='list-disc ml-8 pl-2 text-justify mb-4'>
          The more assets you select, the longer it will take for the portfolio to be generated.
        </li>
        <li className='list-disc ml-8 pl-2 text-justify mb-4'>
          Selecting a nonsensical mix of assets may result in portfolio generation failure and
          errors. If you&apos;re repeatedly seeing an error and feel you shouldn&apos;t be, please
          <Link
            href='mailto:dev@3fourteenresearch.com?subject=Support Request from Portfolio Builder'
            className='ml-1 text-sky-700 underline'>
            submit a bug report
          </Link>
          .
        </li>
        <li className='list-disc ml-8 pl-2 text-justify mb-4'>
          The Portfolio Builder is not supported on small screens.
        </li>
      </ul>
    </>
  );
}

PbTips.displayName = 'PbTips';

export default memo(PbTips);
