'use client';

import { categorizedPostIds, Chart, fetchSearchResults, Post } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';
import { useEffect, useState } from 'react';

import { useRouter, useSearchParams } from 'next/navigation';

export function useSearch(categories = categorizedPostIds) {
  const [query, setQuery] = useState('');
  const searchParams = useSearchParams();
  const searchQuery = searchParams.get('search');
  const router = useRouter();

  const variables = {
    categories,
    search: query,
  };

  const { data, ...rest } = useQuery({
    queryKey: ['search-results', variables],
    queryFn: async () => {
      const res = await fetchSearchResults(variables);

      const results = res?.filter((result) => !(result as Chart).isCustom);

      const grouped = groupBy(results, 'categorySlug');

      return {
        results,
        grouped,
      };
    },
    enabled: !!query,
  });

  const onSubmit = (term) => {
    setQuery(term);
  };

  useEffect(() => {
    if (!!searchQuery) {
      setQuery(searchQuery as string);
    } else {
      setQuery('');
    }

    // only for mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: check out https://usehooks.com/useDebounce/
    const isHome = location.pathname === '/';
    const isSearchPage = location.pathname.includes('/search');
    const isCommentaryPage = location.pathname.includes('/commentary');
    const isChartsPage = location.pathname.includes('/charts');
    const isSearchEnabled = isSearchPage || isCommentaryPage || isChartsPage;

    if (isHome && !!query) {
      const url = new URL(`${location.origin}/search`);
      url.searchParams.set('search', query);

      router.push(`${url}`);
    }

    if (isSearchEnabled) {
      const url = new URL(location.href);

      if (!!query) {
        url.searchParams.set('search', query);
      } else {
        url.searchParams.delete('search');
      }

      router.push(`${url}`);
    }
    // adding router dep causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    query,
    onSubmit,
    results: data?.results,
    grouped: data?.grouped || [],
    ...rest,
  };
}
