export const columns = [
  {
    Header: "Active Date",
    accessor: "date",
  },
  {
    Header: "Ticker",
    accessor: "ticker",
  },
  {
    Header: "% Change",
    accessor: "pct_change",
    sortType: "basic",
  },
  {
    Header: "S&P 500 % Chg",
    accessor: "sp500_change",
    sortType: "basic",
  },
  {
    Header: "Report",
    accessor: "report",
  },
  {
    Header: "Closed",
    accessor: "inactiveDate",
  },
];
