'use client';

import { AllocationModelCode } from '@3fourteen/core';
import { memo, useEffect, useState } from 'react';

import { AssetModelPage } from 'components';
import { RaaSelect } from 'components/RaaSelect';
import { usePathname } from 'next/navigation';
import { RaaVersion, raaVersions } from 'services/raa';

interface RaaModelPageProps {
  statsKey: AllocationModelCode.RAA_V2_20 | AllocationModelCode.RAA_V2_10 | AllocationModelCode.raa;
  latestUpdate?: {
    date: string;
    content: string;
    url?: string;
  };
  Table?: React.ReactElement;
  Legend?: React.ReactElement;
  /** slug without preceding forward slash */
  supportingChart: {
    url: string;
    height: number;
    width: number;
  };
  isFetching?: boolean;
}

const RaaModelPage = ({
  statsKey,
  latestUpdate,
  Table,
  Legend,
  supportingChart,
}: RaaModelPageProps) => {
  const pathname = usePathname();
  const [version, setVersion] = useState<RaaVersion>();

  useEffect(() => {
    const currentRaa = raaVersions.find(({ href }) => pathname === href);

    setVersion(currentRaa);
  }, [pathname]);

  return (
    <AssetModelPage
      statsKey={statsKey}
      latestUpdate={latestUpdate}
      pageHeader={
        <div className='flex justify-center md:justify-start items-end mb-2'>
          <h1 className='text-xl md:text-2xl font-semibold m-0'>Real Asset Allocation</h1>
          <div className='ml-2'>
            <RaaSelect />
          </div>
        </div>
      }
      description={version?.description}
      whitepaperUrl={version?.whitepaper}
      Table={Table}
      Legend={Legend}
      supportingChart={supportingChart}
      skeletonCount={16}
    />
  );
};

export default memo(RaaModelPage);
