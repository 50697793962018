'use client';

import { retroColor, Retrospective } from '@3fourteen/core';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import parse, { domToReact, Element } from 'html-react-parser';
import React, { memo } from 'react';

import { FavoriteButton, PostIcon, PostTag, VideoPlayer } from 'components';

interface RetroProps {
  retro: Retrospective;
}

function Retro({ retro }: RetroProps) {
  const { title, date, excerpt, content } = retro;

  const isoDate = date.split('T')[0];
  const year = isoDate.split('-')[0];

  const { url, error } = useSignedCloudfrontUrl(`retrospectives/${year}/${isoDate}/${isoDate}.mp4`);

  const { url: posterSrc } = useSignedCloudfrontUrl(`retrospectives/${year}/${isoDate}/poster.jpg`);

  const { url: chaptersSrc } = useSignedCloudfrontUrl(
    `retrospectives/${year}/${isoDate}/chapters.vtt`
  );

  const options = {
    replace: (domNode) => {
      const { attribs, children } = domNode;

      if (domNode instanceof Element && attribs && attribs.href?.includes('ts=')) {
        return (
          <span
            className='underline text-sm font-semibold text-gray-500 cursor-pointer'
            onClick={() => {
              const ts = attribs.href.split('=')[1];
              window.history.pushState(null, '', `?ts=${ts}`);
            }}>
            {domToReact(children)}
          </span>
        );
      }
    },
  };

  return (
    <div className='h-screen overflow-y-auto relative pb-16 md:pb-20 lg:pb-6 retro-page'>
      <div className='px-4 md:px-6 pt-5 pb-3 border-zinc-200 relative flex items-center justify-between border-b border-solid '>
        <div className='flex items-center mt-5 md:mt-0'>
          <div className='mr-2 lg:mr-3'>
            <PostIcon categorySlug='retrospectives' size={24} />
          </div>
          <h1 className='text-base lg:text-2xl font-medium font-serif'>{`${title}`}</h1>
        </div>
        <div className='flex flex-col justify-end'>
          <div
            className='pr-4 lg:pr-6 lg:mr-[-24px] absolute md:static top-2 right-0'
            style={{ backgroundColor: retroColor }}>
            <PostTag category='retrospectives' scale='medium' />
          </div>
        </div>
      </div>

      <div className='px-4 md:px-6'>
        <div className='mt-11 max-w-[800px] mx-auto'>
          <div className='flex justify-between'>
            <span className='uppercase text-sm'>{dayjs(isoDate).format('MMMM D, YYYY')}</span>
            <FavoriteButton post={retro} />
          </div>

          <div className='mt-4 mb-10' dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
        {!error ? (
          <div className='md:h-[450px] max-w-[800px] mx-auto bg-teal-800 rounded'>
            <VideoPlayer src={url} poster={posterSrc} chaptersSrc={chaptersSrc} />
          </div>
        ) : (
          <div className='flex flex-col items-center my-20'>
            <ExclamationTriangleIcon className='text-red-800 h-10 w-10' />
            <p className='text-xl mt-4 mb-2'>Hm. Something went wrong loading the video.</p>
            <p className='mb-2'>Error message: {error}</p>
            <p>Please try again later or try refreshing the page.</p>
          </div>
        )}

        {content && (
          <div className='retro max-w-screen-sm mt-10 text-justify mx-auto'>
            {parse(content, options)}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(Retro);
