'use client';

import { sendPasswordResetEmail } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { memo, useState } from 'react';

import { Button, Input } from 'components';
import { useToast } from 'contexts/toast';

function SendPasswordResetForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { showToast } = useToast();

  const clearForm = () => {
    setEmail('');
  };

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      await sendPasswordResetEmail(email);

      showToast({
        message: 'Email has been sent!',
        type: 'success',
      });

      clearForm();
    } catch (error) {
      const message = error?.response?.errors[0]?.message;

      showToast({
        message: message || 'An error occurred, please try again',
        type: 'error',
      });

      Bugsnag.notify(error, (event) => {
        event.context = 'onSubmit() in SendPasswordResetForm';
        event.addMetadata('Form Info', {
          email,
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form action='' className='flex flex-col items-center justify-center'>
      <Input
        placeholder='Enter your email address'
        onChange={onChange}
        type='email'
        value={email}
      />
      <Button
        type='submit'
        className='border border-solid border-white w-56 tracking-wide'
        disabled={!email}
        onClick={onSubmit}
        isLoading={isLoading}>
        Send Password Reset
      </Button>
    </form>
  );
}

export default memo(SendPasswordResetForm);
