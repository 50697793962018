'use client';

import { fetchAssetMenu } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function usePbAssets() {
  return useQuery({
    queryKey: ['pb-asset-menu'],
    queryFn: async () => {
      const res = await fetchAssetMenu();

      // @ts-ignore
      const benchmarks = res?.filter((d) => d.benchmark === 1);
      // @ts-ignore
      const menuAssets = res?.filter((d) => d.benchmark === 0);

      return {
        benchmarks,
        menuAssets,
      };
    },
  });
}
