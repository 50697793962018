import { raaVersions as coreRaaVersions } from '@3fourteen/core';

import { routes } from './routes';

export interface RaaVersion {
  displayName: string;
  href: string;
  description: string;
  whitepaper: string;
}

export const raaVersions = coreRaaVersions.map((version) => {
  return {
    ...version,
    href: routes.models[version.id],
  };
});
