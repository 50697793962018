import { Config, getAuthToken, Stage } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

import { IS_PRODUCTION } from 'services/environment';

export function useHeadObject(key: string) {
  const stage = IS_PRODUCTION ? Stage.prod : Stage.dev;

  const { data, ...rest } = useQuery({
    queryKey: ['head-object', key],
    queryFn: async () => {
      try {
        const res = await fetch(`${Config[stage].apiUrl}/user/db/head`, {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'text/plain',
            Authorization: getAuthToken(),
          },
          body: JSON.stringify({ key }),
        });

        if (!res.ok) {
          throw new Error('Failed to fetch head object');
        }

        return true;
      } catch (error) {
        return false;
      }
    },
  });

  return {
    data,
    ...rest,
  };
}
