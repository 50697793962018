import { FaceFrownIcon } from '@heroicons/react/20/solid';
import { memo } from 'react';

function UpgradeSubscription() {
  return (
    <div className='h-full w-full flex flex-col items-center justify-center'>
      <div className='flex items-center justify-center w-full mb-4'>
        <h1 className='text-2xl font-bold text-red-700'>Access Denied</h1>
        <FaceFrownIcon className='ml-3 h-10 w-10 text-red-700' />
      </div>
      <p className='mb-3'>Your subscription doesn&apos;t support access to this page.</p>
      <p>
        To upgrade, please
        <a href='mailto:sales@3fourteenresearch.com' className='ml-1 text-sky-700 font-bold'>
          reach out to us
        </a>
        .
      </p>
    </div>
  );
}

UpgradeSubscription.displayName = 'UpgradeSubscription';

export default memo(UpgradeSubscription);
