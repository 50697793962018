export const columns = [
  {
    Header: 'Active Date',
    accessor: 'date',
  },
  {
    Header: 'Ticker',
    accessor: 'ticker',
  },
  {
    Header: '% Change',
    accessor: 'pct_change',
    sortType: 'basic',
  },
  {
    Header: 'S&P 500 % Chg',
    accessor: 'sp500_change',
    sortType: 'basic',
  },
  {
    Header: 'Sector',
    accessor: 'sector',
  },
  {
    Header: 'Company',
    accessor: 'company_name',
  },
  {
    Header: 'Rank',
    accessor: 'ranking',
    sortType: (rowA, rowB, id, desc) => {
      const rowAid = Number.isInteger(rowA.original[id])
        ? rowA.original[id]
        : undefined;
      const rowBid = Number.isInteger(rowB.original[id])
        ? rowB.original[id]
        : undefined;

      if (!!rowAid && !!rowBid) {
        if (rowAid < rowBid) return -1;
        if (rowBid > rowAid) return 1;
      } else if (desc) {
        // Use this to prevent those without a rank from being part of the sort
        if (!rowAid && !!rowBid) {
          return -1;
        }
      }

      return 0;
    },
  },
  {
    Header: 'Closed',
    accessor: 'inactive_date',
  },
  {
    Header: 'Is New',
    accessor: 'is_new',
  },
  {
    Header: 'Is Old',
    accessor: 'is_old',
  },
];
