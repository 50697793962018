import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback } from 'react';

import { AssetClassBreakdownBar, BenchmarkCell, Button, DeletePortfolio } from 'components';
import { useRouter } from 'next/navigation';

export interface PortfolioRow {
  model_name: string;
  alpha_model: string;
  benchmark_series_id: string;
  rebalance_config: string;
  asset_breakdown: {
    fixedIncome: number;
    equities: number;
    alternatives: number;
    benchmark: number;
  };
  last_run: string;
}

const columnHelper = createColumnHelper<PortfolioRow>();

const ModelName = ({ value, row }) => {
  const router = useRouter();

  const goToPortfolio = useCallback(() => {
    router.push(`/portfolio-builder/portfolios/${row.original.id}`);
  }, [router, row.original.id]);

  return (
    <Button
      onClick={goToPortfolio}
      className='pl-0 flex items-center capitalize hover:underline text-sky-700'>
      <span className='mr-1 text-base'>{value}</span>
      <ArrowTopRightOnSquareIcon className='w-4 h-4' />
    </Button>
  );
};

export const columns = [
  columnHelper.accessor('model_name', {
    cell: ({ row, getValue }) => <ModelName value={getValue()} row={row} />,
    header: 'Name',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('asset_breakdown', {
    cell: ({ getValue }) => <AssetClassBreakdownBar {...getValue()} />,
    header: () => <span className='flex items-center capitalize text-15'>Asset Breakdown</span>,
    enableSorting: false,
    enableColumnFilter: false,
    footer: () => {
      return (
        <div className='flex justify-around text-sm 2xl:text-15 my-1'>
          <p className='flex items-center mr-4'>
            <span className='block w-3 h-3 2xl:w-4 2xl:h-4 rounded-full bg-olive mr-1.5'></span>
            <span>Fixed Income</span>
          </p>
          <p className='flex items-center mr-4'>
            <span className='block w-3 h-3 2xl:w-4 2xl:h-4 rounded-full bg-navy mr-1.5'></span>
            <span>Equities</span>
          </p>
          <p className='flex items-center mr-4'>
            <span className='block w-3 h-3 2xl:w-4 2xl:h-4 rounded-full bg-fuchsia mr-1.5'></span>
            <span>Alternatives</span>
          </p>
        </div>
      );
    },
  }),
  columnHelper.accessor('alpha_model', {
    cell: ({ getValue }) => <span className='leading-5 text-gray-600 text-sm'>{getValue()}</span>,
    header: 'Alpha Model',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('benchmark_series_id', {
    cell: (info) => <BenchmarkCell id={info.getValue()} />,
    header: 'Benchmark',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('rebalance_config', {
    cell: (info) => <span className='capitalize text-sm'>{info.getValue()}</span>,
    header: 'Rebalance Term',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('last_run', {
    cell: (info) => <span className='text-base'>{dayjs(info.getValue()).format('M/DD/YYYY')}</span>,
    header: 'Last Run',
    enableColumnFilter: false,
  }),
  {
    id: 'delete',
    cell: ({ row }) => {
      return <DeletePortfolio portfolio={row.original} />;
    },
    header: () => <span className='flex items-center capitalize text-15'>Delete</span>,
    enableColumnFilter: false,
    enableSorting: false,
  },
];
