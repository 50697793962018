import { MenuAsset } from '@3fourteen/core';
import { usePbAssets } from 'hooks/usePbAssets';
import { memo, useMemo } from 'react';

interface BenchmarkCellProps {
  id: MenuAsset['series_id'];
}

function BenchmarkCell({ id }: BenchmarkCellProps) {
  const { data } = usePbAssets();

  const benchmark = useMemo(() => {
    return data?.benchmarks?.find(({ series_id }) => series_id === id);
  }, [data?.benchmarks, id]);

  return (
    <span className=' text-gray-600 text-sm  w-80 whitespace-nowrap text-ellipsis overflow-hidden'>
      {benchmark?.short_name || ''}
    </span>
  );
}

BenchmarkCell.displayName = 'BenchmarkCell';

export default memo(BenchmarkCell);
