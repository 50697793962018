'use client';

import { OurPick, useToggleBoolean } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo, useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';

import { Button, EyeIcon, OurPicksTableRow, TableHead, TableSortDisplay } from 'components';

import { columns } from './columns';

interface OurPicksTableProps {
  data: OurPick[];
}

function OurPicksTable({ data }: OurPicksTableProps) {
  const [sortedColumn, setSortedColumn] = useState(undefined);
  const [showInactive, toggleInactive] = useToggleBoolean(false);
  const isSmallScreen = useMediaQuery(768);
  const hideText = isSmallScreen ? 'Inactive' : 'Hide Inactive';
  const showText = isSmallScreen ? 'Inactive' : 'Show Inactive';

  const cols = useMemo(() => {
    return columns;
  }, []);

  const tableData = useMemo(() => {
    let picksData = [...data];

    if (!showInactive) {
      picksData = [...data].filter(({ inactiveDate }) => !inactiveDate);
    }

    const rows = picksData.map(({ ticker, date, pct_change, sp500_change, inactiveDate }) => {
      return {
        date,
        ticker,
        pct_change,
        sp500_change,
        report: null,
        inactiveDate,
      };
    });

    return rows;
  }, [data, showInactive]);

  const tableInstance = useTable(
    {
      columns: cols,
      data: tableData,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'date', desc: true }],
      },
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const headers = headerGroups[0].headers;

  const sorted = headers.find((column) => column.sortedIndex !== -1);

  useEffect(() => {
    setSortedColumn(sorted);
  }, [sorted]);

  const sortOrder = !sortedColumn ? undefined : sortedColumn.isSortedDesc ? 'DESC' : 'ASC';

  return (
    <div className='pb-16 lg:pb-20 xl:pb-0'>
      <div className='flex w-full items-baseline'>
        <TableSortDisplay sortOption={sortedColumn?.Header} sortOrder={sortOrder} />
        <div className='w-full md:w-auto flex justify-end md:block py-4 px-4 md:py-0 md:pl-0 md:pr-6'>
          <Button onClick={toggleInactive} className='btn-secondary btn-xs'>
            <EyeIcon isOpen={!showInactive} color='#254653' size={16} />
            <span className='inline-block ml-1.5 text-15 w-14 md:w-24'>
              {showInactive ? hideText : showText}
            </span>
          </Button>
        </div>
      </div>
      <div className='border-b border-solid border-zinc-200 table-container'>
        <table {...getTableProps()} className='w-full border-collapse border-spacing-0'>
          <TableHead headerGroups={[...headerGroups]} sortable={true} />
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const inactiveRow = !!row.cells[5].value;

              return <OurPicksTableRow key={i} row={row} inactiveRow={inactiveRow} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default memo(OurPicksTable);
