'use client';

import { motion } from 'framer-motion';
import { memo, useEffect, useState } from 'react';

import { LoadingDots, ProgressBar } from 'components';

const messages = [
  { message: 'We got your request.', delay: 2 },
  { message: '', delay: 3 },
  { message: '', delay: 5 },
  {
    message: '',
    delay: 7,
  },
  {
    message: 'This could take anywhere from 20s to 2 minutes depending on your configuration.',
    delay: 8,
  },
  { message: '', delay: 12 },
  { message: '', delay: 15 },
  { message: '', delay: 19 },
  { message: '', delay: 20 },
  { message: '', delay: 22 },
  { message: '', delay: 28 },
  { message: '', delay: 31 },
  { message: '', delay: 33 },
  { message: '', delay: 35 },
  { message: '', delay: 38 },
  {
    message: '',
    delay: 42,
  },
  {
    message: '',
    delay: 56,
  },
  {
    message: '',
    delay: 60,
  },
  {
    message: 'This should be wrapping up...',
    delay: 65,
  },
  {
    message: '',
    delay: 72,
  },
  {
    message: '',
    delay: 80,
  },
  {
    message: '',
    delay: 88,
  },
  {
    message: '',
    delay: 90,
  },
  {
    message: '',
    delay: 102,
  },
];

const list = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const listItems = {
  hidden: {
    opacity: 0,
    display: 'none',
  },
  visible: (i) => ({
    opacity: 1,
    display: 'block',
    transition: {
      delay: messages[i].delay,
    },
  }),
};

let timeouts = [];

function cancelTimeouts() {
  for (var i = 0; i < timeouts.length; i++) {
    clearTimeout(timeouts[i]);
  }

  timeouts = [];
}

function PortfolioBuilderLoadingState({ numOfAssets }: { numOfAssets: number }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const maxExpectedTime =
      numOfAssets < 12 ? 75 : numOfAssets >= 12 && numOfAssets <= 30 ? 90 : 105;
    for (let i = 0; i < messages.length; i++) {
      (function (i) {
        timeouts.push(
          setTimeout(function () {
            console.log('setting progress: ', messages[i].delay / maxExpectedTime);
            const prog = messages[i].delay / maxExpectedTime;

            if (prog < 1) {
              setProgress(messages[i].delay / maxExpectedTime);
            }
          }, messages[i].delay * 1000)
        );
      })(i); //Pass current value into self-executing anonymous function
    }

    return () => {
      cancelTimeouts();
    };
  }, [numOfAssets]);

  return (
    <div className='w-full h-full flex flex-col items-center mt-[10vw]'>
      <p className='text-center mb-6 text-xl text-teal-600 font-medium'>{`Generating your ${numOfAssets}-asset portfolio`}</p>
      <div className='flex items-center w-96 mb-4'>
        <ProgressBar progress={progress} />
      </div>
      <motion.ol animate='visible' initial='hidden' variants={list}>
        {messages.map((item, i) => {
          if (!item.message) return null;

          return (
            <motion.li key={i} custom={i} variants={listItems} className='text-center block mb-2'>
              {item.message}
            </motion.li>
          );
        })}
      </motion.ol>
      <LoadingDots />
    </div>
  );
}

PortfolioBuilderLoadingState.displayName = 'PortfolioBuilderLoadingState';

export default memo(PortfolioBuilderLoadingState);
