'use client';

import { FullCycleStock, useToggleBoolean } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';

import { Button, DownloadButton, EyeIcon, FullCycleTableRow, TableHead, TableSortDisplay } from 'components';

import { columns } from './columns';

interface FullCycleTableProps {
  data?: FullCycleStock[];
}

function FullCycleTable({ data }: FullCycleTableProps) {
  const [sortedColumn, setSortedColumn] = useState(undefined);
  const [showInactive, toggleInactive] = useToggleBoolean(false);
  const isSmallScreen = useMediaQuery(768);
  const hideText = isSmallScreen ? 'Closed' : 'Hide All Closed';
  const showText = isSmallScreen ? 'Closed' : 'Show All Closed';

  const { url: csvUrl } = useSignedCloudfrontUrl('trend_quality.csv');

  const cols = useMemo(() => columns, []);

  const tableData = useMemo(() => {
    let fcStocks = [...data];

    if (!showInactive) {
      fcStocks = [...data].filter(({ inactive_date, is_old }) => !inactive_date || is_old);
    }

    const rows = fcStocks.map(
      ({
        ticker,
        date,
        pct_change,
        sp500_change,
        sector,
        company_name,
        ranking,
        inactive_date,
        is_new,
        is_old,
      }) => ({
        date,
        ticker,
        pct_change,
        sp500_change,
        sector,
        company_name,
        ranking,
        inactive_date,
        is_new,
        is_old,
      })
    );

    return rows;
  }, [data, showInactive]);

  const tableInstance = useTable(
    {
      columns: cols,
      data: tableData,
      disableSortRemove: true,
      initialState: {
        hiddenColumns: ['is_new', 'is_old'],
      },
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const { headers } = headerGroups[0];

  const sorted = headers.find((column) => column.sortedIndex !== -1);

  useEffect(() => {
    setSortedColumn(sorted);
  }, [sorted]);

  const clearSorting = useCallback(() => {
    sorted.clearSortBy();
  }, [sorted]);

  const sortOrder = !sortedColumn ? undefined : sortedColumn.isSortedDesc ? 'DESC' : 'ASC';

  return (
    <div>
      <div className='flex items-center w-full'>
        <TableSortDisplay
          sortOption={sortedColumn?.Header}
          sortOrder={sortOrder}
          onResetSort={clearSorting}
          containerStyles={{
            marginRight: 8,
          }}
        />
        <div className='w-full md:w-auto flex justify-end md:justify-start p-4 md:p-0 md:pr-6'>
          <div className='mr-2'>
            <Button onClick={toggleInactive} className='btn-secondary btn-xs'>
              <EyeIcon isOpen={!showInactive} color='#254653' size={16} />
              <span className='inline-block ml-1.5'>{showInactive ? hideText : showText}</span>
            </Button>
          </div>
          {csvUrl && (
            <DownloadButton
              text={isSmallScreen ? 'CSV' : 'Download CSV'}
              href={csvUrl}
              download='trend_quality.csv'
              data-testid='download-fct-csv-btn'
            />
          )}
        </div>
      </div>
      <div className='border-b border-solid border-zinc-200 table-container'>
        <table {...getTableProps()} className='bg-white w-full border-collapse border-spacing-0'>
          <TableHead headerGroups={[...headerGroups]} sortable />
          <tbody {...getTableBodyProps()} data-testid='full-cycle-tbody'>
            {rows.map((row, i) => {
              prepareRow(row);
              const inactiveRow = !!row.cells[7].value;

              return <FullCycleTableRow key={i} row={row} inactiveRow={inactiveRow} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default memo(FullCycleTable);
