import { memo } from 'react';

import { AntIcon, Button } from 'components';

interface BottomBarButton {
  El?: React.ReactElement;
  text?: string;
  icon?: string;
  onClick?: () => void;
}

interface BottomBarProps {
  buttons: BottomBarButton[];
}

/**
 * Action buttons for mobile view
 */
function BottomBar({ buttons }: BottomBarProps) {
  return (
    <div className='lg:hidden fixed right-0 left-0 bottom-0 z-[999] pb-safe-half bg-white/50'>
      <div className='flex justify-center mb-3'>
        {buttons.map((btn, i) => {
          const { text, icon, onClick, El } = btn;

          return (
            <div key={i} className='flex-1 px-2 md:px-4 flex justify-center'>
              {!!btn.El && (
                <div className='btn btn-primary flex-1'>{btn.El}</div>
              )}
              {!!btn.onClick && (
                <Button onClick={onClick} className='btn-primary w-full'>
                  <span className='mr-1'>
                    <AntIcon name={icon} color='white' />
                  </span>
                  <span className='tracking-wide'>{text}</span>
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(BottomBar);
