import { memo } from 'react';

import { CaretIcon } from 'components';

interface WeightChangeIconProps {
  currentWeight: number;
  previousWeight: number;
}

function WeightChangeIcon({
  currentWeight,
  previousWeight,
}: WeightChangeIconProps) {
  const change = currentWeight - previousWeight;

  return (
    <CaretIcon direction={change === 0 ? null : change > 0 ? "up" : "down"} />
  );
}

export default memo(WeightChangeIcon);
