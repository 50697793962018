import { fetchPerformance, ModelCode } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useStats(model: ModelCode, opts = {}) {
  const { data: stats, ...rest } = useQuery<any>({
    queryKey: [`${model}-stats`],
    queryFn: () => {
      return fetchPerformance(model);
    },
    ...opts,
  });

  return {
    stats,
    ...rest,
  };
}
