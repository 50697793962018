'use client';

import { classNames } from 'helpers/classNames';
import React, { memo, useMemo } from 'react';

import { AntIcon, SimpleLineIcon } from 'components';
import Link, { LinkProps } from 'next/link';
import { usePathname } from 'next/navigation';
import { IconFamily, ParentItem } from 'services/links';

export interface NavLinkProps extends LinkProps {
  text: string;
  className?: string;
  icon?: string;
  onClick?: () => void;
  visible?: boolean;
  childOf?: ParentItem;
  iconFamily?: IconFamily;
  parent?: string;
  isExpanded?: boolean;
}

function NavLink({
  text,
  href,
  childOf,
  icon,
  onClick,
  visible = true,
  iconFamily,
  parent,
  isExpanded,
  ...props
}: NavLinkProps) {
  const pathname = usePathname();
  const navItemClasses = `leading-tight uppercase text-15 cursor-pointer flex items-center justify-between hover:bg-white/10 transition-colors ease-in-out duration-200 ${props.className}`;
  const hierarchicalClasses = !!childOf
    ? classNames(navItemClasses, 'pl-4 capitalize mx-0 font-light')
    : navItemClasses;

  const classes =
    pathname === href
      ? classNames(hierarchicalClasses, 'bg-white/20 font-semibold')
      : hierarchicalClasses;

  const navItem = useMemo(
    () => (
      <div
        title={text}
        data-testid={`nav-link-${text}`}
        className={classNames(
          !!childOf ? '' : ' 2xl:py-2',
          'inline-block py-1.5 px-6 xl:pr-0 w-full'
        )}>
        <span className='mr-0 xl:mr-3 inline-block'>
          {iconFamily === 'ant-design' ? (
            <AntIcon name={icon} size={16} color='white' />
          ) : (
            <SimpleLineIcon name={icon} size={16} color='white' />
          )}
        </span>
        <span className='hidden xl:inline-flex'>{text}</span>
      </div>
    ),
    [text, icon, iconFamily, childOf]
  );

  if (!visible) {
    return null;
  }

  if (!href) {
    return (
      <div className={classes} role='button' onClick={onClick}>
        {navItem}
      </div>
    );
  }

  return (
    <div className={classes}>
      <Link href={href} {...props} className='w-full'>
        {navItem}
      </Link>
      {!!parent && (
        <span className='pr-3 hidden xl:block' onClick={onClick}>
          {isExpanded ? (
            <AntIcon name='caretright' size={12} color='white' />
          ) : (
            <AntIcon name='caretdown' size={12} color='white' />
          )}
        </span>
      )}
    </div>
  );
}

export default memo(NavLink);
