import { memo, ReactNode } from 'react';

interface PageHeaderProps {
  heading: string | ReactNode;
  className?: string;
  leftEl?: ReactNode;
  rightEl?: ReactNode;
}

function PageHeader({ heading, className, leftEl, rightEl }: PageHeaderProps) {
  return (
    <div
      className={`h-[50px] md:h-[80px] md:px-6 flex-shrink-0 flex justify-between items-center border-b border-solid border-black/20 ${className}`}>
      <div className='flex flex-1 items-baseline'>
        {leftEl ? leftEl : null}
        {typeof heading === 'string' ? (
          <h1 className='flex-1 text-xl md:text-2xl text-center md:text-left font-semibold'>
            {heading}
          </h1>
        ) : (
          heading
        )}
      </div>
      {rightEl ? rightEl : null}
    </div>
  );
}

PageHeader.displayName = 'PageHeader';

export default memo(PageHeader);
