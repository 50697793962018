'use client';

import { useMember } from 'hooks/useMember';
import React, { memo } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { ChangePasswordForm, EditProfileForm, MySubscriptions } from 'components';

const AccountTabs = () => {
  const { isPbUser } = useMember();

  return (
    <div className='max-w-[600px] my-10 mx-auto'>
      <Tabs forceRenderTabPanel={true}>
        <TabList>
          <Tab>Update Profile</Tab>
          <Tab>Change Password</Tab>
          {isPbUser && <Tab>Subscription</Tab>}
        </TabList>
        <TabPanel>
          <div className='py-16 px-4 md:px-6'>
            <h2 className='font-semibold mb-4 text-lg text-center'>Profile Information</h2>
            <EditProfileForm />
          </div>
        </TabPanel>
        <TabPanel>
          <div className='py-16 px-4 md:px-6'>
            <h2 className='font-semibold mb-4 text-lg text-center'>Password Requirements</h2>
            <p>
              Your password must be between 6 to 20 characters and contain one number, one uppercase
              letter and one lowercase letter.
            </p>
            <div className='py-4 md:py-6'>
              <ChangePasswordForm />
            </div>
          </div>
        </TabPanel>
        {isPbUser && (
          <TabPanel>
            <div className='py-16 px-4 md:px-6'>
              <h2 className='font-semibold mb-4 text-lg text-center'>Subscription Information</h2>
              <MySubscriptions />
            </div>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

AccountTabs.displayName = 'AccountTabs';

export default memo(AccountTabs);
