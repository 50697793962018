import { memo } from 'react';

function SortIcon({
  size = 16,
  color = "#373e41",
}: {
  size?: number;
  color?: string;
}) {
  return (
    <span style={{ display: "inline-block", width: size, height: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 18.75"
        version="1.1"
        x="0px"
        y="0px"
      >
        <title>noun_96801_cc</title>
        <desc>Created with Sketch.</desc>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-289.000000, -221.000000)">
            <g transform="translate(24.000000, 205.000000)">
              <g transform="translate(265.000000, 13.000000)">
                <g transform="translate(0.000000, 3.000000)">
                  <path
                    d="M7.55555556,2.77777778 L15.1394442,2.77777778"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.55555556,5.61111111 L12.3061113,5.61111111"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.55555556,8.44444444 L10.4172222,8.44444444"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.04753337,12.9028579 L3.04753337,0.547581181 C3.04753337,0.245160445 3.30122663,0 3.59444262,0 C3.89649226,0 4.14135187,0.235023774 4.14135187,0.547581181 L4.14135187,12.9045651 L6.25957464,10.7863423 C6.46960409,10.5763129 6.81925482,10.5854386 7.02658983,10.7927736 C7.24017118,11.006355 7.23922635,11.3535836 7.03302112,11.5597888 L3.59280994,15 L2.81936346,14.2265535 L2.82184973,14.2240673 L0.153902781,11.5561203 C-0.0523024468,11.3499151 -0.0532472807,11.0026865 0.160334067,10.7891051 C0.367669084,10.5817701 0.717319813,10.5726444 0.927349259,10.7826738 L3.04753337,12.9028579 Z"
                    fill={color}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default memo(SortIcon);
