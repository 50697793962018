import { formatPercent } from '@3fourteen/core';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { HTMLProps } from 'react';

import { AssetClassCell, DeselectAllHeader, StartDatePicker, WeightField } from 'components';

import { AssetRow } from './types';

const columnHelper = createColumnHelper<AssetRow>();

function RowSelectCheckbox({ ...rest }: HTMLProps<HTMLInputElement>) {
  return (
    <input
      type='checkbox'
      className='scroll-mt-8 h-4 w-4 rounded border-gray-300 text-teal-800 focus:ring-teal-600 cursor-pointer mr-3 mt-0.5'
      {...rest}
    />
  );
}

export const columns = [
  columnHelper.accessor('isSelected', {
    cell: ({ row, table, column }) => {
      const onChange = (e) => {
        table.options.meta.updateData(row.index, column.id, e.target.checked);
      };

      return (
        <label
          htmlFor={`select-${row.id}-checkbox`}
          className='px-1 flex justify-center'>
          <RowSelectCheckbox
            {...{
              id: `select-${row.id}-checkbox`,
              checked: !!row.getValue('isSelected'),
              onChange,
            }}
          />
        </label>
      );
    },
    header: ({ table }) => <DeselectAllHeader table={table} />,
    enableColumnFilter: false,
    enableSorting: false,
  }),
  columnHelper.accessor('asset_class', {
    cell: ({ getValue }) => <AssetClassCell assetClass={getValue()} />,
    header: 'Asset Class',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('short_name', {
    cell: ({ getValue }) => (
      <span className='leading-5 text-gray-600 font-medium'>{getValue()}</span>
    ),
    header: 'Short Name',
  }),
  columnHelper.accessor('bench_weight', {
    cell: (info) => <WeightField info={info} inputId='bench_weight' />,
    header: () => (
      <div className='flex items-center z-20 relative'>
        <p>Benchmark </p>
        <div className='font-normal text-base'>
          <span
            data-tooltip-id='benchmark-tip'
            data-tooltip-html='<p style="font-size: 14px;">The default weight for the asset.</p><p style="font-size: 13px; font-weight: normal;">Alpha models will tilt around and modify this baseline exposure.</p>'>
            <InformationCircleIcon className='h-4 w-4 ml-0.5' />
          </span>
        </div>
      </div>
    ),
    enableColumnFilter: false,
    meta: {
      htmlFor: 'bench_weight',
    },
  }),
  columnHelper.accessor('min_weight', {
    cell: (info) => <WeightField info={info} inputId='min_weight' />,
    header: () => (
      <div className='flex items-center z-20 relative'>
        <p>Min Wgt </p>
        <div className='font-normal text-base'>
          <span
            data-tooltip-id='min-tip'
            data-tooltip-html='<p style="font-size: 14px;">A forced minimum allocation to the asset.</p><p style="font-size: 13px; font-weight: normal;">Alpha models will be constrained to at least this much weight as they tilt exposure.</p>'>
            <InformationCircleIcon className='h-4 w-4 ml-0.5' />
          </span>
        </div>
      </div>
    ),
    enableColumnFilter: false,
    meta: {
      htmlFor: 'min_weight',
    },
  }),
  columnHelper.accessor('max_weight', {
    cell: (info) => <WeightField info={info} inputId='max_weight' />,
    header: () => (
      <div className='flex items-center z-20 relative'>
        <p>Max Wgt </p>
        <div className='font-normal text-base'>
          <span
            data-tooltip-id='max-tip'
            data-tooltip-html='<p style="font-size: 14px;">A forced maximum allocation to the asset.</p><p style="font-size: 13px; font-weight: normal;">Alpha models will be constrained to no more than this much weight as they tilt exposure.</p>'>
            <InformationCircleIcon className='h-4 w-4 ml-0.5' />
          </span>
        </div>
      </div>
    ),
    enableColumnFilter: false,
    meta: {
      htmlFor: 'max_weight',
    },
  }),
  columnHelper.accessor('start_date', {
    cell: (info) => {
      return <StartDatePicker info={info} />;
    },
    header: () => (
      <div>
        <p>Start Date</p>
      </div>
    ),
    meta: {
      htmlFor: `start_date`,
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor('cagr', {
    cell: (info) => formatPercent(info.getValue()),
    header: 'CAGR',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('sharpe', {
    cell: (info) => info.getValue().toFixed(2),
    header: 'SHARPE',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('max_dd', {
    cell: (info) => formatPercent(info.getValue()),
    header: 'MAX DD',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('vol', {
    cell: (info) => formatPercent(info.getValue()),
    header: 'VOL',
    enableColumnFilter: false,
  }),
];
