'use client';

import { PlusIcon } from '@heroicons/react/20/solid';
import { fixRounding } from 'helpers/fixRounding';
import { getAssetClassBreakdown } from 'helpers/getAssetClassBreakdown';
import { useMyPortfolios } from 'hooks/useMyPortfolios';
import { usePbAssets } from 'hooks/usePbAssets';
import { memo, useCallback, useEffect, useState } from 'react';

import { AntIcon, Button, LoadingDots, MyPortfoliosTable, PageHeader } from 'components';
import Head from 'next/head';
import { useRouter } from 'next/navigation';
import { routes } from 'services/routes';

const pageTitle = 'My Portfolios | 3FR';

function MyPortfolios() {
  const { portfolios, isLoading: isPortfoliosLoading, error: portfoliosError } = useMyPortfolios();
  const [data, setData] = useState(undefined);
  const { data: menuData, isLoading: isAssetsLoading, error: assetsError } = usePbAssets();
  const router = useRouter();

  useEffect(() => {
    if (!isPortfoliosLoading && !portfolios.length) {
      router.push(routes.portfolioBuilder.demo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortfoliosLoading, portfolios]);

  useEffect(() => {
    if (portfolios && menuData?.menuAssets) {
      const formatted = portfolios.map(({ id, config, last_modified }) => {
        const assetsWithClass = config.assets.map((asset) => {
          const menuAsset = menuData?.menuAssets.find(
            ({ series_id }) => series_id === asset.series_id
          );

          return {
            ...asset,
            bench_weight: fixRounding(asset.bench_weight * 100, 1),
            asset_class: menuAsset.asset_class,
          };
        });

        return {
          id,
          ...config,
          alpha_model: config.alpha_model.name,
          asset_breakdown: getAssetClassBreakdown(assetsWithClass),
          last_run: last_modified,
        };
      });

      setData([...formatted]);
    }
  }, [portfolios, menuData?.menuAssets]);

  const goToBuilder = useCallback(() => {
    router.push(routes.portfolioBuilder.root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='min-h-screen flex flex-col'>
      <Head>
        <title>{pageTitle}</title>
        <meta property='og:title' content={pageTitle} key='title' />
      </Head>

      {(isPortfoliosLoading || isAssetsLoading) && (
        <div className='flex flex-1 items-center justify-center'>
          <LoadingDots color='#999' size='regular' />
        </div>
      )}
      {data ? (
        <>
          {!!data.length && (
            <div className='mt-3 mb-5'>
              <PageHeader
                leftEl={
                  <div className='mr-2'>
                    <AntIcon name='folder1' size={22} />
                  </div>
                }
                heading={
                  <div className='flex items-center'>
                    <h1 className='flex-1 text-xl md:text-2xl text-center md:text-left font-semibold mr-4'>
                      My Portfolios
                    </h1>
                    <Button
                      className='btn btn-primary p-0 h-9 w-9 rounded-full'
                      onClick={goToBuilder}>
                      <PlusIcon className='h-6 w-6 text-white' />
                    </Button>
                  </div>
                }
                className='border-none'
              />
              <MyPortfoliosTable portfolios={data} />
            </div>
          )}
        </>
      ) : (
        <>
          {(portfoliosError || assetsError) && (
            <div className='flex-1 flex flex-col justify-center items-center'>
              <p className='text-2xl'>There was an error loading your portfolios.</p>
              <p>Please check back later.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

MyPortfolios.displayName = 'MyPortfolios';

export default memo(MyPortfolios);
