'use client';

import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { ToastMessage } from 'components';

export const ToastContext = createContext({});

interface Toast {
  message: string;
  type?: 'success' | 'error' | 'warning' | 'info';
}

interface ToastContextType {
  showToast: (toast: Toast) => void;
}

export function ToastProvider({ children }) {
  const [toast, setToast] = useState<Toast | null>(null);

  useEffect(() => {
    if (!!toast) {
      const timeoutId = setTimeout(() => {
        setToast(null);
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [toast]);

  const showToast = useCallback(
    (toast) => {
      setToast(toast);
    },
    [setToast]
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastMessage message={toast?.message} visible={!!toast} type={toast?.type} />
    </ToastContext.Provider>
  );
}

export const useToast = () => useContext(ToastContext) as ToastContextType;
