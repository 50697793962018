'use client';

import { deletePortfolio, MyPortfolio } from '@3fourteen/core';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { myPortfoliosQueryKey } from 'hooks/useMyPortfolios';
import { Fragment, memo, useState } from 'react';

import { Button } from 'components';

interface DeletePortfolioProps {
  isVisible?: boolean;
  portfolio: {
    model_name: MyPortfolio['config']['model_name'];
    id: MyPortfolio['id'];
  };
}

function DeletePortfolio({ portfolio }: DeletePortfolioProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { model_name, id } = portfolio;

  const onDelete = async () => {
    setLoading(true);
    try {
      await deletePortfolio(id);

      await queryClient.refetchQueries({
        queryKey: [myPortfoliosQueryKey],
        type: 'active',
        exact: true,
      });

      setOpen(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button onClick={() => setOpen(true)} className='px-1 flex justify-center'>
        <XCircleIcon className='w-7 h-7 text-red-700 font-bold' />
      </Button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg font-medium leading-6 text-gray-900 mb-4'>
                        {`Delete ${model_name}?`}
                      </Dialog.Title>
                      <div className='mt-2 text-gray-700 mb-8'>
                        <p>{`Are you sure you want to delete ${model_name}? `}</p>
                        <p>This action cannot be undone.</p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                    <Button
                      type='button'
                      isLoading={loading}
                      className='btn uppercase bg-red-700 text-white'
                      onClick={onDelete}>
                      Delete
                    </Button>
                    <Button
                      type='button'
                      className='btn border border-solid border-gray-500 text-gray-800'
                      onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

DeletePortfolio.displayName = 'DeletePortfolio';

export default memo(DeletePortfolio);
