'use client';

import { ArrowLeftIcon, ArrowRightIcon, ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactDatepicker from 'react-datepicker';

import { DatePickerMonthSelect } from 'components/DatePickerMonthSelect';
import { months } from 'components/DatePickerMonthSelect/component';
import { DatePickerYearSelect } from 'components/DatePickerYearSelect';

function getRenderValueAsDate(val) {
  if (val) {
    return new Date(
      new Date(val).toLocaleString('en-US', {
        timeZone: 'UTC',
      })
    );
  }
  return null;
}

const StartDatePicker = (props: any) => {
  const datepickerRef = useRef(null);
  const {
    cell,
    getValue,
    row: { index: rowIndex, original },
    column: { id },
    table,
  } = props.info;
  const defaultStartDate = original.defaults.start_date;
  const noDisabledDates = defaultStartDate.endsWith('-01-01');

  const initialValue = getValue();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onChange = useCallback(
    (newVal) => {
      const formatted = dayjs(getRenderValueAsDate(newVal)).format('YYYY-MM-DD');
      setValue(formatted);

      table.options.meta?.updateData(rowIndex, id, formatted);
    },
    [table, id, rowIndex]
  );

  // Set input id so edit icon is functional
  useEffect(() => {
    datepickerRef.current.input.id = `portfolioAssets.${rowIndex}.start_date`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
    // onChange(initialValue);
  }, [initialValue, onChange]);

  return (
    <ReactDatepicker
      ref={datepickerRef}
      selected={getRenderValueAsDate(value)}
      onChange={onChange}
      excludeDateIntervals={
        !noDisabledDates
          ? [
              {
                start: new Date(`${new Date(defaultStartDate).getFullYear()}-01-01`),
                end: dayjs(defaultStartDate).subtract(1, 'day').toDate(),
              },
            ]
          : undefined
      }
      renderCustomHeader={(props) => {
        const {
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        } = props;
        return (
          <div className='flex justify-between mx-4 mb-4'>
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <ArrowLeftIcon className='h-5 2-5 text-white font-bold' />
            </button>
            <div className='flex mx-3'>
              <div className='mr-1'>
                <DatePickerMonthSelect
                  value={months[new Date(date).getMonth()]}
                  onChange={(value) => {
                    changeMonth(months.indexOf(value));
                  }}
                />
              </div>

              <div className='mx-1'>
                <DatePickerYearSelect
                  defaultStartDate={defaultStartDate}
                  value={new Date(date).getFullYear()}
                  onChange={(value) => {
                    changeYear(value);
                  }}
                />
              </div>

              <button
                title='Reset Date'
                onClick={() => {
                  datepickerRef.current.setPreSelection(getRenderValueAsDate(defaultStartDate));
                  onChange(defaultStartDate);
                }}
                className='bg-white/20 relative ml-1 w-full h-full cursor-pointer rounded-md border border-gray-300  text-white py-2 px-3 text-left shadow-sm focus:border-gray-50 focus:ring-1 focus:ring-gray-50'>
                <ArrowUturnLeftIcon className='w-4 h-4 text-white' />
              </button>
            </div>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <ArrowRightIcon className='h-5 2-5 text-white font-bold' />
            </button>
          </div>
        );
      }}
    />
  );
};

StartDatePicker.displayName = 'StartDatePicker';

export default memo(StartDatePicker);
