'use client';

import {
  AllocationModelCode,
  formatWeight,
  getAssetTableCellColor,
  RealAAAsset,
  RealAAParentAsset,
  YoAsset,
} from '@3fourteen/core';
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  ArrowsUpDownIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { classNames } from 'helpers/classNames';
import { memo, useEffect, useMemo, useState } from 'react';

import { Tooltip } from 'components';

interface AssetsTableProps {
  id: AllocationModelCode;
  readings: RealAAParentAsset[] | YoAsset[];
  dates: string[];
}

const INTRAPERIOD = 'intraperiod-toggle';

interface AssetRow {
  asset: string;
  ticker: string;
  benchmark: number;
  max: number;
  date0: number;
  date1: number;
  date2: number;
  date3: number;
}

const columnHelper = createColumnHelper<AssetRow>();

function AssetsTable({ id, readings, dates }: AssetsTableProps) {
  const { hasBenchmark, hasMaxWeight } = useMemo(
    () => ({
      hasBenchmark: !!(readings[0] as RealAAAsset).benchmark_weight,
      hasMaxWeight: !!(readings[0] as RealAAParentAsset).children?.[0].max_weight,
    }),
    [readings]
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const localColVisItem = `${id}-columnVisibility`;
  const localColVisState = JSON.parse(localStorage.getItem(localColVisItem));

  // If there's no local state for column visibility, return undefined
  // Otherwise, if there's a "max" property, return the state
  // If there's no "max" property, return the state with the "max" property on it
  const initialColState = !localColVisState
    ? undefined
    : 'max' in localColVisState
    ? localColVisState
    : {
        ...localColVisState,
        max: hasMaxWeight,
      };

  const [columnVisibility, setColumnVisibility] = useState(
    initialColState || {
      date0: false,
      benchmark: hasBenchmark,
      max: hasMaxWeight,
    }
  );

  useEffect(() => {
    localStorage.setItem(localColVisItem, JSON.stringify(columnVisibility));
  }, [columnVisibility, localColVisItem]);

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('asset', {
        cell: (info) => info.getValue(),
        header: 'Asset',
      }),
      columnHelper.accessor('ticker', {
        cell: (info) => {
          return <span className='inline-block pl-4'>{info.getValue()}</span>;
        },
        header: () => (
          <div className='flex items-center'>
            <span>Related Tickers</span>
            <div className='font-normal text-base'>
              <span
                data-tooltip-id='ticker-tip'
                data-tooltip-html='<p style="font-size: 14px;">Potential implementation ETFs</p><p style="font-size: 13px; font-weight: normal;">The Model does not utilize any of these vehicles.</p>'>
                <InformationCircleIcon className='h-4 w-4 ml-0.5' />
              </span>
            </div>
          </div>
        ),
        sortUndefined: 1,
      }),
      columnHelper.accessor('benchmark', {
        cell: (info) => <span className='pl-4'>{formatWeight(info.getValue() as number, 0)}</span>,
        header: 'Benchmark',
      }),
    ];

    const dateCols = dates.map((date, i) =>
      // @ts-ignore
      columnHelper.accessor(`date${i}`, {
        cell: ({ row, getValue }) => {
          const decimalPlaces = hasBenchmark ? 0 : 1;
          // Old RAA doesn't have benchmark
          // This might want to find weight change based on last and 2nd last rebal dates, though (versus intramonth)

          const secondWeight = hasBenchmark ? row.original.benchmark : row.original.date2;

          const weightChange = getAssetTableCellColor(
            row.original.date1.toFixed(2),
            secondWeight.toFixed(2)
          );

          const weightClasses =
            weightChange === 'decrease'
              ? 'bg-terracotta-500 text-white'
              : weightChange === 'increase'
              ? 'bg-sage-500 text-white'
              : '';

          return (
            <span
              className={classNames(i === 1 ? `${weightClasses} font-semibold block` : '', 'pl-4')}>
              {formatWeight(getValue() as number, decimalPlaces)}
            </span>
          );
        },
        header: ({ header }) => {
          switch (header.id) {
            case 'date0':
              return (
                <div className='flex items-center'>
                  <span>{dayjs(date).format('MMM D')}</span>
                  <div className='flex items-center text-sm font-normal'>
                    <span className=' ml-2'>(Intra-period</span>
                    <div className='text-base max-w-xs'>
                      <span
                        data-tooltip-id='intraperiod-tip'
                        data-tooltip-html='<p style="font-size: 14px;">Intra-period weights</p><p style="font-size: 13px; font-weight: normal;">These are not used in the Model. They are provided only for informational purposes only.</p>'>
                        <InformationCircleIcon className='h-4 w-4 ml-0.5' />
                      </span>
                    </div>
                    )
                  </div>
                </div>
              );

            case 'date1': {
              return (
                <div className='flex items-center'>
                  <span>{dayjs(date).format('MMM D')}</span>
                  <div className='flex items-center text-sm font-normal'>
                    <span className=' ml-2'>(Official</span>
                    <div className='text-base max-w-xs'>
                      <span
                        data-tooltip-id='official-tip'
                        data-tooltip-html='<p style="font-size: 14px;">Last rebalance weights</p><p style="font-size: 13px; font-weight: normal;">The weights are the official weights of the Model at present.</p>'>
                        <InformationCircleIcon className='h-4 w-4 ml-0.5' />
                      </span>
                    </div>
                    )
                  </div>
                </div>
              );
            }
            default:
              return <span>{dayjs(date).format('MMM D')}</span>;
          }
        },
      })
    );

    return [
      ...cols,
      ...dateCols,
      columnHelper.accessor('max', {
        cell: (info) => (
          <span className='pl-4'>
            {info.getValue() ? formatWeight(info.getValue() as number, 0) : null}
          </span>
        ),
        header: () => {
          return (
            <div className='flex items-center'>
              <span>Max Wgt</span>
              {/* <div className='flex items-center text-sm font-normal'>
                <div className='text-base max-w-xs'>
                  <span
                    data-tooltip-id='official-tip'
                    data-tooltip-html='<p style="font-size: 14px;">Description</p><p style="font-size: 13px; font-weight: normal;">Text.</p>'>
                    <InformationCircleIcon className='h-4 w-4 ml-0.5' />
                  </span>
                </div>
              </div> */}
            </div>
          );
        },
        sortUndefined: 1,
      }),
    ];
  }, [dates, hasBenchmark]);

  const tableData = useMemo(() => {
    const rows = [];

    readings.forEach((reading) => {
      rows.push({
        asset: reading.name,
        ticker: reading.ticker,
        benchmark: reading.benchmark_weight,
        date0: reading.weight[0],
        date1: reading.weight[1],
        date2: reading.weight[2],
        date3: reading.weight[3],
        hasChildren: !!reading.children,
      });

      if (!!reading.children) {
        reading.children.forEach((child) => {
          rows.push({
            asset: child.name,
            ticker: child.ticker,
            benchmark: child.benchmark_weight,
            max: child.max_weight,
            date0: child.weight[0],
            date1: child.weight[1],
            date2: child.weight[2],
            date3: child.weight[3],
            isChild: true,
          });
        });
      }
    });

    return rows;
  }, [readings]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const isIntraperiodVisible = table.getColumn('date0').getIsVisible();

  return (
    <div>
      <div className='flex px-4 md:px-6 pt-4 pb-3 justify-end md:justify-start'>
        <label htmlFor={INTRAPERIOD} className='flex items-center'>
          <input
            name={INTRAPERIOD}
            id={INTRAPERIOD}
            type='checkbox'
            checked={isIntraperiodVisible}
            onChange={table.getColumn('date0').getToggleVisibilityHandler()}
            className='scroll-mt-8 h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-teal-500 cursor-pointer mr-2'
          />
          <span className='cursor-pointer text-15 font-medium'>Show intra-period weights</span>
        </label>
      </div>
      <div className='table-container'>
        <table className='w-full border-collapse border-spacing-0'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id} className='border-y border-solid border-zinc-300'>
                  {headerGroup.headers.map((header, i) => {
                    const isWeightColumn = header.column.id.includes('date');
                    return (
                      <th
                        key={header.column.id}
                        style={{
                          width:
                            isWeightColumn && isIntraperiodVisible
                              ? '15%'
                              : isWeightColumn && !isIntraperiodVisible
                              ? '20%'
                              : 'auto',
                        }}
                        className={classNames(
                          header.column.getIsFiltered() || header.column.getIsSorted()
                            ? 'bg-teal-800 text-white'
                            : 'bg-[#e6f0f4]',
                          'text-left py-1.5 px-4 first:pl-6 border-r border-solid border-gray-200 text-sm font-bold whitespace-nowrap'
                        )}>
                        <button
                          onClick={header.column.getToggleSortingHandler()}
                          className='flex items-center'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() && (
                            <div className='ml-1'>
                              {{
                                asc: <ArrowLongUpIcon className='h-4 w-4' />,
                                desc: <ArrowLongDownIcon className='h-4 w-4' />,
                              }[header.column.getIsSorted() as string] ?? (
                                <ArrowsUpDownIcon className='h-4 w-4 text-teal-800/50 mt-0.5' />
                              )}
                            </div>
                          )}
                        </button>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const { hasChildren, isChild } = row.original;

              return (
                <tr
                  key={row.id}
                  className={classNames(
                    hasChildren ? 'bg-zinc-50 font-semibold' : '',
                    'border-y border-solid border-zinc-100'
                  )}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={classNames(
                          isChild ? 'first:pl-7 md:first:pl-9' : 'first:pl-4 md:first:pl-6',
                          'border-r border-solid border-zinc-100 text-15 whitespace-nowrap'
                        )}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <Tooltip id='intraperiod-tip' />
        <Tooltip id='official-tip' />
        <Tooltip id='ticker-tip' />
      </div>
    </div>
  );
}

export default memo(AssetsTable);
