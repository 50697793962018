import { memo } from 'react';

import { AntIcon, Button } from 'components';
import { ButtonProps } from 'components/Button/component';

interface ResetButtonProps extends ButtonProps {
  text?: string;
}

function ResetButton({ onClick, text = 'Reset', ...rest }: ResetButtonProps) {
  return (
    <Button onClick={onClick} {...rest}>
      <AntIcon name='reload1' size={14} color='inherit' />
      <span className='inline-block ml-1.5'>{text}</span>
    </Button>
  );
}

export default memo(ResetButton);
