import { CrudeReadingsResponse, fetchCrudeReadings } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useCrude() {
  const { data: crude, ...rest } = useQuery<CrudeReadingsResponse>({
    queryKey: ['crudeReadings'],
    queryFn: fetchCrudeReadings,
  });

  return {
    crude,
    ...rest,
  };
}
