import { coreModelComponentMap, CoreModelReading } from '@3fourteen/core';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { BlockedText } from 'components';

interface CoreModelTableProps {
  readings: CoreModelReading[];
  date: string;
  isFetching?: boolean;
  Legend?: React.ComponentType;
}

function CoreModelTable({ readings, date, isFetching = false, Legend }: CoreModelTableProps) {
  const isLoading = isFetching || !readings;

  const overallReading = readings?.find(
    ({ component }) => !!component?.toLowerCase().includes('overall')
  );

  const overallColor = overallReading?.color;

  const overallSignal = overallReading?.reading;

  const components = readings?.filter(
    ({ component }) => !component?.toLowerCase().includes('overall')
  );

  return (
    <div className='w-full'>
      <div className='p-4 md:p-6'>
        <div className='flex flex-col items-center justify-center'>
          {isLoading ? (
            <Skeleton height={40} width={200} />
          ) : (
            <div>
              <BlockedText
                text={overallSignal}
                backgroundColor={overallColor}
                size='large'
                testId='overall-label'
              />
              <p className='text-xl font-bold mt-2 text-center' data-testid='overall-value'>
                {overallReading?.value}
              </p>
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className='mx-auto max-w-[400px] mt-1'>
          <Skeleton count={4} className='mb-6 text-xl' />
        </div>
      ) : (
        <ul className='mx-auto max-w-[400px]'>
          {components.map(({ component, reading, value, color }) => {
            return (
              <li
                className='flex space-between items-center py-3 border-b border-solid border-zinc-200 last:border-none'
                key={component}>
                <span className='text-15 flex-1 mr-4'>{coreModelComponentMap[component]}</span>
                <div className='flex' style={{ flex: !value ? 'initial' : 1 }}>
                  <span
                    className='w-4 h-4 block rounded-full'
                    style={{ backgroundColor: color }}></span>
                  <span className='text-13 uppercase inline-block ml-1.5 font-semibold w-20'>
                    {reading}
                  </span>
                </div>
                {!!value && <span className='basis-[30px] text-right'>{value}</span>}
              </li>
            );
          })}
        </ul>
      )}
      {Legend && <Legend />}
    </div>
  );
}

export default memo(CoreModelTable);
