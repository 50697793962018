'use client';

import { Menu, Transition } from '@headlessui/react';
import { classNames } from 'helpers/classNames';
import { Fragment, memo } from 'react';

import { SimpleLineIcon } from 'components';
import { VideoChapter } from 'components/SeekBar/component';

interface VideoChaptersMenuProps {
  chapters: VideoChapter[];
  onClick: (startTime: number) => void;
  isFullscreen: boolean;
}

function VideoChaptersMenu({ chapters, onClick, isFullscreen }: VideoChaptersMenuProps) {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button>
        <SimpleLineIcon name='list' size={18} color='white' />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
        <Menu.Items
          className={classNames(
            isFullscreen ? 'bottom-7' : 'bottom-6',
            'absolute -right-3 z-10 mt-2 origin-top-right border border-solid rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          )}>
          <div className='py-1'>
            {chapters.map(({ id, text, startTime }) => {
              return (
                <Menu.Item key={id}>
                  <div
                    role='button'
                    onClick={() => onClick(startTime)}
                    className={classNames(
                      isFullscreen ? 'py-3 px-6' : 'py-2 px-3',
                      'cursor-pointer block border-b border-solid border-white/20 last:border-none text-white transition-colors duration-150 hover:text-teal-300'
                    )}>
                    <p
                      className={classNames(
                        isFullscreen ? 'text-base' : 'text-sm',
                        ' font-medium whitespace-nowrap'
                      )}>
                      {text}
                    </p>
                  </div>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default memo(VideoChaptersMenu);
