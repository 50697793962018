'use client';

import { getCloudfrontSignedUrl } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { useQuery } from '@tanstack/react-query';

export function useSignedCloudfrontUrl(key = '') {
  const {
    data: url,
    error,
    isLoading,
  } = useQuery({
    queryKey: [key],
    queryFn: async () => {
      return await getCloudfrontSignedUrl({ key });
    },
    enabled: !!key,
    throwOnError: (error, query) => {
      Bugsnag.notify(error, (event) => {
        event.context = 'useSignedCloudfrontUrl()';
        event.addMetadata('Key', {
          key,
          query,
        });
      });

      return false;
    },
  });

  return {
    isLoading,
    url,
    error,
  };
}
