import { memo } from 'react';

interface UpDownArrowIconProps {
  size?: number;
  color: string;
}

function UpDownArrowIcon({ size, color }: UpDownArrowIconProps) {
  return (
    <span
      style={{
        display: 'inline-block',
        width: size - 1,
        height: size - 1,
        overflow: 'hidden',
        position: 'relative',
        top: 1,
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 25'
        version='1.1'
        x='0px'
        y='0px'
      >
        <title>noun_96801_cc</title>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g transform='translate(-257.000000, -532.000000)' fill={color}>
            <g transform='translate(257.000000, 532.000000)'>
              <path d='M4.27273718,17.4185124 L4.27273718,0.685701231 C4.27273718,0.306998898 4.5761794,0 4.95637374,0 C5.33393579,0 5.6400103,0.313365032 5.6400103,0.685701231 L5.6400103,17.2665119 L8.51112688,14.3953953 C8.78088183,14.1256404 9.21412747,14.1215268 9.48296546,14.3903648 C9.74994215,14.6573415 9.74748652,15.0926519 9.47793498,15.3622034 L5.8471816,18.9929568 L5.85029842,18.9960736 L4.88349032,19.9628817 L4.8803735,19.9597649 L4.87236533,19.9677731 L3.90555723,19.000965 L3.9135654,18.9929568 L0.277920671,15.3573121 C0.00836912768,15.0877605 0.00591350011,14.6524502 0.272890184,14.3854735 C0.541728181,14.1166355 0.974973824,14.120749 1.24472877,14.390504 L4.27273718,17.4185124 Z' />
              <path
                d='M14.1955171,17.3735192 L14.1955171,0.717928167 C14.1955171,0.339225835 14.4989593,0.0322269366 14.8791537,0.0322269366 C15.2567157,0.0322269366 15.5627902,0.345591969 15.5627902,0.717928167 L15.5627902,17.3759589 L18.5111269,14.4276223 C18.7808818,14.1578673 19.2141275,14.1537538 19.4829655,14.4225918 C19.7499421,14.6895685 19.7474865,15.1248788 19.477935,15.3944304 L15.8471816,19.0251837 L15.8502984,19.0283005 L14.8834903,19.9951086 L14.8803735,19.9919918 L14.8723653,20 L13.9055572,19.0331919 L13.9135654,19.0251837 L10.2779207,15.389539 C10.0083691,15.1199875 10.0059135,14.6846771 10.2728902,14.4177004 C10.5417282,14.1488624 10.9749738,14.152976 11.2447288,14.4227309 L14.1955171,17.3735192 Z'
                transform='translate(14.877928, 10.016113) scale(1, -1) translate(-14.877928, -10.016113) '
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default memo(UpDownArrowIcon);
