import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AntIcon, LoadingDots, SimpleLineIcon } from 'components';
import Image from 'next/image';
import { accountLinks, navLinks } from 'services/links';

import logo from '../../public/images/logos/mark-white.svg';

function LoadingSkeleton() {
  const navItems = navLinks.filter(
    (link) => link.hasOwnProperty('isMobileNavItem') && link.isMobileNavItem
  );
  const isSmallDevice = useMediaQuery(768);
  const iconSize = isSmallDevice ? 16 : 20;

  return (
    <div className='flex h-screen'>
      <header className='hidden lg:flex bg-teal-800 xl:basis-[230px] 3xl:basis-[300px] shrink-0 pt-5 pb-4 md:pb-6 flex-col justify-between'>
        <div>
          <div className='h-8 w-8 xl:h-10 xl:w-10 2xl:h-12 2xl:w-12 mx-auto xl:ml-6 mb-8 block'>
            <Image alt='3Fourteen Mark' src={logo} />
          </div>
          <nav className='flex flex-col opacity-30 px-4 md:px-6'>
            {navLinks.map((_, i) => {
              return <Skeleton key={i} />;
            })}
          </nav>
        </div>
        <nav className='flex flex-col opacity-30 px-4 md:px-6'>
          {accountLinks.map((_, i) => (
            <Skeleton key={i} />
          ))}
        </nav>
      </header>

      <main className='w-full h-full flex justify-center items-center'>
        <LoadingDots color='#999' size='regular' />
      </main>
    </div>
  );
}

export default memo(LoadingSkeleton);
