'use client';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { FormikValues } from 'formik';
import { classNames } from 'helpers/classNames';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';

interface RebalanceConfigSelectProps {
  form: FormikValues;
}

const rebalOptions = [
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'quarterly',
    name: 'Quarterly',
  },
  {
    id: 'yearly',
    name: 'Yearly',
  },
];

function RebalanceConfigSelect({ form }: RebalanceConfigSelectProps) {
  const selected = rebalOptions.find(({ id }) => id === form.values.rebalance_config);

  const onChange = useCallback(
    (props) => {
      form.setFieldValue('rebalance_config', props.id);
    },
    [form]
  );

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className='block text-sm font-medium text-gray-700 uppercase'>
            Rebalance Term
          </Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button className='h-10 relative w-full cursor-default rounded-md border border-gray-300 border-solid bg-white py-2 pl-3 pr-10 text-left  focus:border-teal-800 focus:outline-none focus:ring-1 focus:ring-teal-800 sm:text-sm'>
              <span className='block truncate'>{selected.name}</span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {rebalOptions?.map((rebal) => (
                  <Listbox.Option
                    key={rebal.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-teal-800' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={rebal}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}>
                          {rebal.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-teal-800',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

RebalanceConfigSelect.displayName = 'RebalanceConfigSelect';

export default memo(RebalanceConfigSelect);
