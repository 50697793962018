import { useCustomResearch } from 'hooks/useCustomResearch';
import { memo } from 'react';

import { NavLink } from 'components';
import { routes } from 'services/routes';

function CustomResearchNavLink() {
  const { data } = useCustomResearch();

  if (!data) return null;

  return (
    <NavLink
      href={routes.customResearch}
      text='Custom Research'
      key={routes.customResearch}
      icon='settings'
      iconFamily='simple-line'
    />
  );
}

CustomResearchNavLink.displayName = 'CustomResearchNavLink';

export default memo(CustomResearchNavLink);
