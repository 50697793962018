'use client';

import {
  formatMarketCap,
  formatPercent,
  getIndustryCellColor,
  getSectorCellColor,
  TrendRiskStocksResponse,
} from '@3fourteen/core';
import { classNames } from 'helpers/classNames';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo, useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';

import { DownloadButton, TableHead, TableSortDisplay } from 'components';

import { columns } from './columns';

interface TrendRiskTableProps {
  data: TrendRiskStocksResponse['data'];
}

function TrendRiskTable({ data }: TrendRiskTableProps) {
  const { url: csvUrl } = useSignedCloudfrontUrl('stock_selection_tbrisk.csv');
  const [sortedColumn, setSortedColumn] = useState(undefined);
  const cols = useMemo(() => {
    return columns;
  }, []);

  const tableData = useMemo(() => {
    const rows = data.map(
      ({
        ticker,
        name,
        sector,
        industry,
        subindustry,
        market_cap,
        trend_breadth,
        industry_reading,
        sector_reading,
      }) => {
        return {
          ticker,
          name,
          sector,
          industry,
          subindustry,
          market_cap: formatMarketCap(market_cap),
          trend_breadth: formatPercent(trend_breadth),
          sector_reading,
          industry_reading,
        };
      }
    );

    return rows;
  }, [data]);

  const tableInstance = useTable(
    {
      columns: cols,
      data: tableData,
      initialState: {
        hiddenColumns: ['sector_reading', 'industry_reading'],
        sortBy: [
          {
            id: 'sector',
          },
        ],
      },
      disableSortRemove: true,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const headers = headerGroups[0].headers;

  const sorted = headers.find((column) => column.sortedIndex !== -1);

  useEffect(() => {
    setSortedColumn(sorted);
  }, [sorted]);

  const sortOrder = !sortedColumn ? undefined : sortedColumn.isSortedDesc ? 'DESC' : 'ASC';

  return (
    <div>
      <div className='flex w-full items-baseline mt-2'>
        <TableSortDisplay sortOption={sortedColumn?.Header} sortOrder={sortOrder} />
        {csvUrl && (
          <div className='mr-4 md:mr-6 pt-2 pb-3 md:p-0 flex flex-1 justify-end'>
            <DownloadButton
              text='Download CSV'
              href={csvUrl}
              download='stock_selection_tbrisk.csv'
            />
          </div>
        )}
      </div>
      <div className='border-b border-solid border-zinc-200 md:max-h-[calc(100vh_-_416px)] table-container'>
        <table {...getTableProps()} className='bg-white w-full border-collapse border-spacing-0'>
          <TableHead headerGroups={[...headerGroups]} sortable={true} />
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={row.getRowProps().key}
                  className='even:bg-zinc-50 border-y border-solid border-zinc-100'>
                  {row.cells.map((cell, i) => {
                    switch (i) {
                      case 2:
                        const sectorReading = row.values['sector_reading'];
                        const sectorCellColor = getSectorCellColor(sectorReading);
                        return (
                          <td
                            role='cell'
                            key={cell.getCellProps().key}
                            className={classNames(
                              sectorCellColor === 'red'
                                ? 'bg-terracotta-500 text-white'
                                : sectorCellColor === 'green'
                                ? 'bg-sage-500 text-white'
                                : '',
                              'first:pl-4 md:first:pl-6 border-r border-solid border-zinc-100 px-3 py-0 text-15 whitespace-nowrap'
                            )}>
                            {cell.render('Cell')}
                          </td>
                        );

                      case 3:
                        const industryReading = row.values['industry_reading'];
                        const industryCellColor = getIndustryCellColor(industryReading);

                        return (
                          <td
                            role='cell'
                            key={cell.getCellProps().key}
                            className={classNames(
                              industryCellColor === 'red'
                                ? 'bg-terracotta-500 text-white'
                                : industryCellColor === 'green'
                                ? 'bg-sage-500 text-white'
                                : '',
                              'first:pl-4 md:first:pl-6 border-r border-solid border-zinc-100 px-3 py-0 text-15 whitespace-nowrap'
                            )}>
                            {cell.render('Cell')}
                          </td>
                        );
                      default:
                        return (
                          <td
                            role='cell'
                            key={cell.getCellProps().key}
                            className='first:pl-4 md:first:pl-6 border-r border-solid border-zinc-100 px-3 py-0 text-15 whitespace-nowrap'>
                            {cell.render('Cell')}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default memo(TrendRiskTable);
