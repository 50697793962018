import { memo } from 'react';

import styles from './HtmlRenderer.module.scss';

interface HtmlRendererProps {
  content: string;
}

function HtmlRenderer({ content }: HtmlRendererProps) {
  return <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />;
}

HtmlRenderer.displayName = 'HtmlRenderer';

export default memo(HtmlRenderer);
