'use client';

import { isValidEmail } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { Field, Form, Formik } from 'formik';
import { memo } from 'react';

import { Button, Input } from 'components';
import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

interface LoginErrors {
  email?: string;
  password?: string;
}

const LoginForm = () => {
  const { login } = useAuth();
  const { showToast } = useToast();

  const initialValues = {
    email: '',
    password: '',
  };

  const validate = (values) => {
    const errors: LoginErrors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    } else if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  };

  const onSubmit = async (values) => {
    const { email, password } = values;

    try {
      await login(email, password);
    } catch (e) {
      const message = !!e.message.includes('expired')
        ? e.message
        : `${e.message}, please try again`;
      showToast({ message, type: 'error' });

      Bugsnag.notify(e, (event) => {
        event.context = 'onSubmit() in LoginForm';
        event.addMetadata('Form Info', {
          email,
        });
      });
    }
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit, isSubmitting, values }) => (
        <Form onSubmit={handleSubmit} className='flex flex-col items-center justify-center'>
          <Field
            as={Input}
            type='email'
            name='email'
            error={errors.email && touched.email && errors.email}
            placeholder='Email'
            autoComplete='email'
            theme='dark'
            data-testid='email-input'
          />
          <Field
            as={Input}
            name='password'
            type='password'
            error={errors.password && touched.password && errors.password}
            placeholder='Password'
            autoComplete='current-password'
            theme='dark'
            data-testid='password-input'
          />
          <Button
            className='border border-solid border-white uppercase w-56 tracking-wide'
            type='submit'
            disabled={isSubmitting || !values.email || !values.password}
            isLoading={isSubmitting}
            data-testid='submit-button'>
            Log In
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default memo(LoginForm);
