import { memo } from 'react';
import { useToggle } from 'react-use';
import useLocalStorage from 'react-use/lib/useLocalStorage';

import { Button } from 'components';
import Link from 'next/link';
import { CookiePreference } from 'services/cookies';
import { disableAnalytics, enableAnalytics } from 'services/mixpanel';

function CookiesConsent() {
  const [show, toggle] = useToggle(true);
  const [cookiePrefs, setCookiePrefs] = useLocalStorage('cookie-prefs');
  const userId = localStorage.getItem('userId');

  const onDeny = async () => {
    await disableAnalytics();
    setCookiePrefs(CookiePreference.minimum);
    toggle();
  };

  const onAccept = async () => {
    await enableAnalytics();
    setCookiePrefs(CookiePreference.normal);
    toggle();
  };

  if (!show) return null;

  return (
    <div className='fixed bottom-0 right-0 left-0 flex flex-col lg:flex-row bg-neutral-900/80 items-center justify-center text-white py-3 lg:py-6'>
      <p className='mb-0 mr-3'>
        We use cookies! If you accept, you agree to our{' '}
        <Link href='https://3fourteenresearch.com/cookies'>full cookie policy</Link>.
      </p>
      <div className='flex py-3 lg:py-0'>
        <Button
          className='btn-sm text-white border border-solid border-white disabled:opacity-60'
          onClick={onDeny}>
          Deny
        </Button>
        <Button
          className='btn-sm mx-3 text-gray-800 bg-white border border-solid border-white'
          onClick={onAccept}>
          Accept All
        </Button>
      </div>
      <Link href='/cookies'>Cookie Settings</Link>
    </div>
  );
}

CookiesConsent.displayName = 'CookiesConsent';

export default memo(CookiesConsent);
