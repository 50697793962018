import { fetchFullCycleReadings, FullCycleReadingsResponse } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useFullCycle() {
  const { data: fullCycle, ...rest } = useQuery<FullCycleReadingsResponse>({
    queryKey: ['fullCycleStocks'],
    queryFn: fetchFullCycleReadings,
  });

  return {
    fullCycle,
    ...rest,
  };
}
