import { classNames } from 'helpers/classNames';
import { InputHTMLAttributes, memo } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  theme?: 'light' | 'dark';
}

function Input({ error, theme = 'light', ...rest }: InputProps) {
  return (
    <div className='flex h-[76px] flex-col w-[300px]'>
      <input
        {...rest}
        className={classNames(
          !!error ? 'border-terracotta-500 border-[3px]' : 'border border-neutral-200',
          'block h-12 w-full pl-4 pr-3 rounded-full  border-solid  text-gray-800'
        )}
      />
      {!!error && (
        <p
          className={classNames(
            theme === 'dark' ? 'text-orange-600' : 'text-terracotta-500',
            'text-13 font-semibold mb-0 px-4'
          )}
          data-testid='error'>
          {error}
        </p>
      )}
    </div>
  );
}

export default memo(Input);
