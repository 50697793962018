import { memo } from 'react';

import glyphMap from './icon.json';

interface SimpleLineIconProps {
  name: string;
  size?: number;
  color?: string;
  className?: string;
}

function SimpleLineIcon({
  name,
  size = 16,
  color = '#373e41',
  className = '',
}: SimpleLineIconProps) {
  const glyph = glyphMap?.[name];

  return (
    <span
      className={className}
      style={{ fontSize: size, color, fontFamily: 'SimpleLineIcon' }}>
      {!!glyph ? String.fromCodePoint(glyph) : ''}
    </span>
  );
}

export default memo(SimpleLineIcon);
