import { memo } from 'react';

import { AntIcon } from 'components';

interface TableHeadProps {
  headerGroups: any;
  sortable?: boolean;
}

const iconSize = 10;

function TableHead({ headerGroups, sortable = false }: TableHeadProps) {
  return (
    <thead>
      {
        // Loop over the header rows
        headerGroups.map((headerGroup, index) => (
          // Apply the header row props
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={index}
            className='border-y border-solid border-zinc-300'>
            {headerGroup.headers.map((column) => {
              const headerProps = sortable
                ? column.getHeaderProps(column.getSortByToggleProps())
                : {};

              const header = column.render('Header');

              return (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...headerProps}
                  key={header}
                  data-testid={`${header}-th`}
                  className='py-1.5 px-3 first:pl-4 md:first:pl-6 sticky top-[-1px] uppercase text-sm font-bold text-left whitespace-nowrap bg-teal-100'>
                  <span>
                    <span>{header}</span>
                    {/* Add a sort direction indicator */}
                    {sortable && (
                      <span className='inline-block w-5 pl-1.5'>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <AntIcon name='caretdown' size={iconSize} />
                          ) : (
                            <AntIcon name='caretup' size={iconSize} />
                          )
                        ) : (
                          <span className='opacity-30'>
                            <AntIcon name='caretdown' size={iconSize} />
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        ))
      }
    </thead>
  );
}

export default memo(TableHead);
