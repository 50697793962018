export const downloadFile = (link, filename) => {
  const a = document.createElement("a");
  a.href = link;
  a.download = filename;
  a.target = "blank";
  const b = document.createEvent("MouseEvents");
  b.initEvent("click", false, true);
  a.dispatchEvent(b);
  return false;
};
