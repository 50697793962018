import { Chart } from '@3fourteen/core';
import { usePostBy } from 'hooks/usePostBy';
import { memo } from 'react';

import { PostActions } from 'components/PostActions';

interface SupportingChartLinksProps {
  url: string;
}

const SupportingChartLinks = ({ url }: SupportingChartLinksProps) => {
  const urlComponents = url.split('/');
  const chartUrl = [...urlComponents].slice(0, -1).join('/');

  const { post } = usePostBy({ uri: chartUrl });

  if (!post) return null;

  return <PostActions post={post as Chart} />;
};

export default memo(SupportingChartLinks);
