'use client';

import { getSignedUrl } from '@3fourteen/core';
import { usePortfolioById } from 'hooks/usePortfolioById';
import { memo, useCallback, useEffect, useState } from 'react';

import { PdfViewer } from 'components';
import { useParams } from 'next/navigation';

interface PortfolioBuilderProps {
  filename: string;
}

function PbOutputViewer({ filename }: PortfolioBuilderProps) {
  const [url, setUrl] = useState('');
  const [portfolioId, setPortfolioId] = useState<string | undefined>(undefined);
  const { portfolio } = usePortfolioById(portfolioId);
  const params = useParams();
  const id = decodeURIComponent(params.id as string);

  const signUrl = useCallback(async (key) => {
    const signedUrl = await getSignedUrl({
      bucket: '3fourteen-portfolio-builder',
      key,
    });

    setUrl(signedUrl);
  }, []);

  useEffect(() => {
    if (id) {
      setPortfolioId(id as string);
    }
  }, [id]);

  useEffect(() => {
    if (portfolio) {
      signUrl(`${portfolio.user_id}/${portfolio.id}/${filename}.pdf`);
    }
  }, [portfolio, signUrl, filename]);

  return (
    <>
      {url && (
        <PdfViewer
          url={url}
          filename={`${filename}.pdf`}
          containerClass='!h-[calc(100vh_-_120px)]'
        />
      )}
    </>
  );
}

PbOutputViewer.displayName = 'PbOutputViewer';

export default memo(PbOutputViewer);
