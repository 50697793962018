import { ButtonHTMLAttributes, memo } from 'react';

import { AntIcon } from 'components';

import styles from './DropdwnCaret.module.scss';

interface DropdownCaretProps {
  onClick: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  isOpen: boolean;
  color?: string;
  size?: number;
}

function DropdownCaret({
  onClick,
  isOpen,
  color = '#254653',
  size = 16,
}: DropdownCaretProps) {
  return (
    <button onClick={onClick}>
      <AntIcon
        name={isOpen ? 'caretright' : 'caretdown'}
        color={color}
        size={size}
      />
    </button>
  );
}

export default memo(DropdownCaret);
