import { motion, Variants } from 'framer-motion';
import { memo } from 'react';

import { FontistoIcon } from '../';

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants: Variants = {
  start: {
    y: '-30%',
  },
  end: {
    y: '0%',
    transition: {
      duration: 0.5,
      repeat: Infinity,
      repeatType: 'reverse',
      ease: 'easeInOut',
    },
  },
};

interface LoadingDotsProps {
  color?: string;
  size?: 'small' | 'regular';
}

function LoadingDots({ color, size = 'small' }: LoadingDotsProps) {
  const isSmall = size === 'small';
  const dotColor = color || 'inherit';
  const dotSize = isSmall ? 4 : 12;

  return (
    <motion.div
      className={`flex justify-center items-center `}
      variants={loadingContainerVariants}
      initial='start'
      animate='end'>
      <motion.span className='mx-0.5' variants={loadingCircleVariants}>
        <FontistoIcon name='ellipse' color={dotColor} size={dotSize} />
      </motion.span>
      <motion.span className='mx-0.5' variants={loadingCircleVariants}>
        <FontistoIcon name='ellipse' color={dotColor} size={dotSize} />
      </motion.span>
      <motion.span className='mx-0.5' variants={loadingCircleVariants}>
        <FontistoIcon name='ellipse' color={dotColor} size={dotSize} />
      </motion.span>
    </motion.div>
  );
}

export default memo(LoadingDots);
