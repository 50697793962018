import { fetchMyPortfolios } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import dayjs from 'dayjs';

export async function checkIfPortfolioGenerated(modelName: string) {
  try {
    const portfolios = await fetchMyPortfolios();

    const existingPortfolio = portfolios?.find(
      (port) =>
        port.config?.model_name?.toLowerCase() === modelName.toLowerCase()
    );

    const lastModified = dayjs(existingPortfolio?.last_modified);
    const now = dayjs(Date.now());
    const timeDiff = now.diff(lastModified);

    if (existingPortfolio && timeDiff <= 65000) {
      return existingPortfolio;
    }

    return false;
  } catch (error) {
    Bugsnag.notify(error, (event) => {
      event.context = 'fetchPortfolios() in checkIfPortfolioGenerated()';
      event.addMetadata('Meta', {
        userId: localStorage.getItem('databaseId'),
        testing: 'true',
        modelName,
      });
    });

    return false;
  }
}
