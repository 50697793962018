import { fetchRaaReadings, RaaReadingsResponse, ReadingsFilename } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useRaa(
  key: ReadingsFilename.RAA_V2_20 | ReadingsFilename.RAA_V2_10 | ReadingsFilename.raa
) {
  const { data: raa, ...rest } = useQuery<RaaReadingsResponse>({
    queryKey: [key],
    queryFn: () => fetchRaaReadings(key),
  });

  return {
    raa,
    ...rest,
  };
}
