import { fetchPortfolio, FetchPortfolioResponse, MyPortfolio } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function usePortfolioById(id: MyPortfolio['id'] = '') {
  const { data: portfolio, ...rest } = useQuery<FetchPortfolioResponse>({
    queryKey: ['pb-portfolio', id],
    queryFn: () => fetchPortfolio(id),
    enabled: Boolean(id),
  });

  return {
    portfolio,
    ...rest,
  };
}
