import { useMediaQuery } from 'hooks/useMediaQuery';
import { CSSProperties, memo } from 'react';

import { ResetButton, SortIcon } from 'components';

import styles from './TableSortDisplay.module.scss';

interface TableSortDisplayProps {
  sortOption: string;
  sortOrder: 'ASC' | 'DESC';
  onResetSort?: () => void;
  disableReset?: boolean;
  containerStyles?: CSSProperties;
}

const sortOrderMap = {
  ASC: 'Asc',
  DESC: 'Desc',
};

function TableSortDisplay({
  sortOption,
  sortOrder,
  onResetSort,
  disableReset = false,
  containerStyles = {},
}: TableSortDisplayProps) {
  const isSmallScreen = useMediaQuery(768);
  const showOrderText = (!isSmallScreen && !!sortOrder) || !sortOrder;

  if (isSmallScreen) return null;

  return (
    <div
      className='flex items-center justify-between my-4 mx-4 md:mx-6 flex-1'
      style={containerStyles}
    >
      <div className='flex items-center mr-4'>
        <span className='text-sm font-medium mr-2 inline-block'>Sort:</span>
        <span
          className='text-13 inline-block w-[110px] uppercase'
          data-testid='table-sort'
        >
          {sortOption || '--'}
        </span>
        <span className='text-sm font-medium mr-2 inline-block'>Order:</span>
        <div className='flex items-center'>
          {showOrderText && (
            <span
              className='text-13 inline-block mr-1 uppercase'
              data-testid='table-order'
            >
              {sortOrderMap[sortOrder] || '--'}
            </span>
          )}
          {!!sortOrder && (
            <div
              className={`leading-[1px] ${
                sortOrder === 'ASC' ? 'rotate-180 scale-x-[-1]' : ''
              }`}
            >
              <SortIcon size={14} />
            </div>
          )}
        </div>
      </div>
      {!!onResetSort && (
        <ResetButton
          className='btn-secondary btn-xs'
          text='Reset Sort'
          onClick={onResetSort}
          disabled={!sortOption || disableReset}
          data-testid='table-sort-reset-btn'
        />
      )}
    </div>
  );
}

export default memo(TableSortDisplay);
