import { memo, ReactNode } from 'react';

export interface CheckboxProps {
  children: ReactNode;
  id: string;
  label: string;
  toggleCheck: () => void;
  isChecked?: boolean;
  disabled?: boolean;
  testId?: string | number;
}

function Checkbox({ children, id, toggleCheck, isChecked, testId, disabled }: CheckboxProps) {
  return (
    <>
      <input
        type='checkbox'
        className='h-4 w-4 bg-transparent rounded-sm border-teal-800 text-teal-800 focus:ring-teal-500 cursor-pointer'
        id={id}
        data-testid={testId || `${id}-checkbox`}
        checked={isChecked}
        onChange={toggleCheck}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className='flex items-center my-1 w-full lg:w-auto'
        style={{
          opacity: disabled ? 0.7 : 1,
          fontWeight: isChecked ? 600 : 400,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}>
        {children}
      </label>
    </>
  );
}

export default memo(Checkbox);
