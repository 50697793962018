import { Chart, Commentary, CustomPost, fetchPostBy, GetPostByVariables } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function usePostBy(variables: GetPostByVariables, opts = {}) {
  const { data: post, ...rest } = useQuery<Commentary | Chart | CustomPost>({
    queryKey: ['post', variables],
    queryFn: async () => {
      return await fetchPostBy(variables);
    },
    ...opts,
  });

  return {
    post,
    ...rest,
  };
}
