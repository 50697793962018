'use client';

import { ArrowLeftIcon, ArrowRightIcon, ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { memo, useRef } from 'react';
import ReactDatepicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import { DatePickerMonthSelect } from 'components/DatePickerMonthSelect';
import { months } from 'components/DatePickerMonthSelect/component';
import { DatePickerYearSelect } from 'components/DatePickerYearSelect';

function getRenderValueAsDate(val) {
  if (val) {
    return new Date(
      new Date(val).toLocaleString('en-US', {
        timeZone: 'UTC',
      })
    );
  }
  return null;
}

const defaultStartDate = '1900-01-01';

const ChartBuilderStartDate = ({ control, maxDate }: any) => {
  const datepickerRef = useRef(null);

  return (
    <Controller
      control={control}
      name='start_date'
      render={({ field }) => {
        return (
          <ReactDatepicker
            ref={datepickerRef}
            wrapperClassName='chart-builder-datepicker'
            id='start_date'
            selected={field.value}
            selectsStart
            onChange={field.onChange}
            maxDate={maxDate || new Date()}
            placeholderText='Select start date'
            renderCustomHeader={(props) => {
              const {
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              } = props;
              return (
                <div className='flex justify-between mx-4 mb-4'>
                  {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <ArrowLeftIcon className='h-5 2-5 text-white font-bold' />
                  </button> */}
                  <div className='flex mx-3'>
                    <div className='mr-1'>
                      <DatePickerMonthSelect
                        value={months[new Date(date).getMonth()]}
                        onChange={(value) => {
                          changeMonth(months.indexOf(value));
                        }}
                      />
                    </div>

                    <div className='mx-1'>
                      <DatePickerYearSelect
                        defaultStartDate={defaultStartDate}
                        value={new Date(date).getFullYear()}
                        onChange={(value) => {
                          changeYear(value);
                        }}
                      />
                    </div>

                    {/* <button
                      title='Reset Date'
                      onClick={() => {
                        // datepickerRef.current.setPreSelection(
                        //   getRenderValueAsDate(defaultStartDate)
                        // );
                        field.onChange(getRenderValueAsDate(defaultStartDate));
                      }}
                      className='bg-white/20 relative ml-1 w-full h-full cursor-pointer rounded-md border border-gray-300  text-white py-2 px-3 text-left shadow-sm focus:border-gray-50 focus:ring-1 focus:ring-gray-50'>
                      <ArrowUturnLeftIcon className='w-4 h-4 text-white' />
                    </button> */}
                  </div>
                  {/* 
                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <ArrowRightIcon className='h-5 2-5 text-white font-bold' />
                  </button> */}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
};

ChartBuilderStartDate.displayName = 'ChartBuilderStartDate';

export default memo(ChartBuilderStartDate);
