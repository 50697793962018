import { fetchCommentary, GetCommentaryResponse } from '@3fourteen/core';
import { useInfiniteQuery } from '@tanstack/react-query';

export function useCommentary(queryKey, options?) {
  const { data, ...rest } = useInfiniteQuery<GetCommentaryResponse>({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const queryVariables = queryKey[1];

      const variables = {
        ...queryVariables,
        startCursor: pageParam,
      };

      const fetchedData = await fetchCommentary(variables);

      return fetchedData;
    },
    getNextPageParam: (lastPage, pages) => lastPage.pageInfo.endCursor,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });

  return {
    commentary: data?.pages.flatMap((d) => d.data),
    pageInfo: data?.pages[data.pages.length - 1].pageInfo,
    ...rest,
  };
}
