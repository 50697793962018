import { Config, Stage } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

import { IS_PRODUCTION } from 'services/environment';

export function useCustomResearch(filename = 'index.html') {
  const stage = IS_PRODUCTION ? Stage.prod : Stage.dev;

  const { data, ...rest } = useQuery({
    queryKey: ['custom-research', filename],
    queryFn: async () => {
      try {
        const res = await fetch(`${Config[stage].apiUrl}/custom-research/${filename}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'text/plain',
          },
          credentials: 'include',
        });

        if (!res.ok) {
          throw new Error('Failed to fetch custom research');
        }

        const html = await res.text();

        return html;
      } catch (error) {
        return null;
      }
    },
  });

  return {
    data,
    ...rest,
  };
}
