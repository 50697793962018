import { getCurrentDrawdownRisk } from '@3fourteen/core';
import { useDrawdown } from 'hooks/useDrawdown';
import { memo, useMemo } from 'react';

import { CoreModelCard } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface DrawdownCardProps {
  cardId: CardProps['cardId'];
}

function DrawdownCard({ cardId }: DrawdownCardProps) {
  const { drawdown } = useDrawdown();

  const coreReadings = useMemo(() => {
    if (drawdown) {
      const overallReading = drawdown.components.find(({ component }) => component === 'overall');
      const { color, label } = getCurrentDrawdownRisk(drawdown.currentValue);

      return [
        {
          color,
          component: overallReading?.component,
          reading: label,
        },
      ];
    }
  }, [drawdown]);

  return (
    <CoreModelCard
      cardId={cardId}
      readings={coreReadings}
      date={drawdown?.date}
      title='S&P Risk'
      href={routes.models.spRisk}
    />
  );
}

export default memo(DrawdownCard);
