'use client';

import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef, memo, RefObject, useRef } from 'react';
import { useToggle } from 'react-use';
import { HTMLMediaControls, HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook';

import { SimpleLineIcon } from 'components';

interface VolumeControlsProps {
  isFullscreen: boolean;
  volume: HTMLMediaState['volume'];
  muted: HTMLMediaState['muted'];
  mute: HTMLMediaControls['mute'];
  unmute: HTMLMediaControls['unmute'];
}

const variants = {
  initial: {
    width: 0,
  },
  animate: {
    width: 80,
  },
  exit: {
    opacity: 0,
    width: 0,
  },
};

const VolumeControls = forwardRef(
  ({ volume, muted, mute, unmute }: VolumeControlsProps, ref: RefObject<HTMLVideoElement>) => {
    const volumeRef = useRef(null);
    const [isHovering, toggleHovering] = useToggle(false);
    const iconSize = 18;

    const onMouseLeave = () => toggleHovering(false);
    const onMouseEnter = () => toggleHovering(true);

    return (
      <div
        className='flex'
        ref={volumeRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid='volume-controls'>
        <AnimatePresence>
          {isHovering && (
            <motion.div
              className='px-2 flex items-center justify-center'
              initial='initial'
              animate='animate'
              exit='exit'
              variants={variants}
              transition={{ type: 'spring', bounce: 0.1, duration: 0.25 }}>
              <input
                type='range'
                id='volume'
                key='volume'
                min={0}
                max={1}
                step={0.02}
                data-testid='volume-slider'
                value={volume}
                onChange={(event) => {
                  ref.current.volume = event.target.valueAsNumber;
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
        {!muted ? (
          <button onClick={mute} className='font-semibold' data-testid='mute-btn'>
            <SimpleLineIcon name='volume-2' color='white' size={iconSize} />
          </button>
        ) : (
          <button onClick={unmute} className='font-semibold' data-testid='unmute-btn'>
            <SimpleLineIcon name='volume-off' color='white' size={iconSize} />
          </button>
        )}
      </div>
    );
  }
);

VolumeControls.displayName = 'VolumeControls';

export default memo(VolumeControls);
