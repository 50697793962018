'use client';

import { changePassword, isValidPassword } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { memo, useCallback, useEffect, useState } from 'react';

import { Button, Input } from 'components';
import { useToast } from 'contexts/toast';

function ChangePasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmed, setConfirmed] = useState('');
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isMatching, setIsMatching] = useState<boolean | null>(null);
  const isConfirmed = password === confirmed && isValid;
  const { showToast } = useToast();

  const validatePassword = useCallback(() => {
    const isValidPw = isValidPassword(password);

    if (!isValidPw && (isValid || isValid === null)) {
      setIsValid(false);
    } else if (isValidPw && !isValid) {
      setIsValid(true);
    }
  }, [password, isValid]);

  const matchPasswords = useCallback(() => {
    if (password !== confirmed && isMatching) {
      setIsMatching(false);
    } else if (password === confirmed && !isMatching) {
      setIsMatching(true);
    }
  }, [password, confirmed, isMatching]);

  useEffect(() => {
    if (isValid === false) {
      validatePassword();
    }
  }, [password, isValid, validatePassword]);

  useEffect(() => {
    if (isMatching === false) {
      matchPasswords();
    }
  }, [confirmed, isMatching, matchPasswords]);

  const clearForm = () => {
    setPassword('');
    setConfirmed('');
  };

  const onChange = (e) => {
    setPassword(e.target.value);
  };

  const onBlur = (e) => {
    validatePassword();
  };

  const onChangeConfirmed = (e) => {
    setConfirmed(e.target.value);
  };

  const onBlurConfirmed = () => {
    matchPasswords();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    validatePassword();
    matchPasswords();

    const userId = localStorage.getItem('userId');

    try {
      await changePassword({
        userId,
        password,
      });

      showToast({
        message: 'Your password has been changed successfully.',
        type: 'success',
      });

      clearForm();
    } catch (error) {
      showToast({ message: error.message, type: 'error' });

      Bugsnag.notify(error, (event) => {
        event.context = 'onSubmit() in ChangePasswordForm';
        event.addMetadata("Add'l Info", {
          userId,
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form action='' className='flex flex-col items-center justify-center'>
      <Input
        placeholder='New password'
        onChange={onChange}
        type='password'
        value={password}
        onBlur={onBlur}
        error={isValid === false ? 'Invalid password format' : undefined}
      />
      <Input
        placeholder='Confirm new password'
        onChange={onChangeConfirmed}
        type='password'
        value={confirmed}
        onBlur={onBlurConfirmed}
        error={isMatching === false ? "Passwords don't match" : undefined}
      />

      <div className='py-4 md:py-6'>
        <Button
          className='btn-secondary w-52'
          disabled={!password || !isConfirmed}
          onClick={onSubmit}
          isLoading={isLoading}>
          Change Password
        </Button>
      </div>
    </form>
  );
}

export default memo(ChangePasswordForm);
