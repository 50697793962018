'use client';

import { Field, useFormikContext } from 'formik';
import React, { memo, useEffect } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

interface SearchInputProps {
  placeholder?: string;
  autoFocus?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

function SearchInput({ placeholder, autoFocus, onBlur, onFocus }: SearchInputProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const searchQuery = searchParams?.get('search');

  const formik = useFormikContext();

  useEffect(() => {
    // For populating initial value of search bar in the event a search query exists
    formik.setFieldValue('search', searchQuery || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setFieldValue('search', searchQuery || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Field
      as='input'
      type='search'
      name='search'
      autoFocus={autoFocus}
      data-testid='search-input'
      className='px-5 py-4 border-solid rounded-3xl w-full font-sans h-11 bg-white'
      onFocus={onFocus}
      onBlur={onBlur}
      style={{
        // @ts-ignore
        borderColor: !!formik.values.search ? '#666' : '#e0e3e5',
        // @ts-ignore
        borderWidth: !!formik.values.search ? 2 : 1,
      }}
      placeholder={placeholder || 'Search commentary, charts, models...'}
    />
  );
}

export default memo(SearchInput);
