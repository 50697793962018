import { DocumentIcon } from '@heroicons/react/24/outline';
import { memo } from 'react';

function CsvIcon() {
  return (
    <div className='flex items-center justify-center relative'>
      <DocumentIcon className='size-5 lg:size-6 text-[#373e41]' />
      <span className='text-[8px] text-[#373e41] font-black absolute mt-0.5'>CSV</span>
    </div>
  );
}

CsvIcon.displayName = 'CsvIcon';

export default memo(CsvIcon);
