import {
  CategorySlug,
  chartBookColor,
  chartColor,
  modelColor,
  modelUpdateColor,
  positionUpdateColor,
  publicationColor,
  retroColor,
} from '@3fourteen/core';
import React, { memo, useMemo } from 'react';

interface PostTagProps {
  category: CategorySlug;
  scale?: 'small' | 'medium';
}

const PostTag = ({ category, scale = 'small' }: PostTagProps) => {
  const isSmall = scale === 'small';
  const isMedium = scale === 'medium';

  const { text, color } = useMemo(() => {
    switch (category) {
      case 'model-updates': {
        return {
          text: 'Model Update',
          color: modelUpdateColor,
        };
      }
      case 'publications': {
        return {
          text: 'Publication',
          color: publicationColor,
        };
      }
      case 'chart-books': {
        return {
          text: 'Chart Book',
          color: chartBookColor,
        };
      }
      case 'charts': {
        return {
          text: 'Chart',
          color: chartColor,
        };
      }
      case 'models': {
        return {
          text: 'Model',
          color: modelColor,
        };
      }
      case 'position-updates': {
        return {
          text: 'Position Update',
          color: positionUpdateColor,
        };
      }
      case 'retrospectives': {
        return {
          text: 'Retro',
          color: retroColor,
        };
      }

      default: {
        return {
          text: '',
          color: '',
        };
      }
    }
  }, [category]);

  if (!text) return null;

  return (
    <div
      className={`flex items-center rounded-xs ${
        isSmall ? 'py-0.5 px-2' : ''
      } ${isMedium ? 'py-1 px-4' : ''}`}
      style={{
        backgroundColor: color,
      }}
      data-testid={`${category}-tag`}
    >
      <span
        className={`font-medium uppercase text-white whitespace-nowrap overflow-hidden text-ellipsis ${
          isSmall ? 'text-11 lg:text-xs' : ''
        } ${isMedium ? 'text-xs lg:text-sm' : ''}`}
      >
        {text}
      </span>
    </div>
  );
};

export default memo(PostTag);
