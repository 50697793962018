import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { memo } from 'react';

import Link from 'next/link';

interface WhitepaperLinkProps {
  url: string;
  textClasses?: string;
}

const WhitepaperLink = ({ url, textClasses = '' }: WhitepaperLinkProps) => {
  return (
    <Link href={url} className='font-semibold text-sky-700 flex items-center'>
      <span className={`text-15 inline-block mr-1 ${textClasses}`}>Introductory Report</span>
      <ArrowTopRightOnSquareIcon className='w-4 h-4' />
    </Link>
  );
};

export default memo(WhitepaperLink);
