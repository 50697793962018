import { Config, Stage } from '@3fourteen/core';

import { base64toBlob } from 'services/base64toBlob';
import { IS_PRODUCTION } from 'services/environment';

export async function fetchDynamicChart(url: string) {
  const stage = IS_PRODUCTION ? Stage.prod : Stage.dev;

  if (!url) {
    throw new Error('No url provided');
  }

  const res = await fetch(`${Config[stage].apiUrl}${url}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    const body = await res.json();

    throw new Error(body.error.message);
  }

  const base64 = await res.json();

  const blob = base64toBlob(base64);
  return URL.createObjectURL(blob);
}
