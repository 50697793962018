import { ButtonHTMLAttributes, memo } from 'react';

import { LoadingDots } from 'components';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

function Button({
  children,
  className,
  disabled,
  isLoading = false,
  ...rest
}: ButtonProps) {
  return (
    <button
      disabled={disabled || isLoading}
      className={`btn ${className} active:translate-y-px`}
      type='button'
      {...rest}>
      {isLoading ? <LoadingDots /> : children}
    </button>
  );
}

export default memo(Button);
