export function getAssetClassBreakdown(assets) {
  const fixedIncome = [...assets]
    .filter(({ asset_class }) => asset_class.toLowerCase() === 'fixed income')
    .reduce((n, { bench_weight }) => n + bench_weight, 0);

  const equities = [...assets]
    .filter(({ asset_class }) => asset_class.toLowerCase() === 'equities')
    .reduce((n, { bench_weight }) => n + bench_weight, 0);

  const alternatives = [...assets]
    .filter(({ asset_class }) => asset_class.toLowerCase() === 'alternatives')
    .reduce((n, { bench_weight }) => n + bench_weight, 0);

  const benchmark = [...assets].reduce(
    (n, { bench_weight }) => n + bench_weight,
    0
  );

  return {
    benchmark,
    fixedIncome,
    equities,
    alternatives,
  };
}
