import { CoreModelCode, coreModelColorMap, coreModelSignalMap } from '@3fourteen/core';
import { useGold } from 'hooks/useGold';
import { memo, useMemo } from 'react';

import { CoreModelCard } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface GoldCardProps {
  cardId: CardProps['cardId'];
}

function GoldCard({ cardId }: GoldCardProps) {
  const { gold } = useGold();

  const coreReadings = useMemo(() => {
    return gold?.readings.map(({ component, signal }) => {
      const color = coreModelColorMap[signal];
      return {
        color,
        component,
        reading: coreModelSignalMap[CoreModelCode.gold][signal],
      };
    });
  }, [gold]);

  return (
    <CoreModelCard
      cardId={cardId}
      readings={coreReadings}
      title='Gold'
      date={gold?.date}
      href={routes.models.gold}
    />
  );
}

export default memo(GoldCard);
