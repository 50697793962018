import { memo } from 'react';

import { AntIcon } from 'components';

interface CaretIconProps {
  direction?: 'up' | 'down';
}

const iconMap = {
  up: {
    icon: 'caretup',
    color: '#6a8d73',
  },
  down: {
    icon: 'caretdown',
    color: '#b45048',
  },
};

function CaretIcon({ direction = null }: CaretIconProps) {
  const directionClasses = direction === 'up' ? 'relative top-0.5' : '';

  return !!direction ? (
    <span
      className={`flex w-5 justify-center ${directionClasses}`}
      data-testid='caret-icon'
    >
      <AntIcon
        name={iconMap[direction].icon}
        color={iconMap[direction].color}
      />
    </span>
  ) : (
    <span className='flex w-5 justify-center'>--</span>
  );
}

export default memo(CaretIcon);
