import { memo, useState } from 'react';
import { useToggle } from 'react-use';

import { ActionSheet, AntIcon, Button, FAB, SortIcon } from 'components';

interface FiltersActionSheetProps {
  numOfFilters?: number;
  Filters: React.ReactNode;
  SortOptions: React.ReactNode;
  ResetButton: React.ReactNode;
}

function FiltersActionSheet({
  numOfFilters,
  Filters,
  SortOptions,
  ResetButton,
}: FiltersActionSheetProps) {
  const [isOpen, toggle] = useToggle(false);

  return (
    <div className='md:hidden'>
      <FAB
        shape='circle'
        icon='filter'
        onClick={toggle}
        superscript={!!numOfFilters && numOfFilters}
      />
      <ActionSheet visible={isOpen} close={toggle}>
        <div
          className='relative overflow-y-auto pb-40 px-4 md:px-6'
          style={{
            height: '-webkit-fill-available',
          }}>
          <div className='mb-10'>
            <div className='flex items-end pb-2 mb-3 border-b border-solid border-black/10'>
              <AntIcon name='filter' />
              <p className='uppercase ml-1 font-medium mb-0 text-15'>Filter By</p>
            </div>
            <div className='pl-5'>{Filters}</div>
          </div>
          <div>
            <div className='flex items-end pb-2 mb-3 border-b border-solid border-black/10'>
              <SortIcon />
              <p className='uppercase ml-1 font-medium mb-0 text-15'>Sort By</p>
            </div>
            <div className='pl-5'>{SortOptions}</div>
          </div>
        </div>
        <div className='fixed right-0 left-0 bottom-0 pb-safe'>
          <div className='flex items-center justify-center py-4'>
            <div className='mx-3'>{ResetButton}</div>
            <div className='mx-3'>
              <Button onClick={toggle} className='btn-primary w-full px-7'>
                <span className='mr-1'>
                  <AntIcon name='close' color='white' />
                </span>
                <span className='tracking-wide'>Close</span>
              </Button>
            </div>
          </div>
        </div>
      </ActionSheet>
    </div>
  );
}

FiltersActionSheet.displayName = 'FiltersActionSheet';

export default memo(FiltersActionSheet);
