export enum CookiePreference {
  minimum = 'minimum',
  normal = 'normal',
}

export const cookiePrefOpts = [
  {
    id: CookiePreference.minimum,
    title: 'Minimum',
    excerpt: 'Functional',
    description: 'These cookies are used for logging into the site.',
  },
  {
    id: CookiePreference.normal,
    title: 'Regular',
    excerpt: 'Functional + Bug Reporting & Analytics',
    description: 'These cookies report bugs and help us improve the service.',
  },
];
