import { fetchGoldReadings, GoldReadingsResponse } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useGold() {
  const { data: gold, ...rest } = useQuery<GoldReadingsResponse>({
    queryKey: ['goldReadings'],
    queryFn: fetchGoldReadings,
  });

  return {
    gold,
    ...rest,
  };
}
