'use client';

import { SectorRotationIndex } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSectorRotation } from 'hooks/useSectorRotation';
import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, CardRowSkeleton, CaretIcon, RebalDate } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface SectorRotationCardProps {
  cardId: CardProps['cardId'];
}

function SectorRotationCard({ cardId }: SectorRotationCardProps) {
  const { sectorRotation, isFetching } = useSectorRotation();
  const [sectors, setSectors] = useState(null);

  useEffect(() => {
    if (!!sectorRotation) {
      const sorted = [...sectorRotation?.data].sort(
        (a: SectorRotationIndex, b: SectorRotationIndex) => (a.active < b.active ? 1 : -1)
      );
      const overweight = [...sorted].slice(0, 2);
      const underweight = [...sorted].slice(-2);

      setSectors([...overweight, ...underweight]);
    }
  }, [sectorRotation]);

  return (
    <Card
      cardId={cardId}
      heading='Sector Rotation'
      href={routes.models.sector}
      leftEl={<RebalDate date={sectorRotation?.date} />}>
      <div className='flex flex-col h-full justify-between'>
        {!sectors || isFetching ? (
          <div className='p-4 md:p-6 w-full'>
            <div className='hidden lg:block max-w-xs mx-auto'>
              <Skeleton count={4} style={{ marginTop: 13, marginBottom: 13 }} />
            </div>

            <div className='lg:hidden'>
              <CardRowSkeleton numberOfItems={4} />
            </div>
          </div>
        ) : (
          <div className='lg:max-w-xs mx-auto w-full lg:px-6 py-4 lg:pb-0'>
            <ul className='flex justify-between lg:flex-col'>
              {sectors.map(({ index: sector, active }, index) => {
                const iconDirection = active > 0 ? 'up' : active < 0 ? 'down' : undefined;

                return (
                  <li
                    key={`${sector}-${index}`}
                    className='flex-1 flex flex-col lg:flex-row lg:justify-between items-center lg:py-2  border-r lg:border-b lg:border-r-0 border-solid border-zinc-200 last:border-none'>
                    <span className='mb-1 uppercase lg:capitalize inline-block px-1 text-xs md:text-sm whitespace-nowrap text-ellipsis overflow-hidden max-w-[calc((100vw_-_52px)_/_4)] md:max-w-auto'>
                      {sector}
                    </span>
                    <div className='flex items-center font-semibold'>
                      <span>{`${active}%`}</span>
                      <CaretIcon direction={iconDirection} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className='px-4 pb-1.5 pt-1 lg:pt-2 lg:pb-2.5 flex lg:justify-between border-t border-solid border-gray-200 lg:border-none'>
          <div className='flex items-center mr-4 lg:mr-0'>
            <CaretIcon direction='up' />
            <span className='text-xs inline-block'>Overweight</span>
          </div>
          <div className='flex items-center'>
            <CaretIcon direction='down' />
            <span className='text-xs inline-block'>Underweight</span>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default memo(SectorRotationCard);
