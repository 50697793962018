export const columns = [
  {
    Header: "Ticker",
    accessor: "ticker",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Sector",
    accessor: "sector",
  },
  {
    Header: "Industry",
    accessor: "industry",
  },
  {
    Header: "Subindustry",
    accessor: "subindustry",
  },
  {
    Header: "Market Cap",
    accessor: "market_cap",
  },
  {
    Header: "Trend Breadth",
    accessor: "trend_breadth",
  },
  {
    Header: "Sector Reading",
    accessor: "sector_reading",
  },
  {
    Header: "Industry Reading",
    accessor: "industry_reading",
  },
];
