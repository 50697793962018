'use client';

import { CardName } from '@3fourteen/core';
import { classNames } from 'helpers/classNames';
import { memo, ReactNode, useCallback, useMemo } from 'react';

import Link, { LinkProps } from 'next/link';
import { trackPressedCard } from 'services/mixpanel';

export interface CardProps {
  cardId: CardName;
  children: ReactNode;
  heading?: string;
  leftEl?: ReactNode;
  href?: LinkProps['href'];
  className?: string;
  onClick?: () => void;
}

function Card({ cardId, children, heading, leftEl, href, className, onClick }: CardProps) {
  const containerClasses =
    'rounded flex flex-col flex-1 bg-white w-full h-full shadow-md border border-solid border-zinc-200 opacity-100 hover:opacity-90';

  const onClickCard = useCallback(() => {
    if (Boolean(onClick)) {
      onClick();
    }
    trackPressedCard(cardId);
  }, [cardId, onClick]);

  const el = useMemo(() => {
    return (
      <div className='flex-1 flex flex-col h-full' onClick={onClickCard}>
        {heading && (
          <div className='flex justify-between items-center relative pt-3 md:pt-4 pr-5 pl-4 md:px-5 pb-2 md:pb-3 border-b border-solid border-black/10'>
            <p
              className='uppercase font-bold text-13 md:text-15 whitespace-nowrap overflow-hidden text-ellipsis m-0'
              data-testid='heading'>
              {heading}
            </p>
            {!!leftEl && leftEl}
          </div>
        )}
        <div className={`block h-full flex-1 relative ${className}`}>{children}</div>
      </div>
    );
  }, [children, className, heading, leftEl, onClickCard]);

  if (href) {
    return (
      <Link href={href} className={containerClasses} data-testid={cardId}>
        {el}
      </Link>
    );
  }

  return (
    <div
      className={classNames(containerClasses, Boolean(onClick) && 'cursor-pointer')}
      data-testid={cardId}>
      {el}
    </div>
  );
}

export default memo(Card);
