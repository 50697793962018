import { useHeadObject } from 'hooks/useHeadObject';
import { useMember } from 'hooks/useMember';
import { useSignUrl } from 'hooks/useSignUrl';
import { memo } from 'react';

import { CsvIcon } from 'components';

function CrsCsvDownload({ chartCode }: { chartCode: string }) {
  const { member } = useMember();
  const {
    id,
    // @ts-ignore
    profile: { mepr_parent_id: parentId },
  } = member;
  const key = parentId || id;
  const { data: exists } = useHeadObject(`custom-research/${chartCode}.csv`);
  const { url: csvUrl } = useSignUrl(`${key}/custom-research/${chartCode}.csv`);

  if (!exists || !csvUrl) {
    return null;
  }

  return (
    <a href={csvUrl} download={`${chartCode}.csv`}>
      <CsvIcon />
    </a>
  );
}

CrsCsvDownload.displayName = 'CrsCsvDownload';

export default memo(CrsCsvDownload);
