import { CategorizedPost } from '@3fourteen/core';
import { memo } from 'react';

import { Checkbox, PostIcon } from 'components';
import { CheckboxProps } from 'components/Checkbox/component';

type PostCheckboxProps = Omit<CheckboxProps, 'children'> & {
  categorySlug: CategorizedPost['categorySlug'];
};

function PostCheckbox({
  categorySlug,
  id,
  label,
  toggleCheck,
  isChecked,
}: PostCheckboxProps) {
  return (
    <Checkbox
      id={id}
      label={label}
      toggleCheck={toggleCheck}
      isChecked={!!isChecked}
    >
      <div className='mr-1 ml-3'>
        <PostIcon categorySlug={categorySlug} size={14} color='#231f20' />
      </div>
      <span className={isChecked ? 'checked' : undefined}>{label}</span>
    </Checkbox>
  );
}

export default memo(PostCheckbox);
