import { motion, useMotionValue, useTransform } from 'framer-motion';
import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { SearchBar, SimpleLineIcon } from 'components';

interface PostsListLayoutProps {
  icon?: string;
  pageHeading: string;
  MainContent: React.ReactNode;
  SidebarContent: React.ReactNode;
  onSearch: (term: string) => void;
  onClearSearch: () => void;
  numResults: number;
  searchPlaceholder?: string;
  isLoading?: boolean;
  searchTerm?: string;
}

const PostsListLayout = React.forwardRef<HTMLDivElement, PostsListLayoutProps>(
  (
    {
      icon,
      pageHeading,
      MainContent,
      SidebarContent,
      onSearch,
      onClearSearch,
      numResults,
      searchPlaceholder,
      isLoading = false,
      searchTerm,
    },
    ref
  ) => {
    const scrollY = useMotionValue(0);
    const handleScroll = (e) => {
      scrollY.set(e.nativeEvent.target.scrollTop);
    };

    const height = useTransform(scrollY, [0, 160], [100, 69]);
    const borderColor = useTransform(scrollY, [0, 160], ['#fff', 'rgba(0,0,0,0.1)']);

    return (
      <div className='bg-neutral-50'>
        <div className='flex h-auto lg:h-screen'>
          <section className='hidden md:block basis-72 relative z-[3] h-screen overflow-y-auto pb-8'>
            <div className='flex items-center basis-72 pt-6 pb-4 px-4 md:px-6 border-b border-solid border-black/10'>
              {!!icon && (
                <div className='mr-2'>
                  <SimpleLineIcon name={icon} />
                </div>
              )}
              <h1 className='uppercase font-medium text-lg'>{pageHeading}</h1>
            </div>
            <div>{SidebarContent}</div>
          </section>
          <section
            ref={ref}
            className='min-w-0 h-screen md:h-screen flex-1 bg-white overflow-y-auto md:border-l md:border-solid border-neutral-200 shadow-[inset_2px_0px_2px_0px_rgba(200,200,200,0.5)]'
            onScroll={handleScroll}>
            <motion.div
              className='hidden md:flex bg-white fixed right-0  justify-center items-center z-[2] border-b border-solid border-transparent w-[calc(100vw_-_296px)] lg:w-[calc(100vw_-_355.5px)] xl:w-[calc(100vw_-_526px)] 2xl:w-[calc(100vw_-_650px)]'
              style={{
                height,
                borderBottomColor: borderColor,
              }}>
              <div className='flex items-end grow'>
                {!!numResults && searchTerm && (
                  <div className='my-0.5 absolute left-0 mx-4 lg:mx-6'>
                    <p
                      className='text-sm font-medium text-center '
                      data-testid='search-results-text'>{`${numResults} results for "${searchTerm}"`}</p>
                  </div>
                )}
                <div className='mx-auto w-[380px] lg:w-[475px]'>
                  <SearchBar
                    placeholder={searchPlaceholder || ''}
                    onSubmit={onSearch}
                    onClear={onClearSearch}
                  />
                </div>
              </div>
            </motion.div>
            <h1 className='md:hidden text-xl font-semibold text-center pt-3 pb-2 mb-2 text-teal-800 border-b border-solid border-black/20'>
              {pageHeading}
            </h1>
            {isLoading ? (
              <div className='mx-4 lg:mx-6 '>
                {isLoading ? <Skeleton count={40} /> : <Skeleton count={10} />}
              </div>
            ) : (
              <div>
                {numResults > 0 && MainContent}
                {numResults === 0 && (
                  <div className='text-center mt-40'>No results, please try another search</div>
                )}
              </div>
            )}
          </section>
        </div>
      </div>
    );
  }
);

PostsListLayout.displayName = 'PostsListLayout';

export default memo(PostsListLayout);
