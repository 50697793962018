import { CategorizedPost, Chart, Commentary, Model } from '@3fourteen/core';
import { memo, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ChartItem, CommentaryItem, ModelItem } from 'components';

interface PostItemProps {
  post: CategorizedPost;
}

function PostItem({ post }: PostItemProps) {
  const renderItem = useCallback(() => {
    const { categorySlug } = post;

    switch (categorySlug) {
      case 'chart-books':
      case 'model-updates':
      case 'publications':
      case 'position-updates':
      case 'retrospectives': {
        return <CommentaryItem commentary={post as Commentary} />;
      }
      case 'charts': {
        return <ChartItem chart={post as Chart} showIcon />;
      }
      case 'models': {
        return <ModelItem model={post as Model} />;
      }
      default: {
        return null;
      }
    }
  }, [post]);

  if (!post) return <Skeleton count={2} style={{ margin: '10px 0px' }} />;

  return renderItem();
}
export default memo(PostItem);
