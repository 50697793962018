import { DrawdownRiskResponse, fetchDrawdownReadings } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useDrawdown() {
  const { data: drawdown, ...rest } = useQuery<DrawdownRiskResponse>({
    queryKey: ['drawdownReadings'],
    queryFn: fetchDrawdownReadings,
  });

  return {
    drawdown,
    ...rest,
  };
}
