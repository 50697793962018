import { Commentary, getDateProperties } from '@3fourteen/core';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo } from 'react';

import Image from 'next/image';

interface ImageHtmlProps extends HTMLImageElement {
  date: Commentary['date'];
  categorySlug: Commentary['categorySlug'];
}

function ImageHtml({ src, className, date, categorySlug }: ImageHtmlProps) {
  const urlAttribs = src.split('/');
  const filename = urlAttribs[urlAttribs.length - 1];
  const { isoDate, year } = getDateProperties(date);
  const key = `${categorySlug}/${year}/${isoDate}/${filename}`;

  const { url } = useSignedCloudfrontUrl(key);

  return url ? (
    <div className='relative'>
      <Image
        src={url}
        alt='3Fourteen Chart'
        className={className}
        height={1000}
        width={1000}
        loading='eager'
      />
    </div>
  ) : null;
}

ImageHtml.displayName = 'ImageHtml';

export default memo(ImageHtml);
