import { Form, Formik } from 'formik';
import React, { memo, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { AntIcon, Button, SimpleLineIcon } from 'components';
import { trackSearchTerm } from 'services/mixpanel';

import SearchInput from './SearchInput';

interface SearchBarProps {
  placeholder?: string;
  onSubmit?: (term: string) => void;
  onClear?: () => void;
  button?: 'arrow' | 'search';
  isLoading?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

function SearchBar({
  placeholder,
  onSubmit,
  onClear,
  isLoading = false,
  button,
  autoFocus,
  onBlur,
  onFocus,
}: SearchBarProps) {
  const onSearch = useCallback(
    (values) => {
      onSubmit(values.search);
    },
    [onSubmit]
  );

  return (
    <Formik initialValues={{ search: '' }} onSubmit={onSearch}>
      {({ handleSubmit, values, setFieldValue }) => {
        const submit = (e) => {
          // hide keyboard on submit (for mobile, obvi.)
          e.target[0].blur();
          handleSubmit(e);
          trackSearchTerm(values.search);
        };

        const onClearInput = () => {
          setFieldValue('search', '');
          onClear && onClear();
        };

        return (
          <Form onSubmit={submit}>
            <div className='flex justify-center'>
              <div className='flex items-center grow'>
                <SearchInput
                  placeholder={placeholder}
                  autoFocus={autoFocus}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
                {!!values.search && !isMobile && (
                  <div className='relative ml-[-32px]'>
                    <Button
                      className='p-0'
                      type='button'
                      onClick={onClearInput}
                      data-testid='search-clear-btn'
                    >
                      <AntIcon name='close' />
                    </Button>
                  </div>
                )}
              </div>
              {!!button && (
                <Button
                  type='submit'
                  className='btn-primary w-20 ml-4 hidden md:flex'
                  isLoading={isLoading}
                  disabled={isLoading || !values.search}
                  data-testid='search-submit-btn'
                >
                  {button === 'arrow' ? (
                    <AntIcon name='arrowright' color='white' size={22} />
                  ) : (
                    <SimpleLineIcon name='magnifier' color='white' />
                  )}
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default memo(SearchBar);
