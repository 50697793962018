'use client';

import { ErrorMessage, Field } from 'formik';
import { useMyPortfolios } from 'hooks/useMyPortfolios';
import { memo, useCallback, useEffect, useRef } from 'react';

import { useParams } from 'next/navigation';

function ModelNameField() {
  const { portfolios } = useMyPortfolios();
  const params = useParams();
  const id = decodeURIComponent(params.id as string);
  const modelNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!id) {
      modelNameRef.current?.focus();
    }
  }, [id]);

  const validate = useCallback(
    (value) => {
      const alreadyExists = portfolios?.find(
        (port) => port.config.model_name.toLowerCase() === value.toLowerCase()
      );

      const isUpdatingExisting = alreadyExists?.id === id;

      if (!id) {
        if (alreadyExists) {
          return 'You already have a model with this name';
        }
      } else {
        if (alreadyExists && !isUpdatingExisting) {
          if (alreadyExists) {
            return 'You already have a model with this name';
          }
        }
      }
    },
    [portfolios, id]
  );

  return (
    <div className='mt-1 relative'>
      <Field
        innerRef={modelNameRef}
        type='text'
        name='model_name'
        id='model_name'
        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-800 focus:ring-teal-800 sm:text-sm'
        placeholder='Custom Model Name'
        validate={validate}
      />
      <div className='absolute bottom-[-18px] left-0 text-xs text-red-800 font-semibold'>
        <ErrorMessage name='model_name' />
      </div>
    </div>
  );
}

ModelNameField.displayName = 'ModelNameField';

export default memo(ModelNameField);
