import { motion, useMotionValue } from 'framer-motion';
import { memo } from 'react';

interface PercentageCircleProps {
  proportion: number;
  /** Color of proportion line */
  color?: string;
  size?: "small" | "regular";
}

const sizeMap = {
  regular: {
    strokeWidth: 10,
    size: 30,
  },
  small: {
    strokeWidth: 10,
    size: 20,
  },
};

const PercentageCircle = ({
  proportion,
  color = "#254653",
  size = "regular",
}: PercentageCircleProps) => {
  const defaultLength = useMotionValue(1);
  const strokeWidth = sizeMap[size].strokeWidth;
  const circleSize = sizeMap[size].size;

  const circle = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: proportion,
    },
  };

  return (
    <div style={{ height: circleSize, width: circleSize }}>
      <svg
        viewBox="0 0 50 50"
        width={`${circleSize}px`}
        height={`${circleSize}px`}
      >
        <motion.path
          fill="none"
          strokeWidth={strokeWidth}
          stroke="#eee"
          strokeDasharray="0 1"
          d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
          style={{
            pathLength: defaultLength,
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1, // Reverse direction of line animation
          }}
        />
        <motion.path
          fill="none"
          strokeWidth={strokeWidth}
          stroke={color}
          strokeDasharray="0 1"
          d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
          style={{
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1, // Reverse direction of line animation
          }}
          variants={circle}
          initial="hidden"
          animate="visible"
          transition={{
            pathLength: { duration: 0.25, ease: "easeIn" },
          }}
        />
      </svg>
    </div>
  );
};

export default memo(PercentageCircle);
