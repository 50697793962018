import { memo } from 'react';

interface AssetClassBreakdownBar {
  fixedIncome: number;
  equities: number;
  alternatives: number;
  benchmark?: number;
}

function AssetClassBreakdownBar({
  fixedIncome,
  equities,
  alternatives,
  benchmark = 1,
}: AssetClassBreakdownBar) {
  return (
    <div className='relative my-2 h-6 w-full'>
      <div className='absolute top-0 left-0 right-0 bottom-0 rounded-[16px] overflow-hidden flex'>
        {fixedIncome > 0 && (
          <div
            className=' bg-olive  flex justify-center items-center transition-width ease-out duration-500'
            style={{
              width: `${fixedIncome}%`,
            }}>
            <span className='font-semibold 2xl:font-bold text-white block text-center text-13 2xl:text-sm'>{`${fixedIncome}%`}</span>
          </div>
        )}
        {equities > 0 && (
          <div
            className=' bg-navy flex justify-center items-center transition-width ease-out duration-500 '
            style={{
              width: `${equities}%`,
            }}>
            <span className='font-semibold 2xl:font-bold text-white block text-center text-13 2xl:text-sm'>{`${equities}%`}</span>
          </div>
        )}
        {alternatives > 0 && (
          <div
            className='bg-fuchsia flex justify-center items-center transition-width ease-out duration-500'
            style={{
              width: `${alternatives}%`,
            }}>
            <span className='font-semibold 2xl:font-bold text-white block text-center text-13 2xl:text-sm'>{`${alternatives}%`}</span>
          </div>
        )}
        {benchmark !== 1 && <div className='bg-gray-200 flex-1'></div>}
      </div>
    </div>
  );
}

AssetClassBreakdownBar.displayName = 'AssetClassBreakdownBar';

export default memo(AssetClassBreakdownBar);
