import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useMember } from 'hooks/useMember';
import { AnchorHTMLAttributes, memo } from 'react';

import { Tooltip } from 'components';

interface DownloadButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  text?: string;
}

const DownloadButton = ({ text = 'Download', ...rest }: DownloadButtonProps) => {
  const { isTrialUser } = useMember();

  if (isTrialUser) {
    return (
      <div>
        <span
          className='btn btn-secondary btn-xs cursor-not-allowed opacity-75'
          data-tooltip-id='download-button-tip'
          data-tooltip-content='Upgrade to download'>
          <ArrowDownTrayIcon className='size-4 text-teal-800' />
          <span className='hidden md:inline-block ml-1.5 text-teal-800'>{text}</span>
        </span>
        <Tooltip id='download-button-tip' />
      </div>
    );
  }

  return (
    <a {...rest} className='btn btn-secondary btn-xs'>
      <ArrowDownTrayIcon className='size-4 text-teal-800' />
      <span className='hidden md:inline-block ml-1.5 text-teal-800'>{text}</span>
    </a>
  );
};

export default memo(DownloadButton);
