import { fetchMyPortfolios, MyPortfoliosResponse } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export const myPortfoliosQueryKey = 'pb-my-portfolios';

export function useMyPortfolios(options?: any) {
  const { data: portfolios, ...rest } = useQuery<MyPortfoliosResponse>({
    queryKey: [myPortfoliosQueryKey],
    queryFn: fetchMyPortfolios,
    ...options,
  });

  return {
    portfolios,
    ...rest,
  };
}
