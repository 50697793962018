import { CategorySlug } from '@3fourteen/core';
import React, { memo } from 'react';

import { AntIcon, SimpleLineIcon, UpDownArrowIcon } from 'components';

interface PostIconProps {
  categorySlug: CategorySlug;
  size?: number;
  color?: string;
}

const PostIcon = ({
  categorySlug,
  size = 18,
  color = '#666',
}: PostIconProps) => {
  const renderIcon = () => {
    switch (categorySlug) {
      case 'chart-books': {
        return <SimpleLineIcon size={size} name='notebook' color={color} />;
      }
      case 'model-updates': {
        return <AntIcon size={size} name='linechart' color={color} />;
      }
      case 'models': {
        return <SimpleLineIcon size={size} name='graph' color={color} />;
      }
      case 'publications': {
        return <AntIcon size={size} name='filetext1' color={color} />;
      }
      case 'position-updates': {
        return <UpDownArrowIcon color={color} size={size} />;
      }
      case 'retrospectives': {
        return <SimpleLineIcon size={size} name='microphone' color={color} />;
      }
      case 'charts': {
        return <SimpleLineIcon size={size} name='chart' color={color} />;
      }
    }
  };

  return <span data-testid={`${categorySlug}-icon`}>{renderIcon()}</span>;
};

export default memo(PostIcon);
