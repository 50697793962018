'use client';

import { getMe, isValidEmail, updateMe } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import React, { memo, useEffect, useState } from 'react';

import { Button, Input } from 'components';
import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

const EditProfileForm = () => {
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
  });
  const [errors, setErrors] = useState({
    email: '',
  });

  const [firstName, setFirstName] = useState<UserInfo['firstName']>('');
  const [lastName, setLastName] = useState<UserInfo['lastName']>('');
  const [email, setEmail] = useState<UserInfo['email']>('');

  const isDirty = !!firstName || !!lastName || !!email;
  const isValidForm = !errors['email'];

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { member } = await getMe();

        setUserInfo({
          firstName: member?.first_name,
          lastName: member?.last_name,
          email: member?.email,
        });
      } catch (error) {
        const databaseId = localStorage.getItem('databaseId');

        Bugsnag.notify(error, (event) => {
          event.context = 'getUserInfo() in EditProfileForm';
          event.addMetadata("Add'l Info", {
            databaseId,
          });
        });
      }
    };

    if (isAuthenticated) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const onUpdateProfile = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const userId = localStorage.getItem('userId');

    try {
      const updatedUser = await updateMe({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
      });

      showToast({
        message: 'Your profile updated successfully.',
        type: 'success',
      });

      setUserInfo(updatedUser);

      clearForm();
    } catch (error) {
      showToast({ message: error.message, type: 'error' });

      Bugsnag.notify(error, (event) => {
        event.context = 'onUpdateProfile() in EditProfileForm';
        event.addMetadata("Add'l Info", {
          userId,
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);

    const isValid = isValidEmail(email);

    if (isValid && !!errors.email) {
      setErrors({ ...errors, email: '' });
    }
  };

  const onBlurEmail = (e) => {
    const isValid = isValidEmail(email);

    if (!isValid && !!email) {
      setErrors({ ...errors, email: 'Invalid email address' });
    }
  };

  const onEnterKey = (e) => {
    if (e.keyCode === 13 && isValidForm) {
      onUpdateProfile(e);
      e.target.blur();
    }
  };

  const onKeyDownEmail = (e) => {
    // If user pressed backspace or delete and email is empty, delete any errors
    if ((e.keyCode === 8 || e.keyCode === 46) && !!errors.email) {
      setErrors({ ...errors, email: '' });
    }

    onEnterKey(e);
  };

  return (
    <form action='' className='flex flex-col items-center justify-center'>
      <Input
        placeholder={userInfo.firstName}
        onChange={onChangeFirstName}
        value={firstName}
        onKeyDown={onEnterKey}
      />
      <Input
        placeholder={userInfo.lastName}
        onChange={onChangeLastName}
        value={lastName}
        onKeyDown={onEnterKey}
      />
      <Input
        placeholder={userInfo.email}
        onChange={onChangeEmail}
        value={email}
        onBlur={onBlurEmail}
        error={errors.email}
        onKeyDown={onKeyDownEmail}
      />
      <div className='py-4 md:py-6'>
        <Button
          className='btn-secondary w-52'
          disabled={!isDirty || !isValidForm || !isAuthenticated}
          onClick={onUpdateProfile}
          isLoading={isLoading}>
          Update Profile
        </Button>
      </div>
    </form>
  );
};

export default memo(EditProfileForm);
