import { AnimatePresence, motion } from 'framer-motion';
import { memo } from 'react';

import { AntIcon } from 'components';

interface ToastMessageProps {
  message: string;
  visible: boolean;
  type?: 'success' | 'error' | 'warning' | 'info';
}

const toastMap = {
  success: {
    color: '#6a8d73',
    icon: 'checkcircle',
  },
  error: {
    color: '#b45048',
    icon: 'closecircle',
  },
  warning: {
    color: '#fcbb40',
    icon: 'exclamationcircle',
  },
  info: {
    color: '#4876b4',
    icon: 'infocirlce',
  },
};

const transition = {
  duration: 0.8,
  ease: 'easeInOut',
};

const variants = {
  hidden: {
    y: '-100vh',
    x: '-50%',
    opacity: 0,
    transition,
  },
  animate: {
    y: 50,
    x: '-50%',
    opacity: 1,
    transition,
  },
  exit: {
    opacity: 0,
    x: '-50%',
    y: '-100vh',
    transition: {
      duration: 0.5,
    },
  },
};

function ToastMessage({ visible, message, type = 'info' }: ToastMessageProps) {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className='shadow-xl max-w-[92vw] md:max-w-[500px] fixed top-0 left-[50%] w-max-content flex items-center bg-white rounded-sm py-6 pr-6 border-l-4 border-solid border-l-transparent border-y border-y-zinc-200 border-r border-r-zinc-200 z-[2]'
          style={{ borderLeftColor: toastMap[type].color }}
          initial='hidden'
          animate='animate'
          exit='exit'
          variants={variants}
          transition={transition}
        >
          <div className='mr-4 ml-5'>
            <AntIcon
              name={toastMap[type].icon}
              color={toastMap[type].color}
              size={20}
            />
          </div>
          <p>{message}</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default memo(ToastMessage);
