'use client';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'helpers/classNames';
import { Fragment, memo, useEffect, useState } from 'react';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { RaaVersion, raaVersions } from 'services/raa';

function RaaSelect() {
  const pathname = usePathname();
  const [selected, setSelected] = useState<RaaVersion | ''>('');

  useEffect(() => {
    const currentRaa = raaVersions.find(({ href }) => pathname === href);

    setSelected(currentRaa || '');
  }, [pathname]);

  return (
    <Listbox value={selected}>
      {({ open }) => (
        <>
          <div className='relative mt-1'>
            <Listbox.Button className='relative w-full cursor-default rounded-md border border-teal-800 border-solid bg-transparent py-1.5 pl-2 pr-5 text-left shadow-sm focus:border-teal-700 focus:outline-none focus:ring-1 focus:ring-teal-700 text-sm'>
              <span className='block truncate font-semibold text-center'>
                {selected ? selected.displayName : ''}
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1'>
                <ChevronUpDownIcon className='h-4 w-4 text-gray-600' aria-hidden='true' />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-20 overflow-auto rounded-md bg-white py-1 text-sm shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none'>
                {raaVersions.map((version) => (
                  <Listbox.Option
                    key={version.displayName}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-teal-800' : 'text-gray-900',
                        'relative cursor-default select-none py-2 px-2'
                      )
                    }
                    value={version}>
                    {({ selected, active }) => (
                      <>
                        <Link href={version.href} className='block truncate font-medium pr-1'>
                          {version.displayName}
                        </Link>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-teal-800',
                              'absolute inset-y-0 right-0 flex items-center pr-2'
                            )}>
                            <CheckIcon className='h-4 w-4' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

RaaSelect.displayName = 'RaaSelect';

export default memo(RaaSelect);
