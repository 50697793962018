import { retrospectiveCategoryId } from '@3fourteen/core';
import { useCommentary } from 'hooks/useCommentary';
import { memo, useMemo } from 'react';

import { Card, CommentaryItem } from 'components';

function RecentRetroCard() {
  const { commentary } = useCommentary([
    'recent-retro',
    {
      categories: [retrospectiveCategoryId],
      first: 1,
    },
  ]);

  const href = useMemo(() => {
    return commentary?.[0] ? `/${commentary?.[0].categorySlug}/${commentary?.[0].slug}` : null;
  }, [commentary]);

  return (
    <Card cardId='recent-retro' className='py-2 px-4 md:py-3 md:px-6' href={href}>
      <CommentaryItem commentary={commentary?.[0]} useHref={false} showExcerpt={false} />
    </Card>
  );
}

export default memo(RecentRetroCard);
