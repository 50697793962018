import { EventName, EventParams } from '@3fourteen/core';
import mixpanelPlugin from '@analytics/mixpanel';
import Analytics from 'analytics';

import { IS_PRODUCTION } from './environment';

const IS_BROWSER = typeof window !== 'undefined';
const IS_APP_3FR_COM =
  typeof window !== 'undefined' &&
  window.location.hostname === 'app.3fourteenresearch.com';

const plugins = [
  mixpanelPlugin({
    token: process.env.NEXT_PUBLIC_MIXPANEL_KEY,
  }),
];

export const analytics = Analytics({
  app: '3fr-web',
  plugins: [...(IS_BROWSER && IS_APP_3FR_COM ? plugins : [])],
});

interface Traits {
  email?: string;
}

interface AnalyticsOptions {
  mixpanel?: boolean;
}
export async function disableAnalytics() {
  // @ts-ignore
  await analytics.plugins.disable('mixpanel');
}

export async function enableAnalytics() {
  await analytics.plugins.enable('mixpanel');
}

export function identify(
  userId,
  traits: Traits = {},
  options: AnalyticsOptions = {}
) {
  if (IS_APP_3FR_COM) {
    analytics.identify(userId, traits, options);
  }
}

function trackMixpanelEvent(event: EventName, params?: EventParams) {
  if (!IS_PRODUCTION) {
    console.log('event :>> ', event);
    console.log('params :>> ', params);
    return;
  } else {
    analytics.track(event, params);
  }
}

export const trackLoggedIn = (email: EventParams['email']) => {
  trackMixpanelEvent('Logged in', {
    email,
  });
};

export const trackLoggedOut = (email: EventParams['email']) => {
  trackMixpanelEvent('Logged out', {
    email,
  });
};

export const trackSearchTerm = (term: EventParams['term']) => {
  trackMixpanelEvent('Searched term', {
    term,
  });
};

export const trackViewedPost = (
  title: EventParams['title'],
  category: EventParams['category'],
  filename: EventParams['filename'],
  isHtml: EventParams['isHtml']
) => {
  trackMixpanelEvent('Viewed post', {
    title,
    category,
    filename,
    isHtml,
  });
};

export const trackPressedCard = (card: EventParams['card']) => {
  trackMixpanelEvent('Pressed card', {
    card,
  });
};

export const trackFilteredCommentary = (filter: string) => {
  trackMixpanelEvent('Filtered commentary', {
    filter,
  });
};

export const trackSortedCommentary = (field: string, order: 'ASC' | 'DESC') => {
  trackMixpanelEvent('Sorted commentary', {
    field,
    order,
  });
};

export const trackFilteredCharts = (filter: string) => {
  trackMixpanelEvent('Filtered charts', {
    filter,
  });
};

export const trackSortedCharts = (field: string, order: 'ASC' | 'DESC') => {
  trackMixpanelEvent('Sorted charts', {
    field,
    order,
  });
};

export const trackToggledPostViewingPreference = (
  isHtml: EventParams['isHtml']
) => {
  trackMixpanelEvent('Toggled report viewing preference', {
    isHtml,
  });
};

export const trackDownloadedFile = ({
  filename,
}: {
  filename: EventParams['filename'];
}) => {
  trackMixpanelEvent('Downloaded file', {
    filename,
  });
};

export const trackRanPortfolio = () => {
  trackMixpanelEvent('Ran portfolio');
};
