import { formatPercent, getRecReading, recommendationMap } from '@3fourteen/core';
import dayjs from 'dayjs';
import { useStratAA } from 'hooks/useStratAA';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

function StrategicAA() {
  const { stratAA } = useStratAA();

  return (
    <div className='lg:w-full -mx-6 lg:mx-0 h-full pb-3 bg-white/80 border border-solid border-zinc-200 rounded flex flex-col justify-between'>
      <table>
        <thead>
          <tr>
            <th className='font-bold text-left pl-6 lg:pl-6 pt-3 pb-2 text-13 bg-white md:text-sm uppercase border-b border-solid border-black/10 w-[20%] rounded-sm'>
              Asset
            </th>
            <th className='font-bold text-left pl-2 md:pl-4 lg:pl-6 pt-3 pb-2 text-13 bg-white md:text-sm uppercase border-b border-solid border-black/10 w-[30%] text-ellipsis overflow-hidden whitespace-nowrap max-w-[1px]'>
              Recommendations
            </th>
            <th className='font-bold text-left pl-2 md:pl-4 lg:pl-6 pt-3 pb-2 text-13 bg-white md:text-sm uppercase border-b border-solid border-black/10 w-[25%] text-ellipsis overflow-hidden whitespace-nowrap max-w-[1px]'>
              Benchmark
            </th>
            <th className='font-bold text-left pl-2 md:pl-4 lg:pl-6 pt-3 pb-2 text-13 bg-white md:text-sm uppercase border-b border-solid border-black/10 w-[12.5%]'>
              Max
            </th>
            <th className='font-bold text-left pl-2 pr-4 md:pl-4 lg:pl-6 pt-3 pb-2 text-13 bg-white md:text-sm uppercase border-b border-solid border-black/10 w-[12.5%] rounded-sm'>
              Min
            </th>
          </tr>
        </thead>
        {stratAA && (
          <tbody className='px-6 mt-1 w-full'>
            {stratAA[0].assets.map(({ name, recommendation, benchmark, max, min }) => {
              const recReading = getRecReading(recommendation, benchmark);
              const { color, label } = recommendationMap[recReading];

              return (
                <tr key={name} className='text-sm lg:text-15'>
                  <td className='py-1 pl-6 lg:pl-6 border-b border-solid border-neutral-200 text-13 md:text-15'>
                    {name}
                  </td>
                  <td className='py-1 pl-2 md:pl-4 lg:pl-6 border-b border-solid border-neutral-200'>
                    <span
                      className='inline-block w-full max-w-[125px] rounded-sm text-white text-center font-bold py-0.5 whitespace-nowrap'
                      style={{ backgroundColor: color }}>
                      <span>{formatPercent(recommendation, 0)}</span>
                      <span className='ml-1 text-sm'>{`(${label})`}</span>
                    </span>
                  </td>
                  <td className='py-1 pl-2 md:pl-4 lg:pl-6 border-b border-solid border-neutral-200 text-center md:text-left'>
                    <span>{formatPercent(benchmark, 0)}</span>
                  </td>
                  <td className='py-1 pl-2 md:pl-4 lg:pl-6 border-b border-solid border-neutral-200'>
                    <span>{formatPercent(max, 0)}</span>
                  </td>
                  <td className='py-1 pl-2 pr-4 md:pl-4 lg:pl-6 border-b border-solid border-neutral-200'>
                    <span>{formatPercent(min, 0)}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>

      {!stratAA && (
        <div className='mt-4 mx-4 lg:mx-6'>
          <Skeleton count={4} />
        </div>
      )}
      <div className='text-13 md:text-sm lg:flex justify-between px-6 pb-2 mt-4 3xl:mt-8'>
        {stratAA && (
          <span className='font-semibold whitespace-nowrap mr-4'>{`Updated ${dayjs(
            stratAA[0].date
          ).format('MMM DD, YYYY')}`}</span>
        )}
        <div className='text-13 md:text-sm flex items-center flex-wrap mt-2 lg:mt-0 tracking-tighter md:tracking-normal'>
          <span className='mr-1'>UW = Underweight;</span>
          <span className='mr-1'>BMW = Benchmark Weight;</span>
          <span>OW = Overweight</span>
        </div>
      </div>
    </div>
  );
}

StrategicAA.displayName = 'StrategicAA';

export default memo(StrategicAA);
