import { PlusIcon } from '@heroicons/react/20/solid';
import { useMyPortfolios } from 'hooks/useMyPortfolios';
import { memo } from 'react';

import Link from 'next/link';
import { accountLinks } from 'services/links';
import { routes } from 'services/routes';

import { NavLink } from '../';

function PbNav() {
  const { portfolios, isLoading } = useMyPortfolios();

  return (
    <div className='flex-1 flex flex-col justify-between'>
      <nav className='flex-1'>
        <p className='text-white py-2 px-6 block overflow-hidden font-medium whitespace-nowrap text-ellipsis leading-tight uppercase text-15 cursor-default'>
          Relevant Research
        </p>
        <Link
          className='text-white py-2 pl-12 pr-6 block overflow-hidden whitespace-nowrap text-ellipsis leading-tight text-sm font-light cursor-pointer hover:bg-white/10 transition-colors ease-in-out duration-200'
          href='/research/publications/real-asset-allocation-the-world-has-changed'>
          RAA: The World Has Changed
        </Link>

        <Link
          href={routes.portfolioBuilder.demo}
          className='text-white py-2 px-6 block overflow-hidden font-medium whitespace-nowrap text-ellipsis leading-tight uppercase text-15 cursor-pointer hover:bg-white/10 transition-colors ease-in-out duration-200'>
          Tips & Demo
        </Link>

        <Link
          href={routes.portfolioBuilder.root}
          className='text-white py-2 px-6 block overflow-hidden font-medium whitespace-nowrap text-ellipsis leading-tight uppercase text-15 cursor-pointer hover:bg-white/10 transition-colors ease-in-out duration-200'>
          Builder
        </Link>

        <Link
          href={routes.portfolioBuilder.portfolios.root}
          className='text-white py-2 px-6 block overflow-hidden font-medium whitespace-nowrap text-ellipsis leading-tight uppercase text-15 cursor-pointer hover:bg-white/10 transition-colors ease-in-out duration-200'>
          My Portfolios
        </Link>

        {isLoading && (
          <div className='flex items-center'>
            <p className='text-white py-2 pl-6 pr-1 block overflow-hidden whitespace-nowrap text-ellipsis leading-tight  text-15 cursor-default'>
              Loading Portfolios...
            </p>
          </div>
        )}

        {portfolios && !portfolios.length && (
          <Link
            passHref={true}
            className='flex items-center text-white py-2 pl-12 pr-6 overflow-hidden whitespace-nowrap text-ellipsis leading-tight text-sm font-light cursor-pointer hover:bg-white/10 transition-colors ease-in-out duration-200'
            href={routes.portfolioBuilder.root}>
            <span>Create a Portfolio</span>
            <PlusIcon className='h-4 w-4 ml-1 text-white' />
          </Link>
        )}

        {portfolios && portfolios.length && (
          <div>
            {portfolios.map(({ id, config }) => {
              return (
                <Link
                  className='text-white py-2 pl-12 pr-6 block overflow-hidden whitespace-nowrap text-ellipsis leading-tight text-sm font-light cursor-pointer hover:bg-white/10 transition-colors ease-in-out duration-200'
                  key={id}
                  href={`/portfolio-builder/portfolios/${id}`}>
                  {config.model_name}
                </Link>
              );
            })}
          </div>
        )}
      </nav>

      <nav className='flex flex-col text-white mt-4'>
        {accountLinks.map(({ displayName, route, icon, onClick, iconFamily }) => (
          <NavLink
            href={route}
            text={displayName}
            key={displayName}
            icon={icon}
            onClick={onClick}
            iconFamily={iconFamily}
            className='capitalize'
          />
        ))}
      </nav>
    </div>
  );
}

PbNav.displayName = 'PbNav';

export default memo(PbNav);
