import { memo } from 'react';

import { AntIcon } from 'components';

interface FilterTagProps {
  text: string;
  onClick: () => void;
}

function FilterTag({ text, onClick }: FilterTagProps) {
  return (
    <button
      onClick={onClick}
      className='bg-zinc-200 border-b-2 border-x border-black/10 border-solid pt-1.5 px-3 pb-1 flex items-center rounded-2xl'
    >
      <AntIcon name='close' size={12} />
      <span className='font-semibold inline-block pl-2 text-13 text-gray-500 pb-0.5'>
        {text}
      </span>
    </button>
  );
}

export default memo(FilterTag);
