import { memo } from 'react';

import glyphMap from './icon.json';

interface FontistoIconProps {
  name: string;
  size?: number | string;
  color: string;
}

function FontistoIcon({ name, size = 16, color }: FontistoIconProps) {
  const glyph = glyphMap?.[name];

  return (
    <span style={{ fontSize: size, color, fontFamily: 'FontistoIcon' }}>
      {!!glyph ? String.fromCodePoint(glyph) : ''}
    </span>
  );
}

export default memo(FontistoIcon);
