import { login, LoginParams, logout } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';

export const onLogin = async (email: LoginParams['email'], password: LoginParams['password']) => {
  try {
    const data = await login({
      email,
      password,
    });
    const { authToken, userId, databaseId } = data;

    if (!!authToken && !!userId && !!databaseId) {
      localStorage.setItem('userId', userId);
      localStorage.setItem('databaseId', `${databaseId}`);
    }

    Bugsnag.setUser(userId, email);

    return {
      authToken,
      userId,
    };
  } catch (e) {
    Bugsnag.notify(e, (event) => {
      event.context = 'onLogin() from auth service';
    });
    throw e;
  }
};

export const onLogout = async () => {
  await logout();

  localStorage.removeItem('userId');
  localStorage.removeItem('databaseId');

  window.location.href = '/login';
};
