'use client';

import { fetchSectorRotation, sectorDisplayNameMap, SectorRotationResponse } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useSectorRotation() {
  const { data: sectorRotation, ...rest } = useQuery<SectorRotationResponse>({
    queryKey: ['sector-rotation'],
    queryFn: async () => {
      const res = await fetchSectorRotation();

      const sectorData = res?.data.map((sector) => ({
        ...sector,
        index: sectorDisplayNameMap[sector.index] || sector.index,
      }));

      return {
        date: res?.date,
        data: sectorData,
      };
    },
  });

  return {
    sectorRotation,
    ...rest,
  };
}
