'use client';

import { createContext, useContext, useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';

interface HistoryContextType {
  history: string[];
}

const HistoryContext = createContext({});

export const HistoryProvider = ({ children }) => {
  const pathname = usePathname();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    setHistory([pathname, ...history]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>;
};

export const useHistory = () => useContext(HistoryContext) as HistoryContextType;
