import dayjs from 'dayjs';
import { useFullCycle } from 'hooks/useFullCycle';
import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, RebalDate } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface FullCycleCardProps {
  cardId: CardProps['cardId'];
}

function FullCycleCard({ cardId }: FullCycleCardProps) {
  const { fullCycle } = useFullCycle();
  const [stocks, setStocks] = useState(undefined);
  const title = 'Full Cycle Trend';

  useEffect(() => {
    if (fullCycle?.data) {
      /** Filter out inactive picks, sort highest -> lowest, show top 4 or 6 picks */
      const buys = [...fullCycle.data]
        .filter(({ is_new }) => is_new)
        .map(({ ticker }) => ({
          label: ticker,
        }));

      const sells = [...fullCycle.data]
        .filter(({ is_old }) => is_old)
        .map(({ ticker }) => ({
          label: ticker,
        }));

      setStocks({
        buys: [...buys],
        sells: [...sells],
      });
    }
  }, [fullCycle?.data]);

  return (
    <Card
      cardId={cardId}
      heading={title}
      href={routes.stockSelection.fullCycleTrend}
      className='flex items-center'
      leftEl={<RebalDate date={fullCycle?.date} />}>
      <div className='p-4 md:p-6 flex-1'>
        {!stocks ? (
          <div>
            <div className='flex '>
              {[...Array(6)].map((_, i) => {
                const flexBasis = (100 / 6).toFixed();
                return (
                  <div key={i} style={{ flexBasis: `${flexBasis}%` }} className='text-center'>
                    <Skeleton height={16} width='60%' />
                  </div>
                );
              })}
            </div>

            <div className='h-1 bg-gray-200 w-full mt-2 mb-4 lg:mt-3 lg:mb-6' />

            <div className='flex'>
              {[...Array(6)].map((_, i) => {
                const flexBasis = (100 / 6).toFixed();
                return (
                  <div key={i} style={{ flexBasis: `${flexBasis}%` }} className='text-center'>
                    <Skeleton height={16} width='60%' />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>
            {' '}
            <p className='text-xs md:text-sm uppercase font-semibold pb-2 md:pb-4 text-gray-500'>{`${dayjs(
              fullCycle?.date
            )
              .add(1, 'month')
              .format('MMMM')} Buys`}</p>
            <ul className='grid grid-cols-6 gap-2'>
              {stocks.buys.map(({ label }, i) => {
                return (
                  <li
                    key={`${label}-${i}`}
                    className='text-13 md:text-15 border-sage-500/70 border border-solid text-sage-500 font-semibold inline-block text-center rounded-sm'>
                    {label}
                  </li>
                );
              })}
            </ul>
            <div className='h-1 bg-gray-200 w-full my-4 md:my-6' />
            <p className='text-xs md:text-sm uppercase font-semibold pb-2 md:pb-4 text-gray-500'>{`${dayjs(
              fullCycle?.date
            )
              .add(1, 'month')
              .format('MMMM')} Sells`}</p>
            <ul className='grid grid-cols-6 gap-2'>
              {stocks.sells.map(({ label }, i) => {
                return (
                  <li
                    key={`${label}-${i}`}
                    className='text-13 md:text-15 border-terracotta-500/70 border border-solid text-terracotta-500 font-semibold inline-block text-center rounded-sm'>
                    {label}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </Card>
  );
}

export default memo(FullCycleCard);
