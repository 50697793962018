import { memo } from 'react';

interface BlockedTextProps {
  text: string;
  backgroundColor: string;
  size?: 'small' | 'regular' | 'large';
  testId?: string;
}

function BlockedText({
  text,
  backgroundColor,
  size = 'regular',
  testId = 'blocked-text',
}: BlockedTextProps) {
  const smallClasses =
    'text-xs py-1 px-2 w-[110px] font-semibold md:py-1.5 md:px-0 md:text-sm';
  const regularClasses =
    'w-[100px] py-1 text-13 font-semibold md:w-[160px] md:px-0 md:text-base';

  const largeClasses = 'py-1.5 px-5 font-bold md:w-[200px] text-xl';

  const classes =
    size === 'large'
      ? largeClasses
      : size === 'regular'
      ? regularClasses
      : smallClasses;

  return (
    <span
      className={`uppercase text-white text-center block rounded-sm ${classes}`}
      style={{ backgroundColor }}
      data-testid={testId}
    >
      {text}
    </span>
  );
}

export default memo(BlockedText);
