'use client';

import { SectorRotationResponse } from '@3fourteen/core';
import { classNames } from 'helpers/classNames';
import { memo, useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';

import { TableHead, TableSortDisplay } from 'components';

import { columns } from './columns';

interface SectorRotationTableProps extends SectorRotationResponse {}

function SectorRotationTable({ data, date }: SectorRotationTableProps) {
  const [sortedColumn, setSortedColumn] = useState(undefined);
  const cols = useMemo(() => {
    return columns;
  }, []);

  const tableInstance = useTable(
    {
      columns: cols,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: 'active', desc: true }],
      },
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const headers = headerGroups[0].headers;

  const sorted = headers.find((column) => column.sortedIndex !== -1);

  useEffect(() => {
    setSortedColumn(sorted);
  }, [sorted]);

  const sortOrder = !sortedColumn ? undefined : sortedColumn.isSortedDesc ? 'DESC' : 'ASC';

  return (
    <div>
      <TableSortDisplay
        sortOption={sortedColumn?.Header}
        sortOrder={sortedColumn?.isSortedDesc ? 'DESC' : 'ASC'}
      />
      <div className='table-container'>
        <table {...getTableProps()} className='bg-white w-full border-collapse border-spacing-0'>
          <TableHead headerGroups={[...headerGroups]} sortable={true} />
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className='border-y border-solid border-zinc-100'>
                  {row.cells.map((cell, i) => {
                    const isWeightCell = i === 5 || i === 6 || i === 7;
                    const activeCellValue = row.cells[7].value;
                    const activeClass =
                      activeCellValue < 0
                        ? 'text-white bg-terracotta-500 font-bold'
                        : activeCellValue > 0
                        ? 'text-white bg-sage-500 font-bold'
                        : '';

                    if (isWeightCell) {
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className: classNames(
                                i === 7 ? activeClass : undefined,
                                'first:pl-4 md:first:pl-6 border-r border-solid border-zinc-100 px-3 py-0 text-15 whitespace-nowrap'
                              ),
                            },
                          ])}
                          key={i}>
                          <span>{`${cell.value}%`}</span>
                        </td>
                      );
                    }

                    return (
                      <td
                        {...cell.getCellProps()}
                        key={i}
                        className='first:pl-4 md:first:pl-6 border-r border-solid border-zinc-100 px-3 py-0 text-15 whitespace-nowrap'>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default memo(SectorRotationTable);
