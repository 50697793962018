import { FavoritesResponse, fetchFavorites } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useFavorites() {
  const { data, ...rest } = useQuery<FavoritesResponse>({
    queryKey: ['favorites'],
    queryFn: fetchFavorites,
  });

  return {
    favorites: data?.data,
    ...rest,
  };
}
