import { Model } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { PostIcon, PostTag } from 'components';
import Link from 'next/link';

interface ModelItemProps {
  model: Model;
}

function ModelItem({ model }: ModelItemProps) {
  const isSmallScreen = useMediaQuery(768);

  if (!model) return <Skeleton count={3} />;

  const { title, excerpt, slug } = model;
  const iconSize = isSmallScreen ? 14 : 16;

  return (
    <Link href={`/models/${slug}`}>
      <div className='flex items-center'>
        <PostIcon categorySlug='models' size={iconSize} />
        <p className='pr-2 pl-1.5 mb-0 font-medium whitespace-nowrap font-serif text-ellipsis'>
          {title}
        </p>
        <PostTag category='models' />
      </div>
      {excerpt && <div className='excerpt' dangerouslySetInnerHTML={{ __html: excerpt }} />}
    </Link>
  );
}

export default memo(ModelItem);
