'use client';

import { Chart, Commentary, Retrospective, toggleFavorite } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useFavorites } from 'hooks/useFavorites';
import { memo, useCallback, useState } from 'react';

import { AntIcon } from 'components';
import { useToast } from 'contexts/toast';

interface FavoriteButtonProps {
  post: Chart | Commentary | Retrospective;
  containerClass?: string;
  className?: string;
}

function FavoriteButton({ post, containerClass = '', className = '' }: FavoriteButtonProps) {
  const queryClient = useQueryClient();
  const { favorites } = useFavorites();
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);

  const isFavorite = favorites?.find((fav) => fav.id === post?.id);

  const onFavorite = useCallback(async () => {
    setLoading(true);

    try {
      await toggleFavorite(post);

      await queryClient.refetchQueries({ queryKey: ['favorites'], type: 'active' });
    } catch (error) {
      showToast({
        type: 'error',
        message: 'Failed to toggle favorite',
      });

      Bugsnag.notify(error, (event) => {
        event.context = 'onFavorite() in FavoriteButton';
        event.addMetadata('Post Data', {
          post,
        });
      });
    } finally {
      setLoading(false);
    }
  }, [post, queryClient, showToast]);

  return (
    <div className={`flex justify-center items-center h-5 w-5 ${containerClass}`}>
      {loading ? (
        <AntIcon name='loading1' className={`!text-teal-600 animate-spin ${className}`} />
      ) : (
        <button
          className='w-full'
          onClick={onFavorite}
          title={`${isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}`}>
          {isFavorite ? (
            <AntIcon name='heart' className={`!text-lg !text-teal-600 ${className}`} />
          ) : (
            <AntIcon name='hearto' className={`!text-lg !text-teal-600 ${className}`} />
          )}
        </button>
      )}
    </div>
  );
}

FavoriteButton.displayName = 'FavoriteButton';

export default memo(FavoriteButton);
