import { XCircleIcon } from '@heroicons/react/20/solid';
import { Table } from '@tanstack/react-table';
import { memo } from 'react';

import { AssetRow } from 'components/AssetsMenuTable/types';

interface DeselectAllHeaderProps {
  table: Table<AssetRow>;
}

function DeselectAllHeader({ table }: DeselectAllHeaderProps) {
  const onClick = () => {
    const rows = table.getRowModel().flatRows;

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];

      if (!!row.getValue('isSelected')) {
        table.options.meta?.updateData(row.index, 'isSelected', false);
      }
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <button
        onClick={onClick}
        type='button'
        className='flex flex-col items-center leading-tight'>
        <XCircleIcon className='w-5 h-5 text-red-700 font-bold' />
        Deselect <span className='block'>All</span>
      </button>
    </div>
  );
}

DeselectAllHeader.displayName = 'DeselectAllHeader';

export default memo(DeselectAllHeader);
