import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

interface CardRowSkeletonProps {
  numberOfItems: number;
  height?: number;
}

function CardRowSkeleton({ numberOfItems, height = 16 }: CardRowSkeletonProps) {
  return (
    <div className="flex ">
      {[...Array(numberOfItems)].map((_, i) => {
        const flexBasis = (100 / numberOfItems).toFixed();
        return (
          <div
            key={i}
            style={{ flexBasis: `${flexBasis}%`, textAlign: "center" }}
          >
            <Skeleton
              height={height}
              width="60%"
              style={{ marginBottom: 10 }}
            />
            <Skeleton height={height} width="60%" style={{ marginBottom: 4 }} />
          </div>
        );
      })}
    </div>
  );
}

export default memo(CardRowSkeleton);
