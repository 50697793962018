'use client';

import { MenuAsset } from '@3fourteen/core';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';
import { FormikValues } from 'formik';
import { fixRounding } from 'helpers/fixRounding';
import { usePbAssets } from 'hooks/usePbAssets';
import { Fragment, memo, useEffect, useRef, useState } from 'react';

import { AssetsMenuTable, Button, LoadingDots } from 'components';

interface AssetsMenuModalProps {
  form: FormikValues;
}

function formatOptions(menuAssets: MenuAsset[]) {
  return menuAssets.map(
    ({ asset_class, short_name, index_name, stats, start_date, series_id }) => ({
      isSelected: false,
      asset_class,
      short_name,
      index_name,
      cagr: stats.cagr,
      sharpe: stats.sharpe,
      max_dd: stats.max_dd,
      vol: stats.vol,
      start_date,
      bench_weight: 0,
      min_weight: 0,
      max_weight: 100,
      series_id,
      defaults: {
        short_name,
        start_date,
      },
    })
  );
}

function AssetsMenuModal({ form }: AssetsMenuModalProps) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [tableAssets, setTableAssets] = useState(undefined);
  const { data } = usePbAssets();
  const { assets } = form.values;

  useEffect(() => {
    if (data?.menuAssets) {
      const rowsWithSelected = formatOptions(data?.menuAssets).map((rowAsset) => {
        const selectedAsset = assets.find((ass) => ass.series_id === rowAsset.series_id);

        if (selectedAsset) {
          return {
            ...rowAsset,
            isSelected: true,
            bench_weight: fixRounding(selectedAsset.bench_weight * 100, 1),
            min_weight: fixRounding(selectedAsset.min_weight * 100, 1),
            max_weight: fixRounding(selectedAsset.max_weight * 100, 1),
            start_date: selectedAsset.start_date,
          };
        } else {
          return { ...rowAsset };
        }
      });
      setTableAssets([...rowsWithSelected]);
    }
  }, [data?.menuAssets, assets]);

  const onOpen = () => {
    setOpen(true);
  };

  const onSave = (tableAssets) => {
    form.setFieldValue('assets', [...tableAssets]);
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
    form.setFieldValue('assets', [...assets]);
  };

  const goToSelectedAsset = (id) => {
    const item = document.getElementById(id);

    item.scrollIntoView();
  };

  const selectedList = assets
    .map(({ series_id }) => data?.menuAssets?.find((ass) => ass.series_id === series_id).short_name)
    .join(', ');

  return (
    <div>
      <span className='block text-sm font-medium text-gray-700 uppercase'>
        {assets.length ? `Assets (${assets.length})` : 'Assets'}
      </span>
      <Button
        onClick={onOpen}
        className='flex font-normal justify-between w-full rounded-md border mt-1 border-gray-300 border-solid bg-white py-2 px-3 text-left  focus:border-teal-800 focus:outline-none focus:ring-1 focus:ring-teal-800 sm:text-sm'>
        <span className='whitespace-nowrap text-ellipsis overflow-hidden'>
          {assets.length ? `${selectedList}` : ' Select Assets'}
        </span>
        <ArrowUpRightIcon className='h-5 w-5 text-gray-400' />
      </Button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={onCancel}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center text-center sm:items-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                <Dialog.Panel className='h-[95vh] overflow-y-auto flex flex-col justify-between relative transform overflow-hidden rounded-lg bg-white px-8 pt-8 pb-4 text-left shadow-xl transition-all sm:w-full mx-6'>
                  <div>
                    <div className='overflow-y-auto'>
                      {!!tableAssets ? (
                        <AssetsMenuTable
                          modelName={form.values.model_name}
                          tableAssets={tableAssets}
                          onSave={onSave}
                          onCancel={onCancel}
                        />
                      ) : (
                        <div className='flex h-[85vh] items-center justify-center'>
                          <LoadingDots size='regular' color='#999' />
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

AssetsMenuModal.displayName = 'AssetsMenuModal';

export default memo(AssetsMenuModal);
