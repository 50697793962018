'use client';

import { Listbox, Transition } from '@headlessui/react';
import { classNames } from 'helpers/classNames';
import { Fragment, memo } from 'react';

const range = (start, stop) => {
  return Array.from({ length: stop - start + 1 }, (_, i) => start + i);
};

interface DatePickerYearSelectProps {
  defaultStartDate: string;
  value: number;
  onChange: (year: number) => void;
}

function DatePickerYearSelect({ defaultStartDate, value, onChange }: DatePickerYearSelectProps) {
  const years = range(new Date(defaultStartDate).getFullYear(), new Date().getFullYear());

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className='relative'>
            <Listbox.Button className='bg-white/20 relative w-full cursor-pointer rounded-md border border-gray-300  text-white py-2 px-3 text-left shadow-sm focus:border-gray-50 focus:ring-1 focus:ring-gray-50'>
              <span className='block truncate font-semibold'>{value}</span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {years.map((year) => (
                  <Listbox.Option
                    key={year}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-teal-800' : 'text-gray-900',
                        'relative cursor-default select-none py-2'
                      )
                    }
                    value={year}>
                    {({ selected }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}>
                          {year}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

DatePickerYearSelect.displayName = 'DatePickerYearSelect';

export default memo(DatePickerYearSelect);
