import { AlphaModelResponse, fetchAlphaModels } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function usePbAlphaModels() {
  const { data: alphaModels, ...rest } = useQuery<AlphaModelResponse>({
    queryKey: ['pb-alpha-models'],
    queryFn: () => fetchAlphaModels(),
  });

  return {
    alphaModels,
    ...rest,
  };
}
