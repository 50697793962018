'use client';

import { ArrowLeftIcon, ArrowRightIcon, ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { memo, useRef } from 'react';
import ReactDatepicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import { DatePickerMonthSelect } from 'components/DatePickerMonthSelect';
import { months } from 'components/DatePickerMonthSelect/component';
import { DatePickerYearSelect } from 'components/DatePickerYearSelect';

function getRenderValueAsDate(val) {
  if (val) {
    return new Date(
      new Date(val).toLocaleString('en-US', {
        timeZone: 'UTC',
      })
    );
  }
  return null;
}

const defaultStartDate = dayjs(Date.now()).format('YYYY-MM-DD');

const ChartBuilderEndDate = ({ control, minDate }: any) => {
  const datepickerRef = useRef(null);

  return (
    <Controller
      control={control}
      name='end_date'
      rules={{
        validate: (value, formValues) => {
          console.log('value', value);
          console.log('formValues.start_date', formValues.start_date);
          if (value && formValues.start_date) {
            return (
              dayjs(value).isAfter(dayjs(formValues.start_date)) ||
              'End date must be after start date'
            );
          } else return true;
        },
      }}
      render={({ field, formState }) => {
        console.log('formState', formState);
        return (
          <ReactDatepicker
            ref={datepickerRef}
            wrapperClassName='chart-builder-datepicker'
            id='end_date'
            selected={field.value}
            onChange={field.onChange}
            minDate={minDate}
            maxDate={new Date()}
            selectsEnd
            placeholderText='Select end date'
            renderCustomHeader={(props) => {
              const {
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              } = props;
              return (
                <div className='flex justify-between mx-4 mb-4'>
                  {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <ArrowLeftIcon className='h-5 2-5 text-white font-bold' />
                  </button> */}
                  <div className='flex mx-3'>
                    <div className='mr-1'>
                      <DatePickerMonthSelect
                        value={months[new Date(date).getMonth()]}
                        onChange={(value) => {
                          changeMonth(months.indexOf(value));
                        }}
                      />
                    </div>

                    <div className='mx-1'>
                      <DatePickerYearSelect
                        defaultStartDate={minDate}
                        value={new Date(date).getFullYear()}
                        onChange={(value) => {
                          changeYear(value);
                        }}
                      />
                    </div>

                    {/* <button
                      title='Reset Date'
                      onClick={() => {
                        // datepickerRef.current.setPreSelection(
                        //   getRenderValueAsDate(defaultStartDate)
                        // );
                        field.onChange(getRenderValueAsDate(defaultStartDate));
                      }}
                      className='bg-white/20 relative ml-1 w-full h-full cursor-pointer rounded-md border border-gray-300  text-white py-2 px-3 text-left shadow-sm focus:border-gray-50 focus:ring-1 focus:ring-gray-50'>
                      <ArrowUturnLeftIcon className='w-4 h-4 text-white' />
                    </button> */}
                  </div>

                  {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <ArrowRightIcon className='h-5 2-5 text-white font-bold' />
                  </button> */}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
};

ChartBuilderEndDate.displayName = 'ChartBuilderEndDate';

export default memo(ChartBuilderEndDate);
