import { formatPercent } from '@3fourteen/core';
import { memo } from 'react';

interface ProgressBarProps {
  progress: number;
}

function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <div className=' w-full'>
      <span className='font-semibold 2xl:font-bold mb-2 block text-center text-13 2xl:text-sm'>{`${formatPercent(
        progress
      )}`}</span>
      <div className='relative my-2 h-6 w-full'>
        <div className='absolute top-0 left-0 right-0 bottom-0 rounded-[16px] overflow-hidden flex'>
          {progress > 0 && (
            <div
              className=' bg-teal-500 flex justify-center items-center transition-width ease-out duration-500'
              style={{
                width: `${formatPercent(progress)}`,
              }}></div>
          )}
          {progress !== 1 && <div className='bg-gray-200 flex-1'></div>}
        </div>
      </div>
    </div>
  );
}

ProgressBar.displayName = 'ProgressBar';

export default memo(ProgressBar);
