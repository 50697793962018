'use client';

import { PortfolioAsset } from '@3fourteen/core';
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
  ArrowsUpDownIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { FieldArray, Form, Formik, FormikProps, FormikValues } from 'formik';
import { classNames } from 'helpers/classNames';
import isEmpty from 'lodash/isEmpty';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import { AssetClassBreakdownBar, Button, Tooltip } from 'components';

import { columns } from './columns';
import { AssetRow } from './types';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    htmlFor: string;
  }

  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    totals: {
      benchmark: number;
      fixedIncome: number;
      equities: number;
      alternatives: number;
    };
  }
}

interface AssetsMenuTableProps {
  modelName?: string;
  tableAssets: AssetRow[];
  onSave: (assets: PortfolioAsset[]) => void;
  onCancel: () => void;
}

function Filter({ column, table }: any) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className='flex space-x-2'>
      <input
        type='number'
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])
        }
        placeholder='Min'
        className='w-20 rounded-full text-sm py-1 mt-2 text-gray-900'
      />
      <input
        type='number'
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])
        }
        placeholder='Max'
        className='w-20 rounded-full text-sm py-1 mt-2 text-gray-900'
      />
    </div>
  ) : (
    <div className='mr-4'>
      <input
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder='Search...'
        className='w-full rounded-full text-sm py-1 mt-2 font-medium border-gray-300 text-gray-900'
      />
    </div>
  );
}
const TableFormSchema = Yup.object().shape({
  portfolioAssets: Yup.array()
    .of(
      Yup.object().shape({
        series_id: Yup.string().default(''),
        start_date: Yup.string().default(''),
        bench_weight: Yup.number().default(0),
        min_weight: Yup.number().test('min-weight', 'Must be <= max wgt', (value, ctx) => {
          return value <= ctx.parent.max_weight;
        }),
        max_weight: Yup.number().test('max-weight', 'Must be >= min wgt', (value, ctx) => {
          return value >= ctx.parent.min_weight;
        }),
      })
    )
    .required('Select some assets')
    .min(1, 'Minimum of 1 asset'),
});

function AssetsMenuTable({ modelName, tableAssets, onSave, onCancel }: AssetsMenuTableProps) {
  const localSort = 'asset-menu-sort';
  const tableForm = useRef<FormikProps<FormikValues>>(null);
  const [data, setData] = useState([...tableAssets]);
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(localStorage.getItem(localSort)) || []
  );
  const [totals, setTotals] = useState({
    totalAssets: 0,
    benchmark: 0,
    equities: 0,
    fixedIncome: 0,
    alternatives: 0,
  });

  useEffect(() => {
    tableForm.current.validateForm();
  }, []);

  useEffect(() => {
    localStorage.setItem(localSort, JSON.stringify(sorting));
  }, [sorting, localSort]);

  const cols = useMemo(() => columns, []);

  const table = useReactTable({
    data,
    columns: cols,
    state: {
      sorting,
    },
    autoResetAll: false,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
    meta: {
      // update table data AND form values
      updateData: (rowIndex, columnId, value) => {
        // Update row data with new value
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }

            return row;
          })
        );

        tableForm.current.setFieldValue(`portfolioAssets.${rowIndex}.${columnId}`, value);
      },
      totals,
    },
  });

  const tableRowData = table.getRowModel().flatRows;
  useEffect(() => {
    const selectedRows = tableRowData.filter((row) => row.getValue('isSelected'));

    const fixedIncome = [...selectedRows]
      .filter((row) => row.original.asset_class.toLowerCase() === 'fixed income')
      .reduce((n, { getValue }) => n + getValue<number>('bench_weight'), 0);

    const equities = [...selectedRows]
      .filter((row) => row.original.asset_class.toLowerCase() === 'equities')
      .reduce((n, { getValue }) => n + getValue<number>('bench_weight'), 0);

    const alternatives = [...selectedRows]
      .filter((row) => row.original.asset_class.toLowerCase() === 'alternatives')
      .reduce((n, { getValue }) => n + getValue<number>('bench_weight'), 0);

    const benchmark = [...selectedRows].reduce(
      (n, { getValue }) => n + getValue<number>('bench_weight'),
      0
    );

    setTotals({
      totalAssets: selectedRows.length,
      benchmark,
      fixedIncome,
      equities,
      alternatives,
    });
  }, [tableRowData]);

  const onSaveAssets = useCallback(() => {
    const selectedRows = [...tableRowData].filter((row) => row.getValue('isSelected'));
    const portAssets = [...selectedRows].map((row) => {
      const { getValue, original } = row;

      return {
        series_id: original.series_id,
        bench_weight: getValue<AssetRow['bench_weight']>('bench_weight') / 100,
        min_weight: getValue<AssetRow['min_weight']>('min_weight') / 100,
        max_weight: getValue<AssetRow['max_weight']>('max_weight') / 100,
        start_date: getValue<AssetRow['start_date']>('start_date'),
      };
    });

    onSave(portAssets);
  }, [onSave, tableRowData]);

  const toggleSelectedVisibility = useCallback(
    (e) => {
      const column = table.getColumn('isSelected');

      const currentFilter = column.getFilterValue();

      if (currentFilter) {
        column.setFilterValue(undefined);
      } else {
        column.setFilterValue(true);
      }
    },
    [table]
  );

  return (
    <div>
      <div className='flex justify-between pb-4'>
        <div className='flex'>
          <div>
            {modelName ? (
              <div>
                <h2 className='text-3xl font-medium leading-6 mb-3 text-gray-900'>{modelName}</h2>
                {!!totals.totalAssets && (
                  <p className='text-xl text-teal-700 font-medium'>{`${totals.totalAssets} assets`}</p>
                )}
              </div>
            ) : (
              <div>
                <h2 className='text-xl font-medium leading-6 text-gray-900'>
                  Configure your assets
                </h2>
                {!!totals.totalAssets && (
                  <p className='text-xl text-teal-700 font-medium'>{`${totals.totalAssets} assets`}</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-col items-center w-96'>
          <p>
            Total Benchmark:
            <span
              className={classNames(
                totals.benchmark > 100 ? 'text-red-500' : '',
                'font-black ml-2'
              )}>{`${totals.benchmark}%`}</span>
          </p>

          <AssetClassBreakdownBar {...totals} />

          <div className='flex w-full text-sm justify-between'>
            <p className='flex items-center'>
              <span className='block w-3 h-3 rounded-full bg-olive mr-1'></span>
              <span>Fixed Income</span>
            </p>
            <p className='flex items-center'>
              <span className='block w-3 h-3 rounded-full bg-navy mr-1'></span>
              <span>Equities</span>
            </p>
            <p className='flex items-center'>
              <span className='block w-3 h-3 rounded-full bg-fuchsia mr-1'></span>
              <span>Alternatives</span>
            </p>
          </div>
        </div>
        <div className='flex'>
          <Button type='button' className='btn-secondary w-24 btn-sm' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type='button'
            className='btn-primary ml-4 w-24 btn-sm'
            disabled={totals.benchmark !== 100 || !isEmpty(tableForm.current.errors)}
            onClick={onSaveAssets}>
            Done
          </Button>
        </div>
      </div>

      <div className='py-2 pr-4 border-t border-solid border-gray-200 w-full flex'>
        <label htmlFor='selected-only' className='flex items-center cursor-pointer ml-1'>
          <input
            id='selected-only'
            name='selected-only'
            type='checkbox'
            className='scroll-mt-8 h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-500 cursor-pointer mr-3'
            onChange={toggleSelectedVisibility}
          />
          <span className='capitalize text-sm font-bold '>Hide unselected</span>
        </label>
      </div>
      <Formik
        key='table-form'
        validationSchema={TableFormSchema}
        innerRef={tableForm}
        initialValues={{
          portfolioAssets: tableAssets,
        }}
        onSubmit={(values) => {
          console.log('values from asset table form submission: ', values);
        }}>
        {(formik) => {
          const { errors } = formik;

          return (
            <Form
              className='overflow-auto h-[calc(95vh_-_165px)] border border-solid border-gray-200'
              noValidate>
              <FieldArray name='portfolioAssets'>
                {(arrayHelpers) => (
                  <table className='w-full border-collapse border-spacing-0'>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup, i) => {
                        return (
                          <tr key={i} className='border-solid border border-gray-200'>
                            {headerGroup.headers.map((header) => (
                              <th
                                key={header.column.id}
                                className={classNames(
                                  header.column.getIsFiltered() || header.column.getIsSorted()
                                    ? 'bg-teal-800 text-white border-teal-800'
                                    : 'bg-gray-100 border-gray-200',
                                  'text-left py-2 pl-4 border border-solid z-10 sticky top-[-1px] text-sm font-bold whitespace-nowrap'
                                )}>
                                {header.column.getCanSort() ? (
                                  <button
                                    onClick={header.column.getToggleSortingHandler()}
                                    className='flex'>
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: <ArrowLongUpIcon className='h-4 w-4' />,
                                      desc: <ArrowLongDownIcon className='h-4 w-4' />,
                                    }[header.column.getIsSorted() as string] ?? (
                                      <ArrowsUpDownIcon className='h-4 w-4 text-teal-800/50 mt-0.5 ml-1' />
                                    )}
                                  </button>
                                ) : (
                                  flexRender(header.column.columnDef.header, header.getContext())
                                )}
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <Filter column={header.column} table={table} />
                                  </div>
                                ) : null}
                              </th>
                            ))}
                          </tr>
                        );
                      })}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row, rowIndex) => {
                        return (
                          <tr
                            key={row.id}
                            className='border-b border-solid border-gray-200 hover:bg-slate-50'>
                            {row.getVisibleCells().map((cell) => {
                              const isSelected = !!row.getValue('isSelected');
                              const htmlFor = cell.column.columnDef.meta?.htmlFor;

                              return (
                                <td
                                  key={cell.id}
                                  className={classNames(
                                    isSelected ? 'bg-teal-50' : '',
                                    'py-1 pl-4 md:first:pl-6 relative text-15'
                                  )}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  {!!htmlFor && (
                                    <label
                                      htmlFor={`portfolioAssets.${row.index}.${htmlFor}`}
                                      className='edit-icon absolute right-3 top-2.5 h-4 w-4 cursor-pointer'>
                                      <PencilSquareIcon
                                        className='h-4 w-4 text-cyan-700'
                                        aria-hidden='true'
                                      />
                                    </label>
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
      <Tooltip id='max-tip' />
      <Tooltip id='min-tip' />
      <Tooltip id='benchmark-tip' />

      <style jsx>{`
        tr .edit-icon {
          opacity: 0.3;
          transition: 150ms ease;
        }

        tr:hover .edit-icon {
          opacity: 1;
        }
      `}</style>
    </div>
  );
}

AssetsMenuTable.displayName = 'AssetsMenuTable';

export default memo(AssetsMenuTable);
