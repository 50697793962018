import { CellContext } from '@tanstack/react-table';
import { useField } from 'formik';
import InputNumber from 'rc-input-number';
import { memo, useCallback } from 'react';

import { AssetRow } from 'components/AssetsMenuTable/types';

import styles from './WeightField.module.scss';

interface WeightFieldProps {
  inputId: HTMLInputElement['id'];
  info: CellContext<AssetRow, number>;
}

function WeightField({ info, inputId }: WeightFieldProps) {
  const {
    getValue,
    row,
    row: { index: rowIndex },
    column: { id },
    table,
  } = info;
  const inputName = `portfolioAssets.${rowIndex}.${inputId}`;
  const benchmarkTotal = table.options.meta.totals.benchmark;
  const isBenchmarkInput = inputId === 'bench_weight';
  const initialValue = getValue();
  const [field, meta] = useField({
    value: initialValue,
    name: inputName,
    validate: (value) => {
      if (benchmarkTotal > 100 && isBenchmarkInput) {
        return "Benchmark total can't be > 100%";
      }
    },
  });
  const isSelected = row.getValue('isSelected');

  const onChange = (value) => {
    table.options.meta?.updateData(rowIndex, id, value * 1);
  };

  const onPressEnter = useCallback((e) => {
    e.target.blur();
  }, []);

  const onFocus = (e) => {
    e.target.select();
  };

  return (
    <div>
      <div className='relative flex flex-col justify-between items-start'>
        <InputNumber
          // type='number'
          id={inputName}
          name={inputName}
          min={0}
          max={100}
          step={5}
          {...field}
          onChange={onChange}
          onFocus={onFocus}
          onPressEnter={onPressEnter}
          className={styles.weightField}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          precision={Number(1)}
          decimalSeparator='.'
        />
        {isSelected &&
          meta.error &&
          (field.value != 0 || !isBenchmarkInput) && (
            <div className='top-0 left-0 text-xs text-red-800 font-semibold'>
              <span>{meta.error}</span>
            </div>
          )}
      </div>
    </div>
  );
}

WeightField.displayName = 'WeightField';

export default memo(WeightField);
