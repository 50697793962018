export const columns = [
  {
    Header: "Sector",
    accessor: "index",
  },
  {
    Header: "Trend (TB)",
    accessor: "trend",
    sortType: "basic",
  },
  {
    Header: "Flows",
    accessor: "flows",
    sortType: "basic",
  },
  {
    Header: "Tactical ML",
    accessor: "tactical",
    sortType: "basic",
  },
  {
    Header: "Total",
    accessor: "total",
    sortType: "basic",
  },
  {
    Header: "Benchmark Wgt",
    accessor: "benchmark",
    sortType: "basic",
  },
  {
    Header: "Model Wgt",
    accessor: "model",
    sortType: "basic",
  },
  {
    Header: "Active Wgt",
    accessor: "active",
    sortType: "basic",
  },
];
