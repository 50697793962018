'use client';

import { useCustomResearch } from 'hooks/useCustomResearch';
import parse, { attributesToProps, domToReact, Element } from 'html-react-parser';
import { useMemo } from 'react';

import { LoadingDots } from 'components/LoadingDots';
import Link from 'next/link';

const CustomResearchHtml = ({ filename }) => {
  const { data, isLoading } = useCustomResearch(filename);

  const options = useMemo(() => {
    if (data) {
      return {
        replace: (domNode) => {
          const { attribs } = domNode;

          if (
            domNode instanceof Element &&
            attribs &&
            attribs.href?.includes('/custom-research/')
          ) {
            const file = attribs.href.split('/').pop();
            const fileWithoutExtension = file.split('.').shift();

            const props = attributesToProps(attribs);

            return (
              <Link
                as={`/custom-research/${file}`}
                href={`/custom-research/${fileWithoutExtension}`}
                style={props.style}
                className={props.className}>
                {domToReact(domNode.children)}
              </Link>
            );
          }
        },
      };
    } else {
      return {};
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className='flex h-full items-center justify-center'>
        <LoadingDots color='#999' size='regular' />
      </div>
    );
  }

  if (!data) {
    return (
      <div className='flex items-center justify-center'>
        <p>Unable to load this HTML file</p>
      </div>
    );
  }

  return <div className='min-h-screen '>{parse(data, options)}</div>;
};

export default CustomResearchHtml;
