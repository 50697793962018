'use client';

import { Chart, Commentary, Retrospective, updateFavorites } from '@3fourteen/core';
import Bugsnag from '@bugsnag/js';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { memo, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';

import { FavoriteButton, PostItem, SimpleLineIcon } from 'components';
import { useToast } from 'contexts/toast';
import dynamic from 'next/dynamic';

// hack for working DND to work in Nextjs
const Droppable = dynamic(() => import('react-beautiful-dnd').then((res) => res.Droppable), {
  ssr: false,
});

interface FavoritesListProps {
  favorites: (Chart | Commentary | Retrospective)[];
}

function FavoritesList({ favorites }: FavoritesListProps) {
  const [isBrowser, setIsBrowser] = useState(false);
  const [items, setItems] = useState([...favorites]);
  const { showToast } = useToast();

  useEffect(() => {
    // hack for working DND to work in Nextjs
    if (typeof window !== 'undefined') {
      setIsBrowser(true);
    }
  }, []);

  useEffect(() => {
    setItems([...favorites]);
  }, [favorites]);

  const onDragEnd = useCallback(
    async (result) => {
      if (!result.destination) {
        return;
      }
      const newItems = [...items];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);

      try {
        setItems(newItems);

        await updateFavorites(newItems);
      } catch (error) {
        showToast({
          type: 'error',
          message: 'Unable to save updated Favorites order',
        });

        Bugsnag.notify(error, (event) => {
          event.context = 'onDragEnd() in FavoritesList';
          event.addMetadata('Drag Result', {
            result,
          });
        });
      }
    },
    [items, showToast]
  );

  return (
    <div className='mb-16'>
      {isBrowser && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='border-t border-solid border-neutral-200'>
                {items?.map((item, index) => (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(provided, snapshot) => (
                      <li
                        className='bg-white flex items-center py-2.5 px-4 md:py-3 md:px-6 border-b border-solid border-neutral-200'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div className='flex relative'>
                          <EllipsisVerticalIcon className='h-5 w-5 text-zinc-400' />
                          <EllipsisVerticalIcon className='h-5 w-5 text-zinc-400 absolute right-2' />
                        </div>
                        <div className='ml-4 mr-5'>
                          <FavoriteButton post={item} />
                        </div>
                        <PostItem post={item} />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}

export default memo(FavoritesList);
