'use client';

import { classNames } from 'helpers/classNames';
import { FormEvent, memo, useCallback, useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { HTMLMediaControls, HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook';

import { usePathname, useSearchParams } from 'next/navigation';

export interface VideoChapter extends TextTrackCue {
  text: string;
}

interface SeekBarProps {
  time: HTMLMediaState['time'];
  seek: HTMLMediaControls['seek'];
  duration: HTMLMediaState['duration'];
  chapters: VideoChapter[];
  isFullScreen: boolean;
  showChapterLabels: boolean;
}

const SeekBar = ({
  time,
  duration,
  chapters,
  seek,
  isFullScreen,
  showChapterLabels,
}: SeekBarProps) => {
  const searchParams = useSearchParams();
  const timestampQuery = searchParams?.get('ts');
  const pathname = usePathname();
  const [seekValue, setSeekValue] = useState(time / duration);

  useEffect(() => {
    if (timestampQuery) {
      const timestamp = parseFloat(timestampQuery as string);

      seek(timestamp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestampQuery]);

  useEffect(() => {
    setSeekValue(time / duration);
  }, [time, duration]);

  const onSeek = useCallback(
    (startTime: number) => {
      const newUrl = pathname + `?ts=${startTime}`;

      setSeekValue(startTime / duration);
      seek(startTime);
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    },
    [duration, seek, pathname]
  );

  return (
    <div className='relative flex items-center'>
      <input
        key='seekbar'
        id='seekbar'
        type='range'
        width='100%'
        min={0}
        max={1}
        step={0.001}
        value={seekValue}
        onChange={(event: FormEvent<HTMLInputElement>) =>
          onSeek((event.target as HTMLFormElement).value * duration)
        }
      />

      {chapters.map((chapter) => {
        const left = (chapter.startTime / duration) * 100;
        return (
          <button
            data-tooltip-id={chapter.id}
            data-tooltip-content={chapter.id}
            className='absolute top-0 cursor-pointer'
            onClick={() => onSeek(chapter.startTime)}
            key={`${chapter.startTime}`}
            style={{
              left: `${left}%`,
            }}>
            <div className='bg-teal-300 h-2 w-1 rounded-full' />

            {showChapterLabels ? (
              <div
                className={classNames(
                  'hidden md:block text-white uppercase font-semibold mt-0.5 cursor-pointer',
                  isFullScreen ? 'text-sm' : 'text-xs'
                )}>
                {chapter.id}
              </div>
            ) : (
              <ReactTooltip
                className='!text-xs z-[999] border border-solid border-teal-300/20 absolute font-black max-w-xs bg-black text-teal-300 px-3 pt-0.5 pb-1 rounded-sm whitespace-nowrap uppercase'
                id={chapter.id}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

SeekBar.displayName = 'SeekBar';

export default memo(SeekBar);
