'use client';

import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo, useEffect, useState } from 'react';

import { CustomResearchNavLink, NavLink } from 'components';
import { accountLinks, navLinks, ParentItem } from 'services/links';

function CoreNav() {
  const isTablet = useMediaQuery(1280);
  const [subItemsShowing, setSubItemsShowing] = useState<ParentItem[]>([
    'models',
    'stock-selection',
  ]);

  useEffect(() => {
    if (isTablet) {
      setSubItemsShowing([]);
    } else {
      setSubItemsShowing(['models', 'stock-selection', 'portfolio-builder']);
    }
  }, [isTablet]);

  const toggleSubItems = (parent) => {
    const isShowing = subItemsShowing.find((subItem) => subItem === parent);

    if (!!isShowing) {
      const filtered = subItemsShowing.filter((subItem) => subItem !== parent);

      setSubItemsShowing([...filtered]);
    } else {
      setSubItemsShowing([...subItemsShowing, parent]);
    }
  };

  return (
    <div className='flex-1 flex flex-col justify-between'>
      <nav className='flex flex-col text-white'>
        {navLinks.map(({ displayName, route, childOf, parent, icon, iconFamily }) => {
          let onClick = undefined;

          if (!!parent) {
            onClick = () => toggleSubItems(parent);
          }

          return (
            <NavLink
              href={route}
              text={displayName}
              key={displayName}
              icon={icon}
              onClick={onClick}
              childOf={childOf}
              iconFamily={iconFamily}
              parent={parent}
              isExpanded={!!subItemsShowing.includes(parent)}
              visible={!!childOf ? !!subItemsShowing.includes(childOf) : true}
            />
          );
        })}
        <CustomResearchNavLink />
      </nav>
      <nav className='flex flex-col text-white mt-4'>
        {accountLinks.map(({ displayName, route, icon, onClick, iconFamily }) => (
          <NavLink
            href={route}
            text={displayName}
            key={displayName}
            icon={icon}
            onClick={onClick}
            iconFamily={iconFamily}
            className='capitalize'
          />
        ))}
      </nav>
    </div>
  );
}

CoreNav.displayName = 'CoreNav';

export default memo(CoreNav);
