import { CoreModelReading } from '@3fourteen/core';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, RebalDate } from 'components';
import { CardProps } from 'components/Card/component';
import { LinkProps } from 'next/link';

interface CoreModelCardProps {
  cardId: CardProps['cardId'];
  readings: CoreModelReading[];
  date: string;
  title: 'Crude' | 'Gold' | 'S&P Risk' | 'Bitcoin';
  href?: LinkProps['href'];
}

function CoreModelCard({ cardId, readings, date, title, href }: CoreModelCardProps) {
  const overallReading = readings?.find(
    ({ component }) => !!component?.toLowerCase().includes('overall')
  );

  const overallColor = overallReading?.color;

  const overallSignal = overallReading?.reading;

  return (
    <Card
      cardId={cardId}
      heading={title}
      href={href}
      leftEl={
        <div className='hidden lg:block'>
          <RebalDate date={date} />
        </div>
      }>
      <div>
        {!readings ? (
          <div className='p-4 md:p-6'>
            <Skeleton style={{ marginBottom: 0 }} />
          </div>
        ) : (
          <div className='flex items-center justify-center py-4 md:py-6 px-2'>
            <span
              data-testid='overall-reading'
              className='uppercase text-white text-center rounded-sm py-1 px-2 block w-full font-semibold md:py-1.5 md:px-0 text-xs lg:text-base max-w-[10rem]'
              style={{ backgroundColor: overallColor }}>
              {overallSignal}
            </span>
          </div>
        )}
      </div>
    </Card>
  );
}

export default memo(CoreModelCard);
