import { MenuAsset } from '@3fourteen/core';
import { classNames } from 'helpers/classNames';
import { memo } from 'react';

interface AssetClassCellProps {
  assetClass: MenuAsset['asset_class'];
}

function AssetClassCell({ assetClass }: AssetClassCellProps) {
  let classes = '';

  switch (assetClass?.toLowerCase()) {
    case 'fixed income':
      classes = 'bg-olive';
      break;

    case 'alternatives':
      classes = 'bg-fuchsia';
      break;

    case 'equities':
      classes = 'bg-navy';
      break;

    default:
      break;
  }

  return (
    <span
      className={classNames(
        classes,
        'leading-5 text-white font-medium inline-block  px-2 text-center rounded-sm'
      )}>
      {assetClass}
    </span>
  );
}

AssetClassCell.displayName = 'AssetClassCell';

export default memo(AssetClassCell);
