import { Chart } from '@3fourteen/core';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useMember } from 'hooks/useMember';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo } from 'react';

import { Tooltip } from 'components';

interface DownloadChartButtonProps {
  filename: Chart['filename'];
}

const DownloadChartButton = ({ filename }: DownloadChartButtonProps) => {
  const { url: signedUrl } = useSignedCloudfrontUrl(filename);
  const { isTrialUser } = useMember();

  if (!signedUrl) return null;

  if (isTrialUser) {
    return (
      <div>
        <ArrowDownTrayIcon
          data-tooltip-id='post-action-download-tip'
          data-tooltip-content='Upgrade to download'
          className='size-4 lg:size-5 text-gray-500 cursor-not-allowed opacity-75'
        />
        <Tooltip id='post-action-download-tip' />
      </div>
    );
  }

  return (
    <a href={signedUrl} download={filename}>
      <ArrowDownTrayIcon className='size-4 lg:size-5 text-teal-600' />
    </a>
  );
};

export default memo(DownloadChartButton);
